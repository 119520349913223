<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__egrip">
        <div class="container">
            <h1>ЕГРИП</h1>
            <p>В Едином Государственном Реестре Индивидуальных Предпринимателей фиксируются актуальные и достоверные сведения, подтвержденные документально. Все записи из реестра общедоступны и открыты.</p>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Ведением реестра занимается Федеральная налоговая служба. Предприниматель для регистрации ИП подает заявление в территориальный отдел ФНС. Регистрация означает запись в реестр.</p>
            <p>Каждое лицо, регистрируемое в качестве ИП, несет ответственность за достоверную информацию, предоставленную в ФНС с целью занесения в ЕГРИП. </p>
        </div>
    </section>
    <div class="ceo-error">
        <div class="container">
            <div class="ceo-error__wrapper">
                <p>Если меняются любые данные, занесенные в реестр и отражаемые в выписке, ИП обязан подать заявление в налоговую службу о необходимости внесения изменений.</p>
            </div>
        </div>
    </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Egrip',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
