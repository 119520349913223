<template>
    <nav class="nav" :class="{'nav__active': mobile__active, 'nav-user': !mobile__active}">
        <div class="container">
            <router-link to="/">
                <img src="@/assets/icons/logo.svg" alt="" class="nav__logo">
            </router-link>
            <div class="nav__list">
                <p class="nav__list_btn" :class="{'nav__list_btn_active': mobile__active}"><a class="pointer op_check" @click="ShowAll()">О сервисе <span>
                    
                </span></a></p>
                <div class="desktop-menu__header" v-show="block_open">
                    <router-link to="/egrul">ЕГРЮЛ</router-link>
                    <router-link to="/ogrnip">ОГРНИП</router-link>
                    <router-link to="/ogrn">ОГРН</router-link>
                    <router-link to="/extract">ЕГРИП/ЕГРЮЛ</router-link>
                    <router-link to="/egrip">ЕГРИП</router-link>
                    <router-link to="/reporting">Отчетность компаний</router-link>
                    <router-link to="/arbitration-court">Арбитраж</router-link>
                    <router-link to="/procurement">Закупки</router-link>
                    <router-link to="/activites">Виды деятельности</router-link>
                    <router-link to="/raiting">Рейтинг</router-link>
                    <router-link to="/director">Руководитель</router-link>
                    <router-link to="/founders">Учредители</router-link>
                    <router-link to="/affiliation">Аффилированность</router-link>
                    <router-link to="/legal_adress">Юридический адрес</router-link>
                </div>
                <ul v-show="mobile_block_open" :class="{'active': mobile__active, 'active__nav_ul': mobile__active}">
                    <li><router-link to="/egrul">ЕГРЮЛ</router-link></li>
                    <li><router-link to="/ogrnip">ОГРНИП</router-link></li>
                    <li><router-link to="/ogrn">ОГРН</router-link></li>
                    <li><router-link to="/extract">ЕГРИП/ЕГРЮЛ</router-link></li>
                    <li><router-link to="/egrip">ЕГРИП</router-link></li>
                    <li><router-link to="/reporting">Отчетность компаний</router-link></li>
                    <li><router-link to="/arbitration-court">Арбитраж</router-link></li>
                    <li><router-link to="/procurement">Закупки</router-link></li>
                    <li><router-link to="/activites">Виды деятельности</router-link></li>
                    <li><router-link to="/raiting">Рейтинг</router-link></li>
                    <li><router-link to="/director">Руководитель</router-link></li>
                    <li><router-link to="/founders">Учредители</router-link></li>
                    <li><router-link to="/affiliation">Аффилированность</router-link></li>
                    <li><router-link to="/legal_adress">Юридический адрес</router-link></li>
                </ul>
                <p><a class="pointer" @click="GoQuery('info')">Источники данных</a></p>    
                <p><a class="pointer" @click="GoQuery('subs')">Тарифы</a></p>
                <p><a class="pointer" @click="GoQuery('faq')">FAQ</a></p>
            </div>
            <router-link v-if="isAuthenticated" 
            to="/my-profile" 
            class="nav__acc authentif pointer" 
            :class="{'mx80': mobile__active}">ЛК</router-link>
            <a v-else 
            @click="modal_reg = true" 
            class="nav__acc pointer"
            :class="{'mx80': mobile__active}">Вход</a>
            <button class="btn__green pointer" @click="step = 0">Ввести промокод</button>
            <div class="nav__humburger" @click="mobile__active = !mobile__active">
                <span></span>
            </div>
        </div>
    </nav>    

    <div class="modal" :class="{'modal__active': modal_reg}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Регистрация</h2>
            <div class="modal__reg">
                <input type="text" v-model="fio" placeholder="ФИО*" />
                <div class="modal__reg_error">
                    <input type="email" v-model="email" @blur="validateEmail" placeholder="E-mail*">
                    <p v-show="validateEmailCh">Почта некорректна</p>
                </div>
                <div class="modal__reg_error">
                    <input type="password" v-model="password" placeholder="Пароль*">
                </div>
                <div class="modal__reg_error">
                    <input type="password" v-model="password2" placeholder="Повторите пароль*">
                    <p v-if="password2" v-show="password != password2">Данные не совпадают</p>
                </div>
                <div class="modal__reg_phone">
                    <input v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'" placeholder="+7 (999) 999-99-99*">
                    <a class="pointer">
                        <img src="@/assets/icons/rus.svg" alt="">
                    </a>
                </div>
                <div class="modal__reg_error" v-if="errors.length != 0">
                    <p v-show="errors">{{ errors[0] }}</p>
                </div>
            </div>
            <div v-if="fio && !validateEmailCh && password && (password2 == password) && phone">
                <button @click="Registration()" class="btn__green">Зарегистрироваться</button>
            </div>
            <div v-else>
                <button class="btn__green" disabled>Зарегистрироваться</button>
            </div>
            
            <p class="modal__ver">Нажимая кнопку «Зарегистрироваться», я соглашаюсь с условиями <a href="#">политики конфиденциальности</a> и <a href="#">пользовательского соглашения</a></p>
            <div class="modal__was">
                <h2>Уже были у нас?</h2>
                <a class="pointer" @click="LogInModal()">Войти</a>
            </div>
            <div class="modal__close pointer" @click="modal_reg = false"></div>
        </div>
    </div>

    <div class="modal" :class="{'modal__active': step == 0}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Активация промокода</h2>
            <div class="modal__reg modal__reg_error">
                <input v-model="promo" type="text" placeholder="Промокод">
                <p v-if="error_promo.length != 0" v-show="error_promo">{{ error_promo[0] }}</p>
            </div>
            <button class="btn__green pointer" v-if="error_promo.length == 0 && prom.id" @click="ToPay()">Активировать</button>
            <button class="btn__green pointer" v-else disabled>Активировать</button>
            <div class="modal__close pointer" @click="step = -1"></div>
        </div>
    </div>      

    <div class="modal" :class="{'modal__active': step == 1}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
            <div class="modal__close pointer"></div>
        </div>
    </div>   

    <div class="modal" :class="{'modal__active': modal_log}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Вход</h2>
            <div class="modal__reg">
                <input v-model="email_log" type="text" placeholder="E-mail*">
                <input v-model="pass_login" type="password" placeholder="Пароль*">
            </div>
            <div class="modal__reg modal__reg_error" v-if="errors.length != 0">
                <p v-for="list in errors">{{ list }}</p>
            </div>
            <button class="btn__green" @click="LogIn()" v-if="email_log && pass_login">Войти</button>
            <button class="btn__green" disabled v-else>Войти</button>
            <a @click="ForgotPassword()" class="modal__reg_link pointer">Забыли пароль?</a>
            <div class="modal__was">
                <h2>Еще нет аккаунта?</h2>
                <a class="pointer" @click="RegInModal()">Зарегистрироваться</a>
            </div>
            <div class="modal__close" @click="modal_log = false"></div>
        </div>
    </div>


    <ForgotPassModal 
        :modal="forgot_pass"
        v-on:modal="forgot_pass = $event"
        v-on:value="modal_log = $event"
    />

    <ModalSub 
      :tarif__modal="tarif__modal" 
      :current_price="current_price"
      :current_tarif="current_tarif"
      :prom="promo"
      v-on:promo="promo = $event" 
      v-on:modal="tarif__modal = $event" 
      v-on:price="current_price = $event" 
    />

</template>

<style scoped>
    @media (max-width: 1024px) {
        .desktop-menu__header {
            display: none !important;
        }
    }

    .mx80 {
        max-width: 80px !important;
    }

    .authentif {
        justify-content: space-around !important;
    }

    .desktop-menu__header {
        display: flex;
        position: absolute;
        top: 70px;
        box-shadow: 0px 38px 107px rgb(0 0 0 / 5%), 0px 7.6px 17.3875px rgb(0 0 0 / 3%);
        padding: 16px 0px;
        border-radius: 8px;
        width: 230px;
        background: #FFFFFF;
        z-index: 10;
        flex-direction: column;
    }

    .desktop-menu__header a {
        font-style: normal;
        font-weight: 400;
        width: 100%;
        background: #FFFFFF;
        padding: 8px 20px;
        font-size: 18px;
        line-height: 28.8px;
        color: #272833;
        cursor: pointer;
        white-space: nowrap;
        transition: 0.25s;
    }
/*
    .desktop-menu__header li.active {
        background: #00D45F;
        font-weight: 400;
        color: #FFFFFF;
    }*/

    .desktop-menu__header a:hover {
        background: #00c358;
        color: #fff !important;
        font-weight: 400 !important;
    }
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import ModalSub from '@/components/Subs/ModalSub.vue'
    import ForgotPassModal from '@/components/Modal/ForgotPassword.vue'

    export default {
        name: "Header",
        components: {
            ModalSub,
            ForgotPassModal
        },
        directives: { mask },
        data() {
            return {
                modal_reg: false,
                modal_log: false,
                step: -1,
                mobile__active: false,

                tarif__modal: false,
                current_tarif: null,
                current_price: null,
                promo: "",
                prom: {},
                error_promo: [],

                // reg

                fio: "",
                phone: "",
                email: "",
                password: "",
                password2: "",

                email_log: "",
                pass_login: "",

                errors: [],
                id_new_user: 0,

                user_account: {},

                validateEmailCh: false,
                isAuthenticated: false,

                block_open: false,
                mobile_block_open: false,
                forgot_pass: false,
            }
        },
        mounted() {
            this.isAuthenticated = this.$store.state.isAuthenticated
        },
        methods: {
            GoTo(redirect) {
              this.$router.push(redirect)
            },
            GoQuery(value) {
              this.$router.push({path: '/', query: { redirect: value }})
              this.mobile__active = false
            },
            ShowAll() {
                this.block_open = !this.block_open
                this.mobile_block_open = !this.mobile_block_open
            },
            Registration() {
                this.submitForm()
            },
            validateEmail() {
                if (/@\w+([\.-]?\w+)/.test(this.email)) {
                    this.validateEmailCh = false
                } else {
                    this.validateEmailCh = true
                }
            },
            ForgotPassword() {
                this.forgot_pass = true
                this.modal_log = false

            },
            async SendEmailReg(value) {
                await axios
                .post(`/api/v1/send_reg_email/`, {email: value})
                .then(response=> {
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
            },
            async CheckPromo() {
                  this.prom = {}

                  await axios
                  .get(`/api/v1/promocode/${this.promo}/`)
                  .then(response => {
                      this.prom = response.data
                      this.error_promo = []

                      if (this.prom) {
                        this.current_tarif = this.prom.sub

                        if (Number(response.data.request_num) == 0) {
                            this.error_promo.push('Срок действия промокода закончился.')
                        }

                        if (this.error_promo.length == 0) {
                            this.Price()
                            this.current_price = (parseInt(this.current_price) * (100 - this.prom.percent)) / 100;

                            if (Number(this.current_price) == 0) {
                                this.current_price = 1
                            }
                        }
                      }

                  })
                  .catch(error => {
                        this.error_promo.push('Промокода не существует.')
                  })
            },
            async LogIn() {
                this.errors = []

                axios.defaults.headers.common["Authorization"] = ""

                localStorage.removeItem("token")

                const formData = {
                    username: this.email_log,
                    password: this.pass_login
                }

                await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token

                    localStorage.setItem("token", token)

                    this.$store.commit('setUser', this.user_account.id)

                    localStorage.setItem("user_id", this.user_account.id)

                    localStorage.setItem("user", this.user_account)

                    this.$store.commit('setUserInfo', this.user_account)

                    this.GoTo('/my-profile')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Что-то пошло не так...')
                    }
                })
            },
            async submitForm() {
                this.errors = []

                if (!this.errors.length) {
                    const formData = {
                          username: this.email,
                          password: this.password,
                          email: this.email,
                    }

                    await axios
                        .post("/api/v1/users/", formData)
                        .then(response => {
                            this.id_new_user = response.data.id
                        })
                        .catch(error => {
                            if (error.response) {
                                for (const property in error.response.data) {
                                    this.errors.push(`${error.response.data[property]}`)
                                }
                            } else if (error.message) {
                                this.errors.push('Попробуйте еще раз, произошла ошибка')
                            }
                        })
                    
                    if (this.id_new_user != 0) {
                        const UserData = {
                            user: this.id_new_user,
                            fio: this.fio,
                            email: this.email,
                            phone: this.phone,
                        }

                        await axios
                            .post("/api/v1/myProfiles/", UserData)
                            .then(response => {
                                this.user_account = response.data
                            })
                            .catch(error => {
                                console.log(error)
                            })

                        axios.defaults.headers.common["Authorization"] = ""

                        localStorage.removeItem("token")

                        await axios
                        .post("/api/v1/token/login/", formData)
                        .then(response => {
                            const token = response.data.auth_token

                            this.SendEmailReg(this.user_account.email)

                            this.$store.commit('setToken', token)
                            
                            axios.defaults.headers.common["Authorization"] = "Token " + token

                            localStorage.setItem("token", token)

                            this.$store.commit('setUser', this.user_account.id)

                            localStorage.setItem("user_id", this.user_account.id)

                            localStorage.setItem("user", this.user_account)

                            this.$store.commit('setUserInfo', this.user_account)

                            this.GoTo('/my-profile')
                        })
                        .catch(error => {
                            if (error.response) {
                                for (const property in error.response.data) {
                                    this.errors.push(`${property}: ${error.response.data[property]}`)
                                }
                            } else {
                                this.errors.push('Что-то пошло не так...')
                                
                                console.log(JSON.stringify(error))
                            }
                        })
                    }

                }
            },
            RegInModal() {
                this.modal_reg = true
                this.modal_log = false
            },
            LogInModal() {
                this.modal_reg = false
                this.modal_log = true
            },
            ToPay() {
                this.step = 1
 
                setTimeout(() => {
                    this.step = -1
                    this.tarif__modal = true;
                }, 1500)
            },
            Price() {
                if (this.current_tarif == 1) {
                    this.current_price = 350
                }

                if (this.current_tarif == 2) {
                    this.current_price = 9900
                }

                if (this.current_tarif == 3) {
                    this.current_price = 49900
                }
            },
        },
        watch: {
            promo(value) {
                this.promo = value

                if (this.promo == 'none') {
                    this.promo = ''
                }

                if (this.promo) {
                    this.CheckPromo()
                }
            },
        },
    }
</script>