<template>
    <ul class="main-llc__tab main-llc__tab_full">
        <li @click="SetValue(0)" :class="{'active': tab_current == 0}">Общие данные</li>
        <li @click="SetValue(1)" :class="{'active': tab_current == 1}">Надежность</li>
        <li @click="SetValue(2)" :class="{'active': tab_current == 2}">Финансы</li>
        <li @click="SetValue(3)" :class="{'active': tab_current == 3}">Арбитражные дела</li>
        <li @click="SetValue(4)" :class="{'active': tab_current == 4}">Госзакупки</li>
        <li @click="SetValue(5)" :class="{'active': tab_current == 5}">Лицензии</li>
        <li @click="SetValue(8)" :class="{'active': tab_current == 8}">Виды деятельности</li>
<!--         <li>Учредители</li> -->
<!--         <li @click="SetValue(6)" :class="{'active': tab_current == 6}">Связанные организации</li> -->
        <li @click="OpenAll()" class="main-llc__tab_more">Ещё</li>
    </ul>
    <ul v-show="block" class="more__tab_open">
        <li @click="SetValue(10)" :class="{'active': tab_current == 10}">Банкротство</li>
        <li @click="SetValue(11)" :class="{'active': tab_current == 11}">Филлиалы</li>
        <li @click="SetValue(12)" :class="{'active': tab_current == 12}">Реквизиты</li>
        <li @click="SetValue(7)" :class="{'active': tab_current == 7}">Долги</li>
        <li @click="SetValue(9)" :class="{'active': tab_current == 9}">Проверки</li>
        <li @click="SetValue(13)" :class="{'active': tab_current == 13}">Товарные знаки</li>
        <li @click="SetValue(14)" :class="{'active': tab_current == 14}">Лента изменений</li>
        <li @click="SetValue(15)" :class="{'active': tab_current == 15}">Учредители</li>
    </ul>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import moment from 'moment';

    export default {
        name: "NavigationMenu",
        props: {
            tab_current: Number,
        },
        components: {

        },
        data() {
            return {
                block: false,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            SetValue(value) {
                this.$emit('value', value)
            },
            OpenAll() {
                this.block = !this.block
            },
        },
        created() {

        },
        watch: {

        },
   }
</script>