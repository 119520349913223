 <template>
    <Header />
    <div class="full__white">
    <section class="ceo-title">
        <div class="container">
            <h1>О сервисе</h1>
            <p>Годовые показатели из бухгалтерии говорят о многом. Они подскажут, стоит ли вступать в денежные отношения с новой для вас фирмой, и в каком масштабе эти отношения будут иметь наименьший риск.</p>
            <img src="@/assets/img/about_service.svg" alt="" class="ceo__about_service">
        </div>
    </section>
    <section class="affril-list affril-list__activities">
        <div class="container">
            <div class="affril-list__name">
                <h2>Используя ресурс Datafix, вы можете:</h2>
            </div>
            <ul>
                <li>Получать регистрационные данные;</li>
                <li>Проверять аффилированность, массовость адреса, присутствие в реестре дисквалифицированных лиц, наличие недостоверных сведений;</li>
                <li>Узнавать информацию о наличии судебных дел и исполнительных производств;</li>
                <li>Получать краткий финансовый анализ, отчетность, участие вы тендерах;</li>
                <li>Узнать, какими товарными знаками и лицензиями владеет контрагент;</li>
                <li>Вести автоматический мониторинг изменений информации.</li>
                <li>Проверять физических лиц, в том числе подлинность паспортов по базе МВД.</li>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Выделите себя среди конкурентов - добавляйте данные о своей компании, товарах и услугах. Размещайте рекламу своей организации.</p>
            <p>Сформируйте запрос и скачайте базу данных потенциальных клиентов с телефонами, адресами и руководством.</p>
        </div>
    </section>
    <div class="ceo-info ceo-info__report">
        <div class="container">
            <div class="ceo-info__text">
                <p>При устройстве на работу бесплатно проверяйте работодателей.</p>
                <p>Начиная проверку достаточно ввести ИНН организации. Для работы с ресурсом у нас разработаны гибкие тарифы.</p>
                <button class="btn__green" @click="GoQuery('subs')">Выбрать тариф</button>
            </div>
            <div class="ceo-info__img">
                <img src="@/assets/img/seo_datafix.svg" alt="">
            </div>
        </div>
    </div>
    </div>
    <Footer />
</template>

<style scoped>

@media (max-width: 768px) {

.ceo__about_service {
    position: relative !important;
    top: 0px !important;
    right: 0px;
    width: 100%;
}


}

.ceo-info img {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    box-shadow: 0px 0px 0px !important;
    border-radius: 0px !important;
}
.ceo__about_service {
    position: absolute;
    top: 20px;
    right: 0px;
    width: auto;
}    
</style>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'AboutService',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoQuery(value) {
      this.$router.push({path: '/', query: { redirect: value }})
    },
  },
}
</script>
