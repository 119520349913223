<template>
    <HeaderProfile />

    <section class="search">
        <div class="container">
            <div class="search-block" v-if="!loader">
                <div class="item-search-h3">
                    <h3>Результаты поиска ({{ total }})</h3>
                </div>
                <div class="item-search-h3" v-if="!items.length">
                    <h4>Данных нет</h4>
                </div>
                <div v-else class="item-search pointer" v-for="(list, index) in items" :key="list" @click="RedirectToCard(list)">
                    <div class="flex-item flex-item-first ip__type" v-if="list.ТипДокумента == 'ip'">
                        <p>
                            ИП 
                            <span v-if="list.Фамилия">{{ list.Фамилия }}</span> <span v-if="list.Имя">{{ list.Имя }}</span> <span v-if="list.Отчество">{{ list.Отчество }}</span>
                        </p>
                        <h5 v-if="list.ИННФЛ">
                            ИННФЛ: <span>{{ list.ИННФЛ }}</span>
                        </h5>
                    </div>
                    <div class="flex-item ip__block" v-if="list.Активность && list.ТипДокумента == 'ip'">
                        <span class="status">
                            {{ list.Активность }}
                        </span>                        
                    </div>
                    <div class="flex-item" v-if="list.КодОКВЭД && list.НаимОКВЭД && list.ТипДокумента == 'ip'">
                        <h5>
                            ОКВЭД: <span>{{ list.КодОКВЭД }} </span> <span>{{ list.НаимОКВЭД }}</span>
                        </h5>                        
                    </div>
                    <div class="flex-item flex-item-first" v-if="list.ТипДокумента == 'ul'">
                        <p v-if="list.НаимЮЛСокр">
                            {{ list.НаимЮЛСокр }}
                        </p>
                        <span v-if="list.Активность" class="status">
                            {{ list.Активность }}
                        </span>
                        <h5 v-if="list.ИНН">ИНН: <span>{{ list.ИНН }}</span></h5>
                        <h5 v-if="list.КПП">
                            КПП: <span>{{ list.КПП }}</span>
                        </h5>
                    </div>
                    <div class="flex-item" v-if="list.Руководитель && list.ТипДокумента == 'ul'">
                        <h5>
                            Руководитель: <span v-html="list.Руководитель[0]"></span>
                        </h5>                        
                    </div>
                    <div class="flex-item" v-if="list.Адрес && list.ТипДокумента == 'ul'">
                        <h5>
                            Адрес: <span>{{ list.Адрес }}</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="loader__block" v-else>
                <div class="loader-wait">
                    <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
                </div>     
            </div>
        </div>
    </section>

    <Footer />   
</template>

<style scoped>
    .search-block {
        margin: 30px 0 50px;
    }

    .loader__block {
        margin: 120px 0 120px !important;
    }

    .search-block .item-search-h3 {
        max-width: 70%;
/*        background: #FFFFFF;*/
        border-radius: 8px 8px 0px 0px; 
        padding: 25px 20px;
    }

    @media (max-width: 1024px) {
        .search-block .item-search {
            max-width: 100% !important;
        }   
        .flex-item {
            display: flex;
            width: 100% !important;
            max-width: 100% !important;
            justify-content: flex-start !important;
            flex-wrap: wrap !important;
            flex-direction: column !important;
        }     
        .flex-item-first {
            margin-bottom: 0px !important;
        }
        .flex-item h5 {
            margin: 3px 0px;
        }
        .flex-item .status {
            width: 125px !important;
            margin: 8px 0px;
        }
    }

    .flex-item-first {
        margin-bottom: 10px;
    }

    .flex-item .status {
        font-weight: 700;
        font-size: 12px;
        line-height: 230%;
        /* identical to box height, or 14px */


        /* green */

        border: 1px solid #00D45F;
        border-radius: 32px;
        padding: 1px 18px;
        color: #00D45F;    
    }

    .flex-item {
        display: flex;
        width: 100%;
        max-width: 70%;
        justify-content: space-between;
    }

    .flex-item h5 {
        font-weight: 400;
        font-size: 13px;
        line-height: 230%;
        /* or 20px */


        color: #737373;       
    }

    .ip__block {
        margin: 10px 0 10px;
    }

    .ip__block .status {
        border-radius: 6px !important;
    }

    .flex-item h5 span {
        font-weight: 500;
        font-size: 13px;
        line-height: 230%;
        /* or 20px */


        color: #000000;
    }

    .item-search {
        max-width: 70%;
        background: #FFFFFF;
        padding: 25px 20px;
        box-shadow: 0px 38px 107px rgba(0, 0, 0, 0.05), 0px 7.6px 17.3875px rgba(0, 0, 0, 0.025);
    }

    .item-search:hover {
        opacity: 0.8 !important;
        box-shadow: none !important;
    }

    .ip__type p {
        font-weight: 500;
    }
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    import Footer from '@/components/Footer/Footer.vue'
    import HeaderProfile from '@/components/Headers/HeaderProfile.vue'

    export default {
        name: "Search",
        directives: { mask },
        props: {

        },
        components: {
            HeaderProfile,
            Footer,
        },
        data() {
            return {
                query: "",
                total: 0,
                current_value: null,

                loader: true,

                items: [],
                user: {},

                check: "",
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            let uri = window.location.search.substring(1)
            let params = new URLSearchParams(uri)

            if (params.get('query')) {
                this.query = params.get('query')
                this.GetSearch(this.query)
            }
        },
        methods: {
            async GetMeInfo() {
                await axios
                    .get('/api/v1/me/')
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            // async GetSearch() {
            //       await axios
            //       .get('/api/v1/search/')
            //       .then(response => {
            //             this.items = response.data.body.docs
            //             this.total = response.data.body.total
            //       })
            //       .catch(error => {
            //           console.log(error)
            //       })
                 
            // },
            async GetSearch(value) {
                  await axios
                  .get(`/api/v1/search/${value}`)
                  .then(response => {
                        this.items = response.data.body.docs
                        this.total = response.data.body.total
                        this.loader = false
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            },
            async CheckRequest(list) {
                  await axios
                  .get(`/api/v1/history/check/${list.id}/`)
                  .then(response => {
                        this.check = response.data.status
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            },
            async PostRequest(list) {
                var inn = ""
                var kpp = ""
                var innfl = ""
                var title = ""

                if (list.ИНН) {
                    inn = list.ИНН
                }

                if (list.КПП) {
                    kpp = list.КПП
                }

                if (list.ИННФЛ) {
                    innfl = list.ИННФЛ
                }

                if (list.ТипДокумента == 'ul') {
                    title = list.НаимЮЛСокр
                }

                if (list.ТипДокумента == 'ip') {
                    title = 'ИП' + ' ' + list.Фамилия + ' ' + list.Имя + ' ' + list.Отчество
                }

                const dataForm = {
                    user: this.user.user,
                    title: title,
                    inn: inn,
                    kpp: kpp,
                    innfl: innfl,
                    status: list.Активность,
                    project_id: list.id,
                    type_project: list.ТипДокумента,

                }

                await axios
                .post('/api/v1/history/post/', dataForm)
                .then(response => {
                    console.log()
                })
                .catch(error => {
                  console.log(error)
                })
                 
            },
            RedirectToCard(object) {
                this.CheckRequest(object)
                setTimeout(() => {
                    if (String(this.check) == "error") {
                        this.PostRequest(object)
                    }
                }, 1000)

                if (object.ТипДокумента == 'ul') {
                    this.$router.push({path: '/ooo', query: { project_id: object.id }})
                } else {
                    this.$router.push({path: '/ip', query: { project_id: object.id }})
                }
                
            },
            data(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY')
              return datasort
            },  
            dataSub(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD MMMM YYYY')
              return datasort
            },  
            dataHistoryPay(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY в h:mm')
              return datasort
            },  
        },
        created() {
            this.GetMeInfo()
        },
        watch: {
            $route() {
                this.query = this.$route.query.query
                this.GetSearch(this.query)
            },
        }
   }
</script>