<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__phone">
        <div class="container">
            <h1>Учредители</h1>
            <p>Учредители компании — это собственники коммерческой организации, которые имеют право на распределение прибыли от ее деятельности, доступ ко всей информации и принятие управленческих решений. Учредитель может выйти из состава учредителей, продав свою долю в компании, и получить часть имущества в случае ликвидации.</p>
        </div>
    </section>
    <section class="affril-list affril-list__activities">
        <div class="container">
            <div class="affril-list__name">
                <h2>Сведения из ФНС</h2>
                <p>Главные сведения о собственниках содержатся в базе данных ФНС. Выписка из ЕГРЮЛ показывает основную актуальную информацию:</p>
            </div>
            <ul>
                <li>ФИО граждан или названия юридических лиц, являющихся учредителями</li>
                <li>ИНН</li>
                <li>Для акционерных обществ - сведения о держателях реестров акционеров</li>
                <li>Для ООО — номинальная стоимость и доля в уставном капитале</li>
                <p class="affril-list__activities_more">База данных ФНС в реестре дисквалифицированных лиц содержит сведения о вступивших в силу постановлениях о дисквалификации учредителя.</p>
                <p class="affril-list__activities_more">Проверка на аффилированность (поиск связей) позволит узнать, является ли учредитель участником других юридических лиц в качестве учредителя или руководителя.</p>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <h2>Зачем проверять учредителей</h2>
            <p>Используя возможности нашего сервиса, получите информацию, которая играет важную роль в проверке контрагента. Этот поиск особенно полезен при проверке на т.н. «массовость директора» и «массовость учредителя».</p>
            <p>Присутствие одного и того же лица в нескольких компаниях в качестве учредителя и директора должно настораживать.</p>
        </div>
    </section>
    <div class="ceo-error">
        <div class="container">
            <div class="ceo-error__wrapper">
                <p>Согласно статьи 23 ФЗ-129, после внесения в ЕГРЮЛ записи о недостоверных сведениях, учредитель, имеющий долю не менее 50%, не может стать учредителем или руководителем другой организации.</p>
            </div>
        </div>
    </div>
    <section class="ceo-prev ceo-prev__director">
        <div class="container">
            <div class="ceo-prev__info">
                <p>Кроме информации из ФНС будет полезен и ресурс ФССП, содержащий информацию о наличии у граждан и юрлиц открытых исполнительных производств, предмета взыскания и суммы.</p>
                <p>Воспользоваться поиском информации будет полезно и тем, кто намерен проверить, не записан ли он в качестве учредителя какой-либо компании без своего ведома, ведь именно таким образом поступают мошенники для регистрации фирм-однодневок.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <img src="@/assets/img/ceo-info_director.jpg" alt="">
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Founders',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
