<template>
    <div class="inner-page">
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="fillial">
            <h1>Филиалы ({{ total_filials }})</h1>
            <div class="fillial__wrapper" v-if="total_filials != 0">
                <div class="fillial__text">
                    <div class="fillial__item" v-for="item in items_filials">
                        <p class="fillial__name"><img src="@/assets/icons/map.svg" alt="">{{ item.НаимПредПолн }}</p>
                        <ul>
                            <li v-if="item.Адрес ">
                                <p><span>Адрес</span>{{ item.Адрес }}</p>
                            </li>
                            <li>
                                <p><span>Дата создания</span>{{ item.ДатаАккр }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
<!--                 <div class="fillial__map">
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aeb58dd08a4129895d5c0cafcbadfe3e6db8c5bb994ab4fcefd43cc5205373154&amp;source=constructor" width="100%" height="345" frameborder="0"></iframe>
                </div> -->
            </div>
            <div v-else class="none__databody">
                <h4>Нет данных</h4>
            </div>
        </div>        
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Filials",
        directives: { mask },
        props: {
            items_filials: [Object, Array],
            total_filials: Number,
            card_info: Object,
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            dateUpdate(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY')
              return datasort
            },  
        },
        created() {

        },
        watch: {

        },        
   }
</script>