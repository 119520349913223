<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__reliab">
        <div class="container">
            <h1>Проверка надежности контрагентов и партнеров</h1>
            <p>Любой бизнес — это хлопотное и рискованное мероприятие. Вложенные в него финансы сами по себе не в состоянии сделать вашу компанию устойчивой в условиях современного рынка. Любая деятельность подразумевает взаимодействие с множеством контрагентов, и от их добросовестного сотрудничества будет зависеть успех предприятия.</p>
        </div>
    </section>
    <section class="reliab-check">
        <div class="container">
            <img src="@/assets/img/reliab_img.png" alt="">
            <div class="reliab-check__info">
                <h2>Зачем нужна проверка</h2>
                <p>Неумолимая статистика показывает, что большинство компаний сталкивались с недобросовестными организациями, и причиненный ущерб начинается от небольших потерь до вынужденного банкротства. Даже заключенный договор не всегда является гарантией выполнения условий сделки.</p>
            </div>
        </div>
    </section>
    <section class="affril-list affril-list__reliab">
        <div class="container">
            <h2>Существует три основных риска при взаимодействии со сторонними компаниями:</h2>
            <ul>
                <li>Поставщик товаров/услуг, получив предоплату, не выполняет обязательств или предоставляет товары/услуги ненадлежащего качества</li>
                <li>Покупатель товаров/услуг не оплачивает отгруженные без предоплаты товары/услуги</li>
                <li>Недобросовестный партнер не выполняет свою часть обязанностей по трехстороннему совместному договору, что вызывает конфликтную ситуацию в отношениях с третьей стороной.</li>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Когда становится понятным, что контрагент или партнер не выполнил условия сделки, предприниматели сталкиваются с тем, что другая сторона затягивает переговоры по урегулированию конфликта, отказывается выполнять условия, оказывается банкротом или попросту исчезает.</p>
            <p>Стоит знать, что помимо просто недобросовестных партнеров, на рынке есть откровенные мошенники, бизнес которых заключается в незаконном отъеме средств компаний, а их имидж направлен именно на то, чтобы вызвать доверие и усыпить вашу бдительность.</p>
        </div>
    </section>
    <div class="ceo-prev">
        <div class="container">
            <div class="ceo-prev__info">
                <p>Для максимального снижения рисков обязательно проверяйте новых контрагентов и партнеров перед вступлением с ними в денежные отношения.</p>
                <p>Это сбережет ваши средства, время, нервы и поможет избежать санкций со стороны налоговой службы, ведь для налоговиков наличие недобросовестного контрагента всегда является поводом для серьезных проверок и штрафов.</p>
            </div>
            <img src="@/assets/img/ceo-prev_reliab.jpg" alt="">
        </div>
    </div>
    <div class="ceo-check">
        <div class="container">
            <h2>Что мы проверяем</h2>
            <ul>
                <li>Самый первый шаг на пути проверки — это запрос выписки ЕГРЮЛ/ЕГРИП. Этот шаг всегда можно сделать самостоятельно. Такая выписка дает первоначальные сведения о контрагенте. Мы не рекомендуем взаимодействовать с организациями, регистрация которых состоялась менее года назад — одна из трех компаний прекращает фактическую деятельность в течение первого года.</li>
                <li>Далее запросите у второй стороны копии основных документов — устав, протокол о назначении генерального директора и действующую доверенность. Попросите сведения о наличии основных средств. Отсутствие таковых должно вас насторожить. Если вам эти документы предоставят — это уже хороший знак, говорящий о том, что партнер настроен серьезно и готов работать с вами.</li>
            </ul>
        </div>
    </div>
    <section class="ceo-list">
        <div class="container">
            <p class="ceo-list__title">Но срок работы и пакет основных документов не станут гарантией добрых отношений, и мы проверяем вашего будущего контрагента и партнера с разных ракурсов, что дает полную картину о его порядочности и надежности:</p>
            <ul>
                <li>
                    <p>Отсутствие предстоящего исключения из ЕГРЮЛ</p>
                </li>
                <li>
                    <p>Изменения в ЕГРЮЛ</p>
                    <p>Не менялся ли собственник или директор</p>
                </li>
                <li>
                    <p>Отсутствие дисквалификации директора</p>
                </li>
                <li>
                    <p>Действительность паспорта директора</p>
                </li>
                <li>
                    <p>Номинальность директора</p>
                    <p>Не является ли он руководителем нескольких организаций</p>
                </li>
                <li>
                    <p>Адрес на массовую регистрацию фирм</p>
                </li>
                <li>
                    <p>Уровень оборотов компании и количество сотрудников</p>
                </li>
                <li>
                    <p>Регистрация в ПФР</p>
                </li>
                <li>
                    <p>Прибыльность или убыточность бизнеса</p>
                </li>
                <li>
                    <p>Сведения о банкротстве</p>
                </li>
                <li>
                    <p>Действительные лицензии</p>
                </li>
                <li>
                    <p>Наличие проигранных, выигранных и текущих арбитражных дел</p>
                </li>
                <li>
                    <p>Наличие исполнительных производств</p>
                </li>
                <li>
                    <p>Рейтинг компании</p>
                </li>
                <li>
                    <p>Прочие данные</p>
                </li>
            </ul>
        </div>
    </section>
    <div class="ceo-info ceo-info__reliab">
        <img src="@/assets/img/potential_2.svg" alt="" class="ceo-info__bg">
        <img src="@/assets/img/potential_1.svg" alt="" class="ceo-info__bg">
        <div class="container">
            <div class="ceo-info__text">
                <p>На основании вышеуказанных сведений вы сможете составить полную картину о наличии или отсутствии реальных рисков при работе с контрагентом. Она может быть дополнена вашим личным знакомством с новой компанией, получением негласных или публичных отзывов о ее работе.</p>
                <p>Сервис позволяет проверять контрагента с разных ракурсов, для чего на нем предусмотрены разнообразные инструменты, работающие на основании обработки информации из многочисленных открытых источников. Мы постоянно мониторим запросы компаний по количеству и структуре актуальных проверок для формирования комфортных и выгодных условий для заказчиков. Наш сервис предлагает несколько тарифов обслуживания. Анализ показывает, что тариф «СТАНДАРТ» — наиболее оптимальное и выгодное предложение на рынке подобных услуг. Желаем вам успешной работы только с добросовестными партнерами!</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <div class="ceo-info__img">
                <img src="@/assets/img/ceo-info_Reliability.svg" alt="">
            </div>
        </div>
    </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'RealibilityCheck',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
