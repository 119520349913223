<template>
    <HeaderProfile />

    <div class="main-llc">
        <div class="container container-relative">
            <NavigationMenu 
                v-on:value="tab = $event" 
                :tab_current="tab"
            />

            <section v-if="tab == 0">
                <Info
                    :card_info="card_info"
                    :items_participant="items_participant"
                    :items_customer="items_customer"
                    :total_customer="total_customer"
                    :total_partipant="total_partipant"
                    :items_arbitaj="items_arbitaj_info"
                    :total_arbitaj="total_arbitaj"
                    :zvezdi="zvezdi"
                    :success="success"
                    :danger="danger"
                    :warning="warning"
                    :project_id="project_id"
                    :items_izmeneniya="items_izmeneniya"
                    :items_proverki="items_proverki"
                    :items_filials="items_filials"
                    :total_filials="total_filials"
                    :total_svyaz="total_svyaz"
                    :items_svyaz="items_svyaz"
                    :items_dolgi="items_dolgi"
                    :total_dolgi="total_dolgi"
                    v-on:value="tab = $event" 
                />
            </section>
            <section v-if="tab == 1">
                <Nadejnost
                    :card_info="card_info"
                    :success="success"
                    :danger="danger"
                    :warning="warning"
                 />
            </section>
            <section v-if="tab == 2">
                <Finance
                    :card_info="card_info"
                />
            </section>
            <section v-if="tab == 3">
                <Arbitraj
                    :card_info="card_info"
                    :items_arbitaj="items_arbitaj"
                    :total_arbitaj="total_arbitaj"
                />
            </section> 
            <section v-if="tab == 4">
                <Goszakypki
                    :card_info="card_info"
                    :items_participant="items_participant"
                    :items_customer="items_customer"
                    :total_customer="total_customer"
                    :total_partipant="total_partipant"
                />
            </section>
            <section v-if="tab == 5">
                <Licenses
                    :card_info="card_info"
                />
            </section>
            <section v-if="tab == 6">
                <Connect
                    :card_info="card_info"
                    :total_svyaz="total_svyaz"
                    :items_svyaz="items_svyaz"
                />
            </section>
            <section v-if="tab == 7">
                <More 
                    :card_info="card_info"
                    :items_dolgi="items_dolgi"
                    :total_dolgi="total_dolgi"
                />
            </section>    
            <section v-if="tab == 8">
                <DopOkved 
                    :card_info="card_info"
                />
            </section>    
            <section v-if="tab == 9">
                <Proverki 
                    :total_proverki="total_proverki"
                    :items_proverki="items_proverki"
                    :card_info="card_info"
                />
            </section>  
            <section v-if="tab == 10">
                <Bankrotstvo 
                    :card_info="card_info"
                />
            </section>  
            <section v-if="tab == 11">
                <Filials 
                    :items_filials="items_filials"
                    :total_filials="total_filials"
                    :card_info="card_info"
                />
            </section>  
            <section v-if="tab == 12">
                <Rekviziti 
                    :card_info="card_info"
                />
            </section>  
            <section v-if="tab == 13">
                <TovarnieZnaki 
                    :card_info="card_info"
                />
            </section> 
            <section v-if="tab == 14">
                <LentaIzmeneniy 
                    :items_izmeneniya="items_izmeneniya"
                    :card_info="card_info"
                />
            </section> 
            <section v-if="tab == 15">
                <Uchrediteli 
                    :card_info="card_info"
                />
            </section> 
        </div>
    </div>

    <Footer />   
</template>

<style scoped>
.container-relative {
    position: relative;
}
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    import More from '@/components/OOO/More.vue'
    import Info from '@/components/OOO/Info.vue'
    import Nadejnost from '@/components/OOO/Nadejnost.vue'
    import Licenses from '@/components/OOO/Licenses.vue'
    import Arbitraj from '@/components/OOO/Arbitraj.vue'
    import Connect from '@/components/OOO/Connect.vue'
    import Finance from '@/components/OOO/Finance.vue'
    import Goszakypki from '@/components/OOO/Goszakypki.vue'
    import DopOkved from '@/components/OOO/DopOkved.vue'
    import Proverki from '@/components/OOO/Proverki.vue'
    import Bankrotstvo from '@/components/OOO/Bankrotstvo.vue'
    import Filials from '@/components/OOO/Filials.vue'
    import Rekviziti from '@/components/OOO/Rekviziti.vue'
    import TovarnieZnaki from '@/components/OOO/TovarnieZnaki.vue'
    import LentaIzmeneniy from '@/components/OOO/LentaIzmeneniy.vue'
    import Uchrediteli from '@/components/OOO/Uchrediteli.vue'

    import Footer from '@/components/Footer/Footer.vue'
    import HeaderProfile from '@/components/Headers/HeaderProfile.vue'
    import NavigationMenu from '@/components/OOO/NavigationMenu.vue'

    export default {
        name: "OOO",
        directives: { mask },
        props: {

        },
        components: {
            Uchrediteli,
            LentaIzmeneniy,
            TovarnieZnaki,
            Rekviziti,
            Filials,
            Bankrotstvo,
            DopOkved,
            Goszakypki,
            Finance,
            Connect,
            Nadejnost,
            Arbitraj,
            Licenses,
            Proverki,
            Info,
            More,
            HeaderProfile,
            NavigationMenu,
            Footer,
        },
        data() {
            return {
                project_id: "",

                tab: 0,
                card_info: {},

                success: [],
                danger: [],
                warning: [],

                items_svyaz: [],
                total_svyaz: 0,

                items_filials: [],
                total_filials: 0,

                items_izmeneniya: {},

                total_dolgi: 0,
                items_dolgi: [],

                items_participant: [],
                items_customer: [],
                total_partipant: 0,
                total_customer: 0,

                items_arbitaj: {},
                items_arbitaj_info: [],
                total_arbitaj: 0,

                total_proverki: 0,
                items_proverki: [],

                zvezdi: 1,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            // async GetInfoCard() {
            //       await axios
            //       .get('/api/v1/ooo/info/')
            //       .then(response => {
            //             this.card_info = response.data.body
            //             console.log(this.card_info)
            //       })
            //       .catch(error => {
            //           console.log(error)
            //       })            
            // },
            async GetInfoCard(uid) {
                  await axios
                  .get(`/api/v1/ooo/info/${uid}`)
                  .then(response => {
                        this.card_info = response.data.body
                        console.log(this.card_info)
                  })
                  .catch(error => {
                      console.log(error)
                  })
            },
            async GetParticipant(inn) {
                  await axios
                  .get(`/api/v1/ooo/zakupki/participant/${inn}`)
                  .then(response => {
                        this.items_participant = response.data.body.docs
                        this.total_partipant = response.data.body.total
                        console.log(response.data)
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            },  
            async GetCustomer(inn) {
                  await axios
                  .get(`/api/v1/ooo/zakupki/participant/${inn}`)
                  .then(response => {
                        this.items_customer = response.data.body.docs
                        this.total_customer = response.data.body.total
                        console.log(response.data)
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetListingArbitraj(uid) {
                  await axios
                  .get(`/api/v1/ooo/arbitraj/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_arbitaj = response.data.body.точно.дела

                        const item_ar = Object.keys(response.data.body.точно.дела)
                        for (let q = 0; q < Object.keys(response.data.body.точно.дела).length; q++) {
                            if (q < 2) {
                                const item1 = item_ar[q]
                                const item2 = response.data.body.точно.дела[item1]
                                this.items_arbitaj_info.push(item2)
                            }
                        } 

                        this.total_arbitaj = response.data.body.точно.всего
                  })
                  .catch(error => {
                      console.log(error)
                  })
            },        
            async GetSvyazi(uid) {
                  await axios
                  .get(`/api/v1/ooo/svyaz/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_svyaz = response.data.body.docs
                        this.total_svyaz = response.data.body.total
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetRating(uid) {
                  await axios
                  .get(`/api/v1/ooo/rating/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.CheckRating(response.data.body.rating_category, response.data.body.risk_level)
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetNadejnost(uid) {
                  await axios
                  .get(`/api/v1/ooo/nadejnost/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.success = response.data.body.success
                        this.danger = response.data.body.danger
                        this.warning = response.data.body.warning
                  })
                  .catch(error => {
                      console.log(error)
                  })
            },   
            async GetFilials(inn) {
                  await axios
                  .get(`/api/v1/ooo/filials/${inn}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_filials = response.data.body
                        this.total_filials = response.data.body.length
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetDolgi(uid) {
                  await axios
                  .get(`/api/v1/ooo/dolgi/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_dolgi = response.data.body.docs
                        this.total_dolgi = response.data.body.total
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            }, 
            async GetIzmenenyia(uid) {
                  await axios
                  .get(`/api/v1/ooo/izmeneniya/${uid}`)
                  .then(response => {
                        this.items_izmeneniya = response.data
                        console.log(this.items_izmeneniya, response.data)
                  })
                  .catch(error => {
                      console.log(error)
                  })             
            },     
            async GetProverki(uid) {
                  await axios
                  .get(`/api/v1/ooo/proverki/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_proverki = response.data.body.docs
                        this.total_proverki = response.data.body.total
                        
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetLicenses(uid) {
                  await axios
                  .get(`/api/v1/ooo/licenses/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_licenses = response.data.items
                        this.total_licenses = response.data.total
                        
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            CheckRating(value1, value2) {
                if (value1 == 'высокий' && value2 == 'низкий') {
                    this.zvezdi = 5
                }

                if (value1 == 'средний' && value2 == 'средний') {
                    this.zvezdi = 3
                }
            },
        },
        watch: {

        },
        created() {
            this.project_id = this.$route.query.project_id

            if (this.project_id) {
                this.GetInfoCard(this.project_id)
                this.GetSvyazi(this.project_id)
                this.GetLicenses(this.project_id)
                this.GetListingArbitraj(this.project_id)
                this.GetProverki(this.project_id)
                this.GetIzmenenyia(this.project_id)
                this.GetRating(this.project_id)
                this.GetNadejnost(this.project_id)
                this.GetDolgi(this.project_id)

                setTimeout(() => {
                    if (this.card_info) {

                        if (this.card_info.ИНН) {
                            this.GetFilials(this.card_info.ИНН)
                            this.GetParticipant(this.card_info.ИНН)
                            this.GetCustomer(this.card_info.ИНН)
                        }
                    }
                }, 550)
            }
        }
   }
</script>