<template>
  <router-view/>
</template>

<style>
.notification {
  padding: 30px;
  border-radius: 8px;
  border: none;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.notification.is-success {
  background: #00D45F;
  color: #fff !important;
}

.notification button.delete {
  opacity: 0;
  width: 0px;
  height: 0px;
  margin-left: -10px;
}
</style>

<style lang="scss">
@import 'assets/css/reset.css';
@import 'assets/css/style.css';
</style>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {

      }
    },
    components: {

    },
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    },
    mounted() {
      document.title = "Datafix"
    },
    created() {

    },  
    computed: {

    }
  }
</script>