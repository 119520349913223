<template>
    <div class="inner-page">
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="reliab">
            <div class="reliab__title">
                <h1>Лента изменений</h1>
            </div>
            <ul class="reliab__tab" v-if="total != 0">
                <li 
                    @click="Tag(0)"
                    :class="{'active': tag == 0}"
                >   
                    Все ({{ total }})
                </li>
                <li 
                    v-if="address.length != 0" 
                    @click="Tag(1)"
                    :class="{'active': tag == 1}"
                >
                    Адреса ({{ address.length }})
                </li>
                <li 
                    v-if="ceo.length != 0"
                    @click="Tag(2)"
                    :class="{'active': tag == 2}"                    
                >
                    Руководители ({{ ceo.length }})
                </li>
                <li 
                    v-if="founder.length != 0"
                    @click="Tag(3)"
                    :class="{'active': tag == 3}" 
                >   
                    Учредители ({{ founder.length }})
                </li>
            </ul>
            <div v-else>
                <p>Данных нет</p>
            </div>
            <div class="reliab__wrapper">
                <div class="reliab__block" v-show="address.length != 0" v-if="tag == 1 || tag == 0">
                    <h3>Адреса</h3>
                    <div class="reliab__grid">
                        <div class="reliab__item" v-for="list in address">
                            <div class="reliab__name">
                                <div class="">
                                    <p class="bold">{{ list.attribute[0][`${Object.keys(list.attribute[0])}`] }}</p>
                                </div>
                            </div>
                            <div class="reliab__info">
                                <p>{{ list.date }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reliab__block" v-show="ceo.length != 0" v-if="tag == 2 || tag == 0">
                    <h3>Руководители</h3>
                    <div class="reliab__grid">
                        <div class="reliab__item" v-for="list in ceo">
                            <div class="reliab__name">
                                <div class="">
                                    <p class="bold">{{ list.attribute[0].ФИО }}</p>
                                </div>
                                <p>Должность: {{ list.attribute[0].НаимДолжн }}</p>
                                <p>ИНН: {{ list.attribute[0].ИНН }}</p>
                            </div>
                            <div class="reliab__info">
                                <p>{{ list.date }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reliab__block" v-show="founder.length != 0" v-if="tag == 3 || tag == 0">
                    <h3>Учредители</h3>
                    <div class="reliab__grid">
                        <div class="reliab__item" v-for="list in founder">
                            <div class="reliab__name">
                                <div class="" v-if="list.attribute[0].Наименование">
                                    <p class="bold">{{ list.attribute[0].Наименование }}</p>
                                </div>
                                <div class="" v-if="list.attribute[0].ФИО">
                                    <p class="bold">{{ list.attribute[0].ФИО }}</p>
                                </div>                                
                                <p>Доля: {{ list.attribute[0].ДоляРуб }}₽</p>
                                <p>ИНН: {{ list.attribute[0].ИНН }}</p>
                            </div>
                            <div class="reliab__info">
                                <p>{{ list.date }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "LentaIzmeneniy",
        directives: { mask },
        props: {
            card_info: Object,
            items_izmeneniya: Object,
            // total_izmeneniya: Number,
        },
        components: {

        },
        data() {
            return {
                address: [],
                ceo: [],
                founder: [],

                total: 0,

                tag: 0,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            if (this.items_izmeneniya.address) {
                this.address = this.items_izmeneniya.address
            }
            
            if (this.items_izmeneniya.ceo) {
                this.ceo = this.items_izmeneniya.ceo
            }

            if (this.items_izmeneniya.founder) {
                this.founder = this.items_izmeneniya.founder
            }

            this.total = this.address.length + this.ceo.length + this.founder.length
        },
        methods: {
            Tag(value) {
                this.tag = value
            }
        },
        created() {

        },
        watch: {

        },        
   }
</script>