<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__egrip ceo-title__director">
        <div class="container">
            <h1>Руководитель</h1>
            <p>На руководителе лежит ответственность за все действия организации, о чем свидетельствует внесение данных о нем в ЕГРЮЛ.</p>
            <img src="@/assets/img/director.png" alt="">
        </div>
    </section>
    <section class="affril-list affril-list__director">
        <div class="container">
            <div class="affril-list__name">
                <h2>Какие сведения о руководителе будут полезны</h2>
            </div>
            <div class="">
                <p class="affril-list__director_name">Краткие открытые сведения о лице, руководящем компанией, содержатся в выписке из ЕГРЮЛ:</p>
                <ul>
                    <li>ФИО</li>
                    <li>Должность</li>
                    <li>ИНН</li>
                </ul>
                <p class="affril-list__director_name">Воспользовавшись сервисом, вы можете получить расширенную информацию о руководителе:</p>
                <ul>
                    <li>Аффилированность — присутствие лица в других компаниях в качестве руководителя и учредителя (владельца). Этот поиск особенно полезен при проверке на т. н. «массовость директора»</li>
                    <li>Исторические связи</li>
                    <li>Наличие/отсутствие руководителя в реестре дисквалифицированных лиц</li>
                    <li>Наличие/отсутствие открытых исполнительных производств в отношении руководителя</li>
                </ul>
            </div>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <h2>Зачем проверять руководителя</h2>
            <p>Директор является главным лицом компании, определяющим экономическую и финансовую политику предприятия.</p>
            <p>В рамках организации он имеет решающее слово при заключении договоров и становится истиной в последней инстанции при разрешении несудебных споров с контрагентом. Именно с руководителем можно прийти к консенсусу при решении сложных вопросов.</p>
        </div>
    </section>
    <div class="ceo-error">
        <div class="container">
            <div class="ceo-error__wrapper">
                <p>Если руководитель компании занесен в реестр дисквалифицированных лиц, подписанные им документы будут считаться ничтожными. Присутствие его в качестве директора в нескольких организациях говорит о возможной неблагонадежности.</p>
            </div>
        </div>
    </div>
    <section class="ceo-prev ceo-prev__director">
        <div class="container">
            <div class="ceo-prev__info">
                <p>Согласно статьи 23 ФЗ-129, руководитель организации, по которой имеется запись о недостоверности сведений в ЕГРЮЛ, в течение 3 лет после внесения таковой не может стать руководителем или учредителем другого предприятия.</p>
                <p>Воспользоваться поиском информации будет полезно и тем, кто намерен проверить, не записан ли он в качестве директора какой-либо компании без своего ведома, ведь именно таким образом поступают мошенники для регистрации фирм-однодневок. </p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <img src="@/assets/img/ceo-info_director.jpg" alt="">
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Director',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
