<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__procur">
        <div class="container">
            <h1>Закупки (тендеры)</h1>
            <p>Тендер — это открытые или закрытые конкурентные торги, в процессе которых все участники проходят предварительную проверку. Информация о торгах, в которых участвовал ваш потенциальный партнер, покажет его активность и готовность к честному соревнованию.</p>
        </div>
    </section>
    <div class="ceo-suc">
        <div class="container">
            <div class="ceo-suc__wrapper">
                <p>Анализ тендеров дает возможность объективной оценки масштабов деятельности, сумм, которыми оперирует контрагент и структуры предоставляемых товаров/услуг. Если контрагент принимал участие в соревнованиях на поставку удобрений, электроники, пищевых продуктов и предметов гигиены, а вам предлагает закупить у него мебель собственного изготовления - скорее всего он посредник, специализирующийся лишь на перепродаже. </p>
            </div>
        </div>
    </div>
    <div class="ceo-procur">
        <div class="container">
            <img src="@/assets/img/procur.png" alt="">
            <p>Участие в тендерах по госзакупкам говорит о благонадежности партнера, поскольку государство досконально проверяет каждого участника. Победа в госзакупках — это большое достижение, которое характеризует фирму с положительной стороны.</p>
        </div>
    </div>
    <section class="ceo-error">
        <div class="container">
            <div class="ceo-error__wrapper">
                <p>Более тщательный анализ может рассказать о скрытой взаимосвязи компаний. Группа одних и тех же фирм, участвующих в разных торгах может формироваться специально для создания конкурентных преимуществ определенной компании. Это частое явление, являющееся следствием предварительного сговора. </p>
            </div>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Еще один тонкий момент. Предположим, вы уже проанализировали бухгалтерскую отчетность компании и составили впечатление о ее возможностях по объемам поставок.</p>
            <p>Одновременно выясняется, что фирма подала заявку на участие в тендере, и суммарный объем поставки вам и тендерных обязательств значительно выше фактических возможностей компании. Вывод — поставщик будет не в состоянии выполнить одновременно условия двух сделок. Здесь стоит задуматься и получить объяснения от контрагента.</p>
        </div>
    </section>
    <div class="ceo-info ceo-info__procur ceo-info__wht">
        <div class="container">
            <div class="ceo-info__text">
                <p>Для формирования отчета по тендерам сервис использует базы данных тендерных площадок и портала госзакупок.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <div class="ceo-info__img">
                <img src="@/assets/img/ceo-info_procur.svg" alt="">
            </div>
        </div>
    </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Procurement',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
