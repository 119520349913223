<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__phone">
        <div class="container">
            <h1>Телефоны организаций РФ</h1>
            <p>На начало 2022 года в России зарегистрировано 3 274 285 юридических лиц. Из них 2 670 751 коммерческих организаций и 603 574 некоммерческих. </p>
            <img src="@/assets/img/phone.png" alt="">
        </div>
    </section>
    <div class="ceo-suc">
        <div class="container">
            <div class="ceo-suc__wrapper">
                <p>Используя наш сервис, вы сможете не только узнать действующие телефоны интересующего вас предприятия, но и сформировать базу данных компаний и некоммерческих организаций, которая будет полезна для проведения статистических и маркетинговых исследований, телефонных опросов, холодных и горячих звонков.</p>
            </div>
        </div>
    </div>
    <section class="ceo-list ceo-list__change">
        <div class="container">
            <h2>Формирование базы</h2>
            <p>Телефонную базу можно сформировать по 40 параметрам:</p>
            <ul>
                <li>
                    <p>Регион</p>
                </li>
                <li>
                    <p>Виды деятельности</p>
                </li>
                <li>
                    <p>Статус</p>
                </li>
                <li>
                    <p>Адрес</p>
                </li>
                <li>
                    <p>Финансовые показатели</p>
                </li>
                <li>
                    <p>Численность сотрудников</p>
                </li>
                <li>
                    <p>Список ИНН</p>
                </li>
                <li>
                    <p>Прочие</p>
                </li>
            </ul>
        </div>
    </section>
    <div class="ceo-phone">
        <div class="container">
            <div class="ceo-phone__wrapper">
                <h2>Как получить базу данных</h2>
                <p>Для удобства пользователей предусмотрены алгоритмы формирования телефонной базы:</p>
                <ul>
                    <li>Разовая выгрузка. Сформировать базу данных, оплатить и скачать.</li>
                    <li>Выгрузка по тарифу. Оплатить тариф, формировать и выгружать в любое время, пока тариф действует.</li>
                </ul>
            </div>
        </div>
    </div>
    <section class="ceo-prev ceo-prev__director">
        <div class="container">
            <div class="ceo-prev__info">
                <p>Предоставляя информацию о телефонах организаций и прочих открытых данных, сервис не нарушает законодательство РФ.</p>
                <p>Федеральные законы № 128-ФЗ от 08.08.2001 и № 149-ФЗ от 27.07.2006 разъясняют, что эта деятельность является свободной и не подлежит лицензированию и сертификации.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <img src="@/assets/img/ceo-info_director.jpg" alt="">
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Phone',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
