<template>
	<HeaderProfile />
    <section class="acc">
        <div class="container">
        	<SideProfile />
            <div class="acc__container" v-if="!loader">
                <section class="req" v-if="history.length != 0">
                    <div class="req__title">
                        <h1>История запросов ({{ total }})</h1>
                        <div class="req__vol" v-if="!not_sub">
                            <p v-if="sub.sub != 3">{{ sub.request_num }}/{{ sub.request_num_all }}</p>
                            <p v-else>∞/∞</p>
                            <p>запросов</p>
                        </div>
                    </div>
                    <div class="req__container">
                        <div class="req__block">
<!--                             <p class="req__name">сегодня</p> -->
                            <div class="req__wrapper">
                                <div class="req__item pointer" v-for="list in history" @click="GoQuery(list.project_id, list.type_project)">
                                    <div class="req__info">
                                        <h3>{{ list.title }}</h3>
                                        <div class="req__row">
                                            <div v-if="list.inn">
                                                <p class="reg">ИНН</p>
                                                <p class="bold_s">{{ list.inn }}</p>
                                            </div>
                                            <div v-if="list.kpp">
                                                <p class="reg">КПП</p>
                                                <p class="bold_s">{{ list.kpp }}</p>
                                            </div>
                                            <div v-if="list.innfl">
                                                <p class="reg">ИННФЛ</p>
                                                <p class="bold_s">{{ list.innfl }}</p>                                 
                                            </div>
                                        </div>
                                        <p class="info__block">{{ list.status }}</p>
                                    </div>
<!--                                     <div class="req__link">
                                        <a >
                                            <img src="@/assets/icons/main-lcc_link_3.svg" alt="">
                                            
                                        </a>
                                        <a v-else>
                                            <img src="@/assets/icons/fav_active.svg" alt="">
                                        </a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
<!--                         <div class="req__block">
                            <p class="req__name">23.07.2022</p>
                            <div class="req__wrapper">
                                <div class="req__item">
                                    <div class="req__info">
                                        <h3>ИП Александровский Александр  Викторович</h3>
                                        <div class="req__row">
                                            <div class="">
                                                <p class="reg">ИНН</p>
                                                <p class="bold_s">290437415708</p>
                                            </div>
                                            <div class="">
                                                <p class="reg">ОГРНИП</p>
                                                <p class="bold_s">319290100031397</p>
                                            </div>
                                        </div>
                                        <p class="info__block">Действует</p>
                                    </div>
                                    <div class="req__link">
                                        <a href="#">
                                            <img src="@/assets/icons/downloud.svg" alt="">
                                        </a>
                                        <a href="#">
                                            <img src="@/assets/icons/fav.svg" alt="">
                                        </a>
                                    </div>
                                </div>
                                <div class="req__item">
                                    <div class="req__info">
                                        <h3>ИП Александровский Александр  Викторович</h3>
                                        <div class="req__row">
                                            <div class="">
                                                <p class="reg">ИНН</p>
                                                <p class="bold_s">290437415708</p>
                                            </div>
                                            <div class="">
                                                <p class="reg">ОГРНИП</p>
                                                <p class="bold_s">319290100031397</p>
                                            </div>
                                        </div>
                                        <p class="info__block">Действует</p>
                                    </div>
                                    <div class="req__link">
                                        <a href="#">
                                            <img src="@/assets/icons/downloud.svg" alt="">
                                        </a>
                                        <a href="#">
                                            <img src="@/assets/icons/fav.svg" alt="">
                                        </a>
                                    </div>
                                </div>
                                <div class="req__item">
                                    <div class="req__info">
                                        <h3>ИП Александровский Александр  Викторович</h3>
                                        <div class="req__row">
                                            <div class="">
                                                <p class="reg">ИНН</p>
                                                <p class="bold_s">290437415708</p>
                                            </div>
                                            <div class="">
                                                <p class="reg">ОГРНИП</p>
                                                <p class="bold_s">319290100031397</p>
                                            </div>
                                        </div>
                                        <p class="info__block">Действует</p>
                                    </div>
                                    <div class="req__link">
                                        <a href="#">
                                            <img src="@/assets/icons/downloud.svg" alt="">
                                        </a>
                                        <a href="#">
                                            <img src="@/assets/icons/fav.svg" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <button v-show="next != null" @click="GetHistoryInfoPagination()" class="btn__gray">Показать ещё</button>
                    </div>
                </section>
                <section v-else class="req favorite-clear">
                    <h1 style="margin-bottom: 10px;">Вы ещё не сделали ни одного запроса</h1>
                    <h6 style="margin-bottom: 20px;">Чтобы начать проверку контрагента, воспользуйтесь <br> поисковой строкой</h6>
                    <img src="@/assets/img/history__zero.svg">                    
                </section>
            </div>
            <section class="req favorite-clear" v-else>
                <div class="loader__block">
                    <div class="loader-wait">
                        <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
                    </div>         
                </div>                   
            </section>            
        </div>
    </section>
	<Footer />	
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    import HeaderProfile from '@/components/Headers/HeaderProfile.vue'
    import SideProfile from '@/components/SideMenu/SideProfile.vue'
    import Footer from '@/components/Footer/Footer.vue'

	export default {
		name: "HistoryProfile",
        components: {
            HeaderProfile,
            SideProfile,
            Footer
        },
        data() {
            return {
                history: [],
                user: {},

                sub: {},

                total: 0,
                next: null,
                loader: true,

                not_sub: true,

                next_page_pagination: "",
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            this.GetHistoryInfo()
        },
        methods: {
            GoQuery(object_id, object_type) {
                if (object_type == 'ul') {
                    this.$router.push({path: '/ooo', query: { project_id: object_id }})
                } else {
                    this.$router.push({path: '/ip', query: { project_id: object_id }})
                }
            },
            async GetMeInfo() {
                await axios
                    .get('/api/v1/me/')
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetHistoryInfo() {
                var next_page = ""

                await axios
                    .get('/api/v1/history/')
                    .then(response => {
                        setTimeout(() => {
                            this.loader = false
                        }, 500)
                        this.history = response.data.results
                        this.total = response.data.count
                        this.next = response.data.next

                        if (this.next != null) {
                            next_page = this.next.split("/")
                            this.next_page_pagination = next_page[6]
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetHistoryInfoPagination() {
                var next_page = ""

                await axios
                    .get(`/api/v1/history/${this.next_page_pagination}`)
                    .then(response => {
                        this.next = response.data.next

                        if (this.next != null) {
                            next_page = this.next.split("/")
                            this.next_page_pagination = next_page[6]
                        }

                        for (var i = 0; i < response.data.results.length; i++) {
                            const item = response.data.results[i]
                            this.history.push(item)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },            
            async CheckFavorite(value) {
                var status = "error"

                await axios
                .get(`/api/v1/favorite/check/${value}/`)
                .then(response => {
                    status = response.data.status
                })
                .catch(error => {
                  console.log(error)
                })

                return status
            },
            async GetSub() {
                await axios
                    .get(`/api/v1/sub/`)
                    .then(response => {
                        if (response.data.length != 0) {
                            this.sub = response.data[response.data.length - 1]
                            this.not_sub = false
                        } else {
                            this.not_sub = true
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
        created() {
            this.GetMeInfo()
            this.GetSub()
        },
        watch: {

        },     
	}
</script>