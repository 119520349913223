<template>
    <div class="inner-page">
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="requisit" v-if="card_info.НаимЮЛСокр">
            <h1>Реквизиты</h1>
            <div class="requisit__wrapper">
                <div class="requisit__item">
                    <h3>Основные</h3>
                    <div class="requisit__table">
                        <div class="requisit__row">
                            <p>ОГРН</p>
                            <p>{{ card_info.ОГРН }}</p>
                            <a @click="copy(card_info.ОГРН)">Копировать</a>
                        </div>
                        <div class="requisit__row">
                            <p>ИНН</p>
                            <p>{{ card_info.ИНН }}</p>
                            <a @click="copy(card_info.ИНН )">Копировать</a>
                        </div>
                        <div class="requisit__row">
                            <p>КПП</p>
                            <p>{{ card_info.КПП }}</p>
                            <a @click="copy(card_info.КПП)">Копировать</a>
                        </div>
                        <div class="requisit__row">
                            <p>Дата постановки на учёт</p>
                            <p>{{ card_info.ДатаПостУч }}</p>
                            <a @click="copy(card_info.ДатаПостУч)">Копировать</a>
                        </div>
                        <div class="requisit__row">
                            <p>Налоговый орган</p>
                            <p>{{ card_info.НаимНО }}</p>
                            <a @click="copy(card_info.НаимНО)">Копировать</a>
                        </div>
                    </div>
                </div>
                <div class="requisit__item">
                    <h3>Сведения Росстата</h3>
                    <div class="requisit__table">
                        <div class="requisit__row" v-if="card_info.ОКПО">
                            <p>ОКПО</p>
                            <p>{{ card_info.ОКПО }}</p>
                            <a @click="copy(card_info.ОКПО)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ОКАТО">
                            <p>ОКАТО</p>
                            <p>{{ card_info.ОКАТО }}</p>
                            <a @click="copy(card_info.ОКАТО)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ОКТМО">
                            <p>ОКТМО</p>
                            <p>{{ card_info.ОКТМО }}</p>
                            <a @click="copy(card_info.ОКТМО)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ОКФС">
                            <p>ОКФС</p>
                            <p>{{ card_info.ОКФС }}</p>
                            <a @click="copy(card_info.ОКФС)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ОКОГУ">
                            <p>ОКОГУ</p>
                            <p>{{ card_info.ОКОГУ }}</p>
                            <a @click="copy(card_info.ОКОГУ)">Копировать</a>
                        </div>
                    </div>
                </div>
                <div class="requisit__item">
                    <h3>Сведения о регистрации в ФНС</h3>
                    <div class="requisit__table">
                        <div class="requisit__row" v-if="card_info.ОГРН">
                            <p>ОГРН</p>
                            <p>{{ card_info.ОГРН }}</p>
                            <a @click="copy(card_info.ОГРН)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ДатаОГРН">
                            <p>Дата присвоения ОГРН</p>
                            <p>{{ card_info.ДатаОГРН }}</p>
                            <a @click="copy(card_info.ДатаОГРН)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ДержОГРН">
                            <p>Реестр держателя ОГРН</p>
                            <p>{{ card_info.ДержОГРН }}</p>
                            <a @click="copy(card_info.ДержОГРН)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ДержНаимЮЛПолн">
                            <p>Наименование юридического лица держателя реестра</p>
                            <p>{{ card_info.ДержНаимЮЛПолн }}</p>
                            <a @click="copy(card_info.ДержНаимЮЛПолн)">Копировать</a>
                        </div>
                    </div>
                </div>
                <div class="requisit__item">
                    <h3>Внебюджетные фонды</h3>
                    <p class="requisit__subtitle">Сведения о регистрации в ПФР</p>
                    <div class="requisit__table">
                        <div class="requisit__row" v-if="card_info.РегНомПФ">
                            <p>Регистрационный номер</p>
                            <p>{{ card_info.РегНомПФ }}</p>
                            <a @click="copy(card_info.РегНомПФ)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ДатаРегПФ">
                            <p>Дата регистрации</p>
                            <p>{{ card_info.ДатаРегПФ }}</p>
                            <a @click="copy(card_info.ДатаРегПФ)">Копировать</a>
                        </div>
                    </div>
                    <p class="requisit__subtitle">Сведения о регистрации в ФСС</p>
                    <div class="requisit__table">
                        <div class="requisit__row" v-if="card_info.РегНомФСС">
                            <p>Регистрационный номер</p>
                            <p>{{ card_info.РегНомФСС }}</p>
                            <a @click="copy(card_info.РегНомФСС)">Копировать</a>
                        </div>
                        <div class="requisit__row" v-if="card_info.ДатаРегФСС">
                            <p>Дата регистрации</p>
                            <p>{{ card_info.ДатаРегФСС }}</p>
                            <a @click="copy(card_info.ДатаРегФСС)">Копировать</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="requisit">
            <h1>Реквизиты</h1>
            <p>Данных нет</p>
        </div>     
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Rekviziti",
        directives: { mask },
        props: {
            card_info: Object,
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            copy(value) {
              try {
                navigator.clipboard.writeText(value)
              } catch (e) {
                throw e
              }
            },            
        },
        created() {

        },
        watch: {

        },        
   }
</script>