<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__egrip">
        <div class="container">
            <h1>ЕГРЮЛ</h1>
            <p>В Едином Государственном Реестре Юридических Лиц собрана актуальная и достоверная информация, подтвержденная документально. Все записи из реестра, кроме персональной информации (персональная информация о директоре, учредителях/участниках компании) являются открытыми.</p>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Ведением реестра занимаются территориальные налоговые органы, в которые подается  заявление о регистрации компании.  Регистрация означает запись в ЕГРЮЛ.</p>
            <p>Юридическое лицо несет ответственность за достоверную информацию, предоставленную в ФНС для занесения в реестр.</p>
        </div>
    </section>
    <div class="ceo-error">
        <div class="container">
            <div class="ceo-error__wrapper">
                <p>Если меняются любые данные, занесенные в ЕГРЮЛ и отражаемые в выписке, компания подает заявление в ФНС о необходимости внесения изменений в реестр.</p>
            </div>
        </div>
    </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Usrle',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
