<template>
    <div class="inner-page">
        <div class="inner-page__title" v-if="card_ip">
            <h3>ИП {{ card_info.ФИО }}</h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Статус }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРНИП }}</p>
                </div>
            </div>
        </div>
        <div class="inner-page__title" v-else>
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="check">
            <h1>Проверки ({{ total_proverki }})</h1>
<!--             <div class="change__form">
                <p>Выбрать дату</p>
                <div class="change__input">
                    <input type="text">
                </div>
                <div class="change__input">
                    <input type="text">
                </div>
            </div> -->
            <div class="check__wrapper" v-if="items_proverki.length != 0">
                <div class="check__item check__item_active" v-for="list in items_proverki">
                    <div class="check__prev">
                        <p>{{ list["@attributes"].START_DATE }}</p>
                        <div class="check__yellow" :class="{'check__green': list['@attributes'].STATUS == 'Завершена'}">{{ list["@attributes"].STATUS }}</div>
                    </div>
                    <ul>
                        <li class="check__row">
                            <p v-if='list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].START_DATE'> Период проведения: <span>с {{ list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].START_DATE }} по {{ list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].END_DATE }} </span></p>
                            <p>Продолжительность: 
                                <span v-if='list.I_APPROVE["@attributes"].DURATION_HOURS'>
                                    {{ list.I_APPROVE["@attributes"].DURATION_HOURS }} час.
                                </span>
                                <span v-if='list.I_APPROVE["@attributes"].DURATION_DAY'>
                                    {{ list.I_APPROVE["@attributes"].DURATION_DAY }} дн.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>Цель:<span>{{ list.I_APPROVE["@attributes"].INSP_TARGET }}</span></p>
                        </li>
                        <li>
                            <p>Орган контроля:<span>{{ list.I_AUTHORITY["@attributes"].FRGU_ORG_NAME }}</span></p>
                        </li>
                        <li>
                            <p>Тип:<span>{{ list["@attributes"].ITYPE_NAME }}</span></p>
                        </li>
                        <li v-if='list.I_OBJECT[0]["@attributes"].ADDRESS'>
                            <p>Адрес:<span>{{ list.I_OBJECT[0]["@attributes"].ADDRESS }}</span></p>
                        </li>
                    </ul>
<!--                     <a href="#">Свернуть</a> -->
                </div>
            </div>
            <div v-else class="none__databody">
                <h4>Нет данных</h4>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Proverki",
        directives: { mask },
        props: {
            card_info: Object,
            items_proverki: {
                type: Array,
                default: [],
            },
            total_proverki: {
                type: Number,
                default: 0,
            },
            card_ip: {
                type: Boolean,
                default: false,
            },
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {

        },
        created() {

        },
        watch: {

        },        
   }
</script>