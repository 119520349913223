<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__egrip">
        <div class="container">
            <h1>ОГРН</h1>
            <p>Каждая организация, зарегистрированная в едином государственном реестре, имеет ОГРН — основной государственный регистрационный номер. Этот ряд из 13 цифр отдаленно напоминает штрих-код, но не имеет с ним ничего общего.</p>
        </div>
    </section>
    <section class="affril-list affril-list__activities affril-list__pt">
        <div class="container">
            <div class="affril-list__name">
                <h2>О чем расскажет ОГРН</h2>
                <p>В последовательности знаков зашифрована важная информация о контрагенте:</p>
            </div>
            <ul>
                <li>Первая цифра говорит о том, кто получил этот ОГРН — юрлицо (1 или 5), государственная организация (2, 4, 6, 8 или 9), ИП (3).</li>
                <li>Второй и третий знаки отражают последние 2 цифры года регистрации. Так для 2021 года это будет 21.</li>
                <li>4 и 5-й знаки — номер региона. Для Санкт-Петербурга они равны 78.</li>
                <li>С 6 по 12-й знак отражают номер записи, внесенной в ЕГРЮЛ в течение года.</li>
                <li>Последняя цифра - контрольная.</li>
                <p class="affril-list__activities_more">При возникновении сомнений относительно достоверности сведений, которые дал вам контрагент, ОГРН можно проверить арифметическими действиями.</p>
            </ul>
        </div>
    </section>
    <section class="affril-list affril-list__ogrn affril-list__pt">
        <div class="container">
            <div class="affril-list__name">
                <h2>Как проверить корректность ОГРН организации</h2>
                <p>Рассмотрим последовательность контрольных действий на примере. <br>
                    ОГРН = 1 027 700 132 195. <br>
                    1 — юридическое лицо. <br>
                    02 — 2002 год. <br>
                    77 — Московский регион.</p>
            </div>
            <ul>
                <li>Убираем контрольную цифру и получаем 102 770 013 219. Назовем это число А.</li>
                <li>Полученное число А делим на 11 (это константа для юридических лиц). </li>
                <li>Если получилась дробь — отбрасываем все, что после запятой, а оставшееся целое число умножаем на константу 11. У нас получится 102 770 013 214. Назовем этот ответ В.</li>
                <li>Далее надо подсчитать разность С = А - В. 102770013219 - 102770013214= 5.</li>
                <li>Сравниваем полученный результат (С) с последней (контрольной) цифрой из ОГРН. Если совпало — ОГРН корректен. В вашем случае С может оказаться больше 9. Из такого числа нас интересует только вторая цифра.</li>
                <p class="affril-list__ogrn_more">Официально узнать ОГРН можно по ИНН или названию организации. </p>
            </ul>
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Ogrn',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
