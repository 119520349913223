 <template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__reporting">
        <div class="container">
            <h1>Бухгалтерская (финансовая) отчетность компаний РФ</h1>
            <p>Годовые показатели из бухгалтерии говорят о многом. Они подскажут, стоит ли вступать в денежные отношения с новой для вас фирмой, и в каком масштабе эти отношения будут иметь наименьший риск.</p>
            <img src="@/assets/img/reporting.png" alt="" class="ceo-title__reporting_bg">
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Согласно приказу Министерства Финансов РФ №34 от 29 июля 1998 года, бухгалтерская отчетность за год должна быть открытой для взаимодействующих сторон, и вы имеете право запросить ее напрямую у потенциального партнера, на что понадобится время</p>
            <p>Если контрагент отказывает в предоставлении информации, ее можно быстро сформировать на нашем сервисе. Кроме того, подобный запрос не всегда способствует доверительным отношениям, и эти данные лучше получить инкогнито:</p>
        </div>
    </section>
    <section class="ceo-list">
        <div class="container">
            <h2>Сервис предоставляет данные по арбитражу за все время существования компании:</h2>
            <ul>
                <li>
                    <p>Активы, пассивы (обязательства) и собственный капитал</p>
                </li>
                <li>
                    <p>Финансовые результаты</p>
                </li>
                <li>
                    <p>Капитал компании - уставной и дополнительный</p>
                </li>
                <li>
                    <p>Движение денежных средств</p>
                </li>
                <li>
                    <p>Целевое использование средств</p>
                </li>
            </ul>
        </div>
    </section>
    <div class="ceo-info ceo-info__report">
        <div class="container">
            <div class="ceo-info__text">
                <p>По отчетам будет видно, какой объем задолженности есть у контрагента и сделать вывод, насколько вероятно вы окажетесь в списке его кредиторов. Бухгалтерская отчетность будет предоставлена на основании данных Росстата РФ и Федеральной налоговой службы.</p>
                <p>Сервис предоставляет обзорную информацию о состоянии отрасли в целом и то положение, которое в ней занимает потенциальный партнер.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <div class="ceo-info__img">
                <img src="@/assets/img/ceo-info_reporting.svg" alt="">
            </div>
        </div>
    </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Reporting',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
