<template>
    <div class="inner-page">
        <div class="inner-page__title" v-if="card_info.НаимЮЛСокр">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="crash" v-if="card_info.ТоварЗнак">
            <h1>Товарные знаки ({{ card_info.ТоварЗнак.length }})</h1>
            <div class="crash__table">
                <div class="tovarnie_znaki_row crash__title">
                    <div>
                        <p>Рег.номер</p>
                    </div>
                    <div>
                        <p>Номер заявки</p>
                    </div>
                    <div>
                        <p>Дата гос.регистрации</p>
                    </div>
                    <div>
                        <p>Дата истечения срока действия</p>
                    </div>
                </div>
                <div class="tovarnie_znaki_row" v-for="item in card_info.ТоварЗнак">
                    <div>
                        <p>{{ item.РегНомер }}</p>
                    </div>
                    <div>
                        <p>{{ item.НомЗаявки }}</p>
                    </div>
                    <div>
                        <p>{{ item.ДатаРег }}</p>
                    </div>
                    <div>
                        <p>{{ item.ДатаОконч }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="crash" v-else>
            <h1>Товарные знаки</h1>
            <p>Данных нет</p>
        </div>
    </div>
</template>

<style scoped>
.tovarnie_znaki_row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #E7E7ED;
}

.tovarnie_znaki_row p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #272833;
}

.tovarnie_znaki_row div {
    padding: 12px;
}
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "TovarnieZnaki",
        directives: { mask },
        props: {
            card_info: Object,
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            dateUpdate(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY')
              return datasort
            },  
        },
        created() {

        },
        watch: {

        },        
   }
</script>