<template>
    <HeaderProfile />
    <section class="acc">
        <div class="container">
            <SideProfile />
            <div class="acc__container">
                <section class="feedback">
                    <div class="feedback__container">
                        <div class="feedback__wrapper">
                            <h1>Обратная связь</h1>
                            <p>Если у вас есть вопрос, предложение или любая другая обратная связь, напишите нам</p>
                            <form action="#" class="feedback__form">
                                <input v-model="user.fio" type="text" placeholder="ФИО">
                                <input v-model="user.email" type="text" placeholder="Email">
                                <textarea v-model="message" placeholder="Текст обращения" maxlength="200"></textarea>
                                <button class="btn__green pointer" type="button" @click="SendFeedback()">Отправить</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
    <Footer />   

    <div class="modal" :class="{'modal__active': modal_send}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <img src="@/assets/icons/succes.svg" alt="" class="modal__success">
            <h2>Ваше обращение успешно отправлено!</h2>
            <div class="modal__close pointer" @click="modal_send = false"></div>
        </div>
    </div>   
</template>

<script>
    import Footer from '@/components/Footer/Footer.vue'
    import HeaderProfile from '@/components/Headers/HeaderProfile.vue'
    import axios from 'axios'
    import SideProfile from '@/components/SideMenu/SideProfile.vue'

    export default {
        name: "MyProfile",
        components: {
            HeaderProfile,
            SideProfile,
            Footer
        },
        data() {
            return {
                user: {},
                message: "",

                modal_send: false,
            }
        },
        methods: {
            async GetMeInfo() {
                await axios
                    .get('/api/v1/me/')
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },        
            async SendEmail() {
                const FormData = {
                    fio: this.user.fio,
                    email: this.user.email,
                    description: this.message,
                }

                await axios
                    .post('/api/v1/send_help_user/', FormData)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    }) 
            },    
            async SendFeedback() {
                const FormData = {
                    name: this.user.fio,
                    email: this.user.email,
                    description: this.message,
                }

                await axios
                    .post('/api/v1/contact_post/', FormData)
                    .then(response => {
                        this.message = ""
                        this.modal_send = true
                        this.SendEmail()

                        setTimeout(() => {
                            this.modal_send = false
                        }, 3000)
                    })
                    .catch(error => {
                        console.log(error)
                    }) 
            },              
        },
        created() {
            this.GetMeInfo()
        }
    }
</script>