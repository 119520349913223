<template>
    <nav class="nav" :class="{'nav__active': mobile__active}">
        <div class="container">
            <img @click="GoTo('/')" src="@/assets/icons/logo.svg" alt="" class="nav__logo pointer">
            <form @submit.prevent="redirectToSearch()" ref="form" class="nav__search">
                <input v-model="query" type="text" placeholder="Введите ОГРН, ИНН, название, адрес или ФИО">
                <button type="submit"  class="search__button">
                    <span></span>
                </button>
            </form>
            <div class="nav__list">
                <p class="nav__list_btn" :class="{'nav__list_btn_active': mobile__active}"><a class="pointer" @click="ShowAll()">О сервисе</a></p>
                <ul v-show="mobile_block_open" :class="{'active': mobile__active, 'active__nav_ul': mobile__active}">
                    <li><router-link to="/egrul">ЕГРЮЛ</router-link></li>
                    <li><router-link to="/ogrnip">ОГРНИП</router-link></li>
                    <li><router-link to="/ogrn">ОГРН</router-link></li>
                    <li><router-link to="/extract">ЕГРИП/ЕГРЮЛ</router-link></li>
                    <li><router-link to="/egrip">ЕГРИП</router-link></li>
                    <li><router-link to="/reporting">Отчетность компаний</router-link></li>
                    <li><router-link to="/arbitration-court">Арбитраж</router-link></li>
                    <li><router-link to="/procurement">Закупки</router-link></li>
                    <li><router-link to="/activites">Виды деятельности</router-link></li>
                    <li><router-link to="/raiting">Рейтинг</router-link></li>
                    <li><router-link to="/director">Руководитель</router-link></li>
                    <li><router-link to="/founders">Учредители</router-link></li>
                    <li><router-link to="/affiliation">Аффилированность</router-link></li>
                    <li><router-link to="/legal_adress">Юридический адрес</router-link></li>
                </ul>
                <p><a class="pointer" @click="GoQuery('info')">Источники данных</a></p>    
                <p><a class="pointer" @click="GoQuery('subs')">Тарифы</a></p>
                <p><a class="pointer" @click="GoQuery('faq')">FAQ</a></p>
            </div>
            <router-link to="/my-profile" class="nav__acc pointer">Личный кабинет</router-link>
            <button class="btn__green pointer" @click="step = 0">Ввести промокод</button>
            <div class="nav__humburger" @click="mobile__active = !mobile__active">
                <span></span>
            </div>
        </div>
    </nav>	

    <div class="modal" :class="{'modal__active': step == 0}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Активация промокода</h2>
            <div class="modal__reg modal__reg_error">
                <input v-model="promo" type="text" placeholder="Промокод">
                <p v-if="error_promo.length != 0" v-show="error_promo">{{ error_promo[0] }}</p>
            </div>
            <button class="btn__green pointer" v-if="error_promo.length == 0 && prom.id" @click="ToPay()">Активировать</button>
            <button class="btn__green pointer" v-else disabled>Активировать</button>
            <div class="modal__close pointer" @click="step = -1"></div>
        </div>
    </div>      

    <div class="modal" :class="{'modal__active': none_sub_modal}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Оформите тариф</h2>
            <p>Для использования сервиса, вам необходимо выбрать тариф и оплатить его.</p>
            <button class="btn__green pointer" @click="ChooseTarif()">Выбрать тариф</button>
        </div>
    </div>

    <div class="modal" :class="{'modal__active': none_request_modal}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>У вас закончились запросы</h2>
            <button class="btn__green pointer" @click="none_request_modal = false">Готово</button>
        </div>
    </div>

    <ModalSub 
      :tarif__modal="tarif__modal" 
      :current_price="current_price"
      :current_tarif="current_tarif"
      :prom="promo"
      v-on:promo="promo = $event" 
      v-on:modal="tarif__modal = $event" 
      v-on:price="current_price = $event" 
    />

</template>

<style scoped>
    .search__button {
        display: block;
        right: 10px;
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        border: none;
        background: #00D45F;
        border-radius: 8px;
    }    

    .search__button span {
        display: block;
        width: auto;
        height: 20px;
        background: url('@/assets/img/search.svg') center no-repeat;
        background-size: contain;
    }
</style>

<script>
    import axios from 'axios'
    import ModalSub from '@/components/Subs/ModalSub.vue'
	export default {
		name: 'HeaderProfile',
        components: {
            ModalSub,
        },
		data() {
			return {
                tarif__modal: false,
                current_tarif: null,
                current_price: null,
                promo: "",
                query: "",
                prom: {},
                error_promo: [],

                sub: {},
                sub_none: true,

				step: -1,
				mobile__active: false,
                none_sub_modal: false,
                none_request_modal: false,
                mobile_block_open: false,
			}
		},
        methods: {
            GoTo(redirect) {
              this.$router.push(redirect)
            },
            GoQuery(value) {
              this.$router.push({path: '/', query: { redirect: value }})
            },
            redirectToSearch() {
                if (this.sub_none) {
                    this.none_sub_modal = true
                } else {
                    this.SubRequestUpdate(this.sub)
                }
            },
            async SubRequestUpdate(list) {
                if (list.sub != 3) {
                    if (Number(list.request_num) != 0) {
                        if (Number(list.request_num_all != 9999)) {
                            const NewData = {
                                request_num: Number(list.request_num) - 1
                            }

                            await axios
                            .put(`/api/v1/send/request/sub/${list.id}/`, NewData)
                            .then(response => {
                                this.$router.push({path: '/search', query: { query: this.query }})
                            })
                            .catch(error => {
                              console.log(error)
                            })
                        }
                    } else {
                        this.none_request_modal = true
                    }
                } else {
                    this.$router.push({path: '/search', query: { query: this.query }})
                }
            },
            async GetSub() {
                await axios
                    .get(`/api/v1/sub/`)
                    .then(response => {
                        if (response.data.length != 0) {
                            this.sub = response.data[response.data.length - 1]
                            this.sub_none = false
                        } else {
                            this.sub_none = true
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async CheckPromo() {
                  this.prom = {}

                  await axios
                  .get(`/api/v1/promocode/${this.promo}/`)
                  .then(response => {
                      this.prom = response.data
                      this.error_promo = []

                      if (this.prom) {
                        this.current_tarif = this.prom.sub

                        if (response.data.request_num == 0) {
                            this.error_promo.push('Срок действия промокода закончился.')
                        }

                        if (this.error_promo.length == 0) {
                            this.Price()
                            this.current_price = (parseInt(this.current_price) * (100 - this.prom.percent)) / 100;

                            if (Number(this.current_price) == 0) {
                                this.current_price = 1
                            }
                        }
                      }
                  })
                  .catch(error => {
                        this.error_promo.push('Промокода не существует.')
                  })
            },
            ToPay() {
                this.step = -1;
                this.tarif__modal = true;
            },
            Price() {
                if (this.current_tarif == 1) {
                    this.current_price = 350
                }

                if (this.current_tarif == 2) {
                    this.current_price = 9900
                }

                if (this.current_tarif == 3) {
                    this.current_price = 49900
                }
            },
            ChooseTarif() {
                this.none_sub_modal = false
                this.$router.push({path: '/', query: { redirect: 'subs' }})
            },
            ShowAll() {
                this.mobile_block_open = !this.mobile_block_open
            },
        },
        watch: {
            promo(value) {
                this.promo = value

                if (this.promo) {
                    this.CheckPromo()
                }
            },
        },
        created() {
            this.GetSub()
        },
	}
</script>