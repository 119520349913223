<template>
    <div class="acc__aside">
        <ul>
            <li :class="{'active': $route.path === '/my-profile'}">
                <router-link to="/my-profile">
                    <img src="@/assets/icons/user_active.svg" alt="">
                    <p>Профиль</p>
                </router-link>
            </li>
            <li :class="{'active': $route.path === '/history-profile'}">
                <router-link to="/history-profile">
                    <img src="@/assets/icons/clock.svg" alt="">
                    <p>История запросов</p>
                </router-link>
            </li>
            <li :class="{'active': $route.path === '/favorite-profile'}">
                <router-link to="/favorite-profile">
                    <img src="@/assets/icons/bookmark.svg" alt="">
                    <p>Избранное</p>
                </router-link>
            </li>
            <li :class="{'active': $route.path === '/feedback-profile'}">
                <router-link to="/feedback-profile">
                    <img src="@/assets/icons/Mail.svg" alt="">
                    <p>Обратная связь</p>
                </router-link>
            </li>
            <li class="acc__aside_exit">
                <a class="pointer" @click="logout()">
                    <img src="@/assets/icons/exit.svg" alt="">
                    <p>Выйти</p>
                </a>
            </li>
        </ul>
    </div>	

    <div class="mobile_aside_acc">
        <ul>
            <li :class="{'active': $route.path === '/my-profile'}">
                <router-link to="/my-profile">
                    <p>Профиль</p>
                </router-link>
            </li>
            <li :class="{'active': $route.path === '/history-profile'}">
                <router-link to="/history-profile">
                    <p>История запросов</p>
                </router-link>
            </li>
            <li :class="{'active': $route.path === '/favorite-profile'}">
                <router-link to="/favorite-profile">
                    <p>Избранное</p>
                </router-link>
            </li>
            <li :class="{'active': $route.path === '/feedback-profile'}">
                <router-link to="/feedback-profile">
                    <p>Обратная связь</p>
                </router-link>
            </li>
            <li>
                <a class="pointer" @click="logout()">
                    <p>Выйти</p>
                </a>
            </li>
        </ul>        
    </div>

</template>

<style>
    .mobile_aside_acc {
        display: none;
    }
    @media (max-width: 768px) {
        .mobile_aside_acc {
            display: block !important;
            overflow-x: auto;
            margin: 30px 0px 0px 0px;
            width: 100%;
        }

        .mobile_aside_acc ul {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-bottom: 15px;
        }

        .mobile_aside_acc li {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 18px 18px;

            height: 40px;

            margin-right: 16px;

            background: #FFFFFF;
            border-radius: 8px;

            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;        
        }

        .mobile_aside_acc .active {
            background: #00D45F !important;
        }

        .mobile_aside_acc .active p {
            color: #fff !important;
        }
    }
</style>

<script>
    import axios from 'axios'
	export default {
		name: 'SideProfile',
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        methods: {
            logout() {
                axios.defaults.headers.common["Authorization"] = ""

                localStorage.removeItem("token")
                localStorage.removeItem("user_id")
                localStorage.removeItem("sub_id")
                localStorage.removeItem("user")

                this.$store.commit('removeUser')
                this.$store.commit('removeUserInfo')
                this.$store.commit('removeToken')
                this.$store.commit('removeSub')

                this.$router.push('/')
            },
        },
	}
</script>