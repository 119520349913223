<template>
    <Header />
    <div class="full__white">
        <section class="ceo-title ceo-title__egrip">
            <div class="container">
                <h1>Количество сотрудников</h1>
                <p>Сведения о количество сотрудников, официально работающих у контрагента, прямым и косвенным образом помогут охарактеризовать компанию. Эти данные формируются на основе информации из ФНС и являются открытыми.</p>
            </div>
        </section>
        <section class="affril-list affril-list__activities">
            <div class="container">
                <div class="affril-list__name">
                    <h2>О чем расскажет количество сотрудников компании</h2>
                    <p>Получив с помощью сервиса среднесписочную численность сотрудников и сравнить ее с годовым оборотом, фондом заработной платы или средней заработной платой, можно проанализировать их соотношение и сделать выводы:</p>
                </div>
                <ul>
                    <li> Финансовые показатели в целом соответствуют количеству работающих, следовательно, маловероятны претензии ФНС в части серой зарплаты и ухода от взносов в ПФР и ФСС. Компания оформляет работников официально без «серой» заработной платы.</li>
                    <li>Сотрудников мало, ведется производственная или иная деятельность, требующая большого численного состава — есть вероятность, что в организации трудятся неоформленные официально сотрудники, получающие заработную плату в конвертах. Следовательно, контрагент обналичивает средства и уводит их от налогообложения.</li>
                    <li> Средняя заработная плата невелика при солидных оборотах — в компании есть «серая» заработная плата, обналичка и уход от налогов.</li>
                    <li>Если вы собираетесь работать с компанией, имеющей 2-3 человек кадрового состава — задумайтесь, можно ли доверить ей солидные авансовые платежи, в состоянии ли эта организация освоить большие финансы. Постарайтесь понять, посредник или непосредственный исполнитель предлагает свои услуги. И делайте выводы.</li>
                    <p class="affril-list__activities_more">Вышеперечисленным списком выводы не ограничиваются. Внимательно изучайте показатели компании и умейте читать между строк.</p>
                </ul>
            </div>
        </section>
        <section class="potential potential__ceo">
            <img src="@/assets/img/potential_1.svg" alt="">
            <img src="@/assets/img/potential_2.svg" alt="">
            <div class="container">
                <h2>Возможные угрозы</h2>
                <p>Для анализа информации о контрагенте принимайте нестандартные решения. Например, если компания имеет небольшой фонд заработной платы при большом количестве работников — посетите офис контрагента.</p>
                <p>Большие офисные помещения, оформленные на высоком уровне, подскажут, что реальный доход сотрудников скрыт от налоговиков.</p>
            </div>
        </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Employees',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
