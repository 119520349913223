<template>
    <HeaderProfile />
    <section class="acc">
        <div class="container">
            <SideProfile />
            <div class="acc__wrapper">
                <div class="acc-data">
                    <h3>Личные данные</h3>
                    <form class="acc-data__form">
                        <input v-model="user.fio" type="text" placeholder="ФИО">
                        <input v-model="user.email" type="email" placeholder="Email">
                        <input v-model="user.phone" type="tel" v-mask="'+# (###) ###-##-##'" placeholder="Телефон">
                        <input v-model="user.inn" type="tel" v-mask="'############'" placeholder="ИНН компании">
                        <button class="btn__green" type="button" @click="UpdateData()">Сохранить</button>
                    </form>
                </div>
                <div class="sub" v-if="sub != null">
                    <div class="">
                        <p class="bold">Ваш тариф «{{ sub_info.name }}»</p>
                        <p>активна до {{ data(sub.date_end) }}</p>
                    </div>
                    <p class="info__block">Действует</p>
                    <div class="sub__wrapper">
                        <div v-if="sub.sub != 3">
                            <img src="@/assets/icons/sub_1.svg" alt="">
                            <p>{{ sub.request_num }}/{{ sub.request_num_all }}</p>
                            <p>запросов</p>
                        </div>
                        <div v-else>
                            <img src="@/assets/icons/sub_1.svg" alt="">
                            <p>∞/∞</p>
                            <p>запросов</p>                            
                        </div>
                        <div v-if="sub.sub == 1">
                            <img src="@/assets/icons/sub_2.svg" alt="">
                            <p>1/1</p>
                            <p>устройства</p>
                        </div>
                        <div v-else>
                            <img src="@/assets/icons/sub_2.svg" alt="">
                            <p>∞/∞</p>
                            <p>устройства</p>
                        </div>
                    </div>
                    <button @click="modal__sub = true" class="btn__gray">Управлять тарифом</button>              
                </div>
                <div class="sub none-sub" v-else>
                    <h3 style="margin-bottom: 20px">Тарифы</h3>
                    <h6 class="none__sub">У вас нет активных тарифов</h6>
                    <img src="@/assets/img/visual_sub.svg" alt="">
                    <button  class="btn__gray" @click="modal_tarif = true">Изучить тарифы</button>
                </div>
                <div class="acc-data">
                    <h3>Смена пароля</h3>
                    <form class="acc-data__form">
                        <input v-model="cur_pas" type="password" placeholder="Введите старый пароль">
                        <input v-model="new_pas" type="password" placeholder="Введите новый пароль">
                        <input v-model="new_pas2" type="password" placeholder="Повторите новый пароль">
                        <div v-if="cur_pas && new_pas && (new_pas == new_pas2)">
                            <button class="btn__green" type="button" @click="NewPassword()">Сменить</button>
                        </div>
                        <div v-else>
                            <button class="btn__green" type="button" disabled>Сменить</button>
                        </div>
                        
                    </form>
                </div>
                <div class="acc-card" v-if="cards.length != 0">
                    <h3>Способы оплаты <a href="#"><img src="@/assets/icons/edit.svg" alt=""></a></h3>
                    <div 
                        class="acc-card__item" v-for="(list, index) in cards" 
                        :class="{'acc-card__active': list.id == card_current.id}">
                        <div class="d-flex-card">
                            <h3>{{ list.card_type }} {{ list.card_last_four }}</h3>
                            <p>Срок действия: {{ list.card_exp_date }}</p>                            
                        </div>
                        <div class="acc-card__more" :class="{'acc-card__more_active': index == index_delete}" @click="MoreOpenCard(index)">
                            <span></span>
                            <a class="pointer" @click="DeleteCard(list.id)">Удалить карту</a>
                        </div>
                    </div>
                    <button class="btn__gray" @click="modal_card = true">Добавить новый</button>
                </div>
                <div class="sub none-sub" v-else>
                    <h3 style="margin-bottom: 20px">Способы оплаты</h3>
                    <h6 class="none__sub">Вы ещё не добавили ни одного способа оплаты</h6>
                    <button class="btn__gray" @click="modal_card = true">Добавить</button>
                </div>                
                <div class="acc-history">
                    <h3>История платежей</h3>
                    <div class="acc-history__wrapper" v-if="history_pays.length != 0 && MD == ''">
                        <div class="acc-history__item" v-for="list in history_pays">
                            <div class="">
                                <p class="bold">Оплата подписки «{{ subs[list.sub_id - 1].name }}» на {{ subs[list.sub_id - 1].duration }} мес.</p>
                                <p class="reg">{{ dataHistoryPay(list.confirm_date) }}/ Оплата банковской картой ****{{ list.card_last_four }} </p>
                            </div>
                            <p class="acc-history__price">-{{ list.amount }} ₽</p>
                        </div>
                    </div>
                    <div class="acc-history__wrapper" v-else>
                        <div class="acc-history__item">
                            <p class="reg">Тут пока пусто</p>
                        </div>  
                    </div>                   
                </div>
            </div>
        </div>
    </section>

    <div class="modal" :class="{'modal__active': modal_tarif}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper modal__rate">
            <h1>Тарифы</h1>
            <div class="rate__wrapper">
                <div class="rate__item">
                    <h2>Стандарт</h2>
                    <div class="rate__price">
                        <p>350 ₽</p>
                        <p>/ 1 месяц</p>
                    </div>
                    <ul>
                        <li><p>900 <span>запросов в месяц</span></p></li>
                        <li><p>1 <span>устройство</span></p></li>
                    </ul>
                    <button class="btn__green" @click="TarifModal(1)">Выбрать тариф</button>
                </div>
                <div class="rate__item rate__item_rec">
                    <div class="rate__rec">
                        <p class="reg">рекомендуем</p>
                    </div>
                    <h2>Премиум</h2>
                    <div class="rate__price">
                        <p>9900 ₽</p>
                        <p>/ 3 месяца</p>
                    </div>
                    <ul>
                        <li><p>3000 <span>запросов в месяц</span></p></li>
                        <li><p>неограниченное <span>количество устройств</span></p></li>
                    </ul>
                    <button class="btn__green" @click="TarifModal(2)">Выбрать тариф</button>
                </div>
                <div class="rate__item">
                    <h2>Безлимит</h2>
                    <div class="rate__price">
                        <p>49 900 ₽</p>
                        <p>/ 1 год</p>
                    </div>
                    <div class="rate__price">
                      <span style="color: rgba(0, 0, 0, .5);">57 500₽ / с ндс 20%</span>
                    </div>
                    <ul>
                        <li><p>неограниченное <span>количество запросов</span></p></li>
                        <li><p>неограниченное <span>количество устройств</span></p></li>
                    </ul>
                    <button class="btn__green" @click="TarifModal(3)">Выбрать тариф</button>
                </div>
            </div>
            <div class="modal__close pointer" @click="modal_tarif = false"></div>
        </div>
    </div>

    <ModalSub 
      :tarif__modal="tarif__modal" 
      :current_price="current_price"
      :current_tarif="current_tarif"
      v-on:modal="tarif__modal = $event" 
      v-on:price="current_price = $event" 
    />

    <div class="modal" :class="{'modal__active': modal_choose_sub}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Оплата подписки</h2>
            <p class="reg">После оплаты на указанную почту придут данные для входа в ваш автоматически созданный аккаунт</p>
            <div class="modal__pay" v-if="current_tarif == 1">
                <p>Стандарт <span>1 месяц</span></p>
                <p>350 ₽</p>
            </div>
            <div class="modal__pay" v-if="current_tarif == 2">
                <p>Премиум <span>3 месяца</span></p>
                <p>9900 ₽</p>
            </div>
            <div class="modal__pay" v-if="current_tarif == 3">
                <p>Безлимит <span>1 год</span></p>
                <p>49 900 ₽</p>
            </div>
            <div class="modal__reg">
                <input type="text" placeholder="Промокод">
            </div>
            <div class="modal__pay">
                <p>К оплате:</p>
                <p class="bold">{{ current_price }} ₽</p>
            </div>
            <button class="btn__green pointer">Перейти к оплате</button>
            <p class="modal__ver">Нажимая кнопку «Перейти к оплате», я соглашаюсь с условиями <a href="#">политики конфиденциальности</a> и <a href="#">пользовательского соглашения</a></p>
            <div class="modal__close pointer" @click="ModalSub()"></div>
        </div>
    </div>

    <div class="modal" :class="{'modal__active': modal__sub}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper" v-if="sub != null">
            <h2>Управление тарифом</h2>
<!--             <div class="modal__sub">
                <p class="med">Автопродление</p>
                <label for="" class="input-check">
                    <input type="checkbox">
                    <span></span>
                    <p>вкл.</p>
                </label>
            </div>
            <p>Следующий платёж: <span>{{ dataSub(sub.date_end) }}</span></p> -->
<!--             <button class="btn__green" disabled>Сохранить</button> -->
            <a class="modal__sub_error pointer" @click="sub__end()">Отменить тариф</a>
            <div class="modal__close pointer" @click="modal__sub = false"></div>
        </div>
    </div>  

    <div class="modal" :class="{'modal__active': modal_cancel}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper" v-if="sub != null">
            <h2>Вы уверены, что хотите отменить подписку?</h2>
            <p class="reg">После {{ dataSub(today) }} вы больше не сможете пользоваться всеми преимуществами «{{ sub_info.name }}»</p>
            <button class="btn__green" @click="back__sub()">Назад</button>
            <button class="btn__modal pointer" @click="DeleteSub()">Отменить подписку</button>
            <div class="modal__close pointer" @click="modal_cancel = false"></div>
        </div>
    </div>

    <AddCard 
      :modal_card="modal_card" 
      :cards="cards"
      v-on:modal="modal_card = $event" 
    />

    <div class="modal" :class="{'modal__active': modal__modal_order}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper" v-if="status == 'Error'">
            <div class="status text-center">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="#FAEEEC"/>
                <path d="M17.1542 40.7245C16.5684 41.3103 16.5684 42.26 17.1542 42.8458C17.74 43.4316 18.6897 43.4316 19.2755 42.8458L17.1542 40.7245ZM42.8457 19.2756C43.4315 18.6898 43.4315 17.7401 42.8457 17.1543C42.2599 16.5685 41.3102 16.5685 40.7244 17.1543L42.8457 19.2756ZM19.2755 42.8458L42.8457 19.2756L40.7244 17.1543L17.1542 40.7245L19.2755 42.8458Z" fill="#ED6A50"/>
                <path d="M19.2775 17.1542C18.6917 16.5684 17.7419 16.5684 17.1561 17.1542C16.5704 17.74 16.5704 18.6897 17.1561 19.2755L19.2775 17.1542ZM40.7264 42.8457C41.3121 43.4315 42.2619 43.4315 42.8477 42.8457C43.4335 42.2599 43.4335 41.3102 42.8477 40.7244L40.7264 42.8457ZM17.1561 19.2755L40.7264 42.8457L42.8477 40.7244L19.2775 17.1542L17.1561 19.2755Z" fill="#ED6A50"/>
                </svg>
            </div>
            <h2>При оплате возникла ошибка</h2>
            <p class="reg">Попробуйте ещё раз чуть позже</p>
            <button class="btn__green pointer" @click="StatusEnd()">Хорошо</button>
            <div class="modal__close pointer" @click="StatusEnd()"></div>
        </div>
        <div class="modal__wrapper" v-else>
            <div class="status text-center">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="#EDFAEF"/>
                <path d="M16.666 30.0004L24.9156 38.2499L42.5933 20.5723" stroke="#00D45F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <h2>Подписка успешно оплачена!</h2>
            <p class="reg">Теперь вам доступны преимущества Datafix</p>
            <button class="btn__green pointer" @click="StatusEnd()">Готово</button>
            <div class="modal__close pointer" @click="StatusEnd()"></div>
        </div>
    </div>

    <Footer />   
</template>

<style scoped>
    .status {
        margin-bottom: 10px;
    }
    .text-center {
        text-align: center;
    }
    .none__sub {
        /* Текст/Основной */

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        color: #737373;
    }

    .none-card {
        justify-content: flex-start !important;
    }

    .none-sub img {
        margin: 55px 0px 0px;
    }

    .acc-card__active {
        border: 1px solid #00D45F;
    }
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    import Footer from '@/components/Footer/Footer.vue'
    import HeaderProfile from '@/components/Headers/HeaderProfile.vue'
    import SideProfile from '@/components/SideMenu/SideProfile.vue'
    import ModalSub from '@/components/Subs/ModalSub.vue'
    import AddCard from '@/components/Subs/AddCard.vue'

    export default {
        name: "MyProfile",
        directives: { mask },

        props: {
            MD: {
              type: [String, Number],
              default: "",
            },
            Card: {
              type: [String, Boolean],
              default: false
            },
            PaRes: {
              type: [String, Number],
              default: undefined
            }
        },
        components: {
            HeaderProfile,
            SideProfile,
            Footer,
            ModalSub,
            AddCard
        },
        data() {
            return {
                tarif__modal: false,
                current_tarif: null,
                current_price: null,

                modal__sub: false,
                modal_cancel: false,
                modal_card: false,
                modal_del_card: false,
                modal_tarif: false,
                modal_choose_sub: false,
                modal__modal_order: false,

                cardNumber: '',
                expDateMonth: '',
                expDateYear: '',
                name_card: '',

                user: {},
                cards: [],
                sub_info: {},
                card_current: {},
                history_pays: [],
                subs: [],
                sub: null,
                current_tarif: null,
                status: "",

                conf: {},

                cur_pas: "",
                new_pas: "",
                new_pas2: "", 

                today: "",

                index_delete: null,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
          this.today = new Date();
        },
        methods: {
            async GetMeInfo() {
                await axios
                    .get('/api/v1/me/')
                    .then(response => {
                        this.user = response.data

                          // let trans = lastPayment.transaction.transaction
                          // let endPaymentDate = new Date(this.CorDateFrom(trans.completed_datetime, this.sub.duration).split(".").reverse().join(","))
                          // let dateNow = new Date()
                          // let remind = endPaymentDate - dateNow
                          // console.log("endPaymentDate", endPaymentDate);
                          // console.log("dateNow", dateNow);
                          // if (this.user.payment == false) {

                          //    if (remind > 0) {
                          //       this.paymentSuccess = true;
                          //       console.log("payment true")
                          //       this.UpdateData("payment")
                          //    }
                          // }
                          // if (this.user.payment == true) {
                          //    if (remind < 0) {
                          //       this.paymentSuccess = false;
                          //       this.UpdateData("payment")
                          //    }
                          // }
                          // if (remind > 0) {
                          //    this.paymentSuccess = true;
                          //    this.UpdateData("payment", true)
                          // } else {
                          //    this.paymentSuccess = false;
                          //    this.UpdateData("payment", true)
                          // }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetSub() {
                await axios
                    .get(`/api/v1/sub/`)
                    .then(response => {
                        if (response.data.length != 0) {
                            this.sub = response.data[response.data.length - 1]
                        }

                        let dateNow = new Date()
                        let remind = new Date(this.sub.date_end) - dateNow

                        if (remind < 0) {
                            this.DeleteSub()
                        }

                        if (this.sub != null) {
                            this.GetSubInfo()
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetSubInfo() {
                await axios
                    .get(`/api/v1/subs/${this.sub.sub}/`)
                    .then(response => {
                        this.sub_info = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetCards() {
                await axios
                    .get(`/api/v1/cards/`)
                    .then(response => {
                        this.cards = response.data

                        if (Object.keys(this.card_current).length == 0 && this.cards.length != 0) {
                            localStorage.setItem("card_current", this.cards[0])
                            this.$store.commit('setCardInfo', this.cards[0])
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetSubsInfo() {
                await axios
                    .get(`/api/v1/subs/`)
                    .then(response => {
                        this.subs = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async DeleteSub() {
                await axios
                    .delete(`/api/v1/delete/sub/${this.sub.id}/`)
                    .then(response => {
                        this.sub = null
                        this.modal_cancel = false
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },            
             async DeleteCard(id) {
                await axios
                    .delete(`/api/v1/card/delete/${id}/`)
                    .then(response => {
                        this.GetCards()
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },     
            async UpdateData() {
                const DataNew = {
                    fio: this.user.fio,
                    email: this.user.email,
                    phone: this.user.phone,
                    inn: this.user.inn,
                }

                await axios
                    .put('/api/v1/me/', DataNew)
                    .then(response => {
                        toast({
                            message: 'Данные успешно обновленны!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'bottom-right',
                        })

                        this.user = response.data

                        localStorage.setItem("user", response.data)

                        this.$store.commit('setUserInfo', response.data)

                        console.log(this.user)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async SendNewPasswordEmail(value) {
                await axios
                .post(`/api/v1/send_new_password_email/`, {email: value})
                .then(response=> {
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
            },
            async NewPassword() {
                const DataNew = {
                    new_password: this.new_pas,
                    current_password: this.cur_pas,
                }

                await axios
                    .post('/api/v1/users/set_password/', DataNew)
                    .then(response => {
                        this.SendNewPasswordEmail(this.user.email)

                        toast({
                            message: 'Пароль успешно сменен!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'bottom-right',
                        })

                        this.cur_pas = ""
                        this.new_pas = ""
                        this.new_pas2 = ""

                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async RequestConfirmSub(md, pares) {
                const DataNew = {
                    payment_id: md,
                    pa_res: pares,
                }

                await axios
                    .post('/api/v1/create/order/3d', DataNew)
                    .then(response => {
                        this.status = response.data.status
                        this.modal__modal_order = true

                        setTimeout(() => {
                            this.StatusEnd()
                        }, 3000)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async HistoryPayPost(md, pares) {
                this.GetSub()

                const DataNew = {
                    payment_id: md,
                    pa_res: pares,
                    sub_id: Number(this.sub.sub),
                }

                await axios
                    .post('/api/v1/history/pay/post', DataNew)
                    .then(response => {
                        this.history_pays = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async AddCardConfirm(md, pares) {

                const DataNew = {
                    payment_id: md,
                    pa_res: pares,
                    full_number_card: this.cardNumber,
                    expDateMonth: this.expDateMonth,
                    expDateYear: this.expDateYear,
                    name_card: this.name_card,
                }

                await axios
                    .post('/api/v1/create/card/3d', DataNew)
                    .then(response => {
                        console.log('success')
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetHistoryPay() {
                await axios
                    .get('/api/v1/history/pay')
                    .then(response => {
                        this.history_pays = response.data
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetConf() {
                await axios
                    .get('/api/v1/conf')
                    .then(response => {
                        this.conf = response.data
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            SubToPay(value) {
                this.modal_tarif = false
                this.modal_choose_sub = true
                this.current_tarif = value

                if (this.current_tarif == 1) {
                    this.current_price = 350
                }

                if (this.current_tarif == 2) {
                    this.current_price = 9900
                }

                if (this.current_tarif == 3) {
                    this.current_price = 49900
                }
            },
            StatusEnd() {
                this.modal__modal_order = false
                // this.$router.push({path: '/my-profile'})
                window.location.href = '/my-profile'
            },
            ModalSub() {
                this.modal_choose_sub = false
            },
            TarifModal(id) {
                this.modal_tarif = false;
                this.tarif__modal = true;
                this.current_tarif = id

                this.Price()
            },
            Price() {
                if (this.current_tarif == 1) {
                    this.current_price = 350
                }

                if (this.current_tarif == 2) {
                    this.current_price = 9900
                }

                if (this.current_tarif == 3) {
                    this.current_price = 49900
                }
            },
            MoreOpenCard(index) {
                if (index != this.index_delete) {
                    this.index_delete = index
                } else {
                    this.index_delete = null
                }
                
            },
            back__sub() {
                this.modal__sub = true
                this.modal_cancel = false
            },
            sub__end() {
                this.modal__sub = false
                this.modal_cancel = true
            },
            data(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY')
              return datasort
            },  
            dataSub(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD MMMM YYYY')
              return datasort
            },  
            dataHistoryPay(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY в h:mm')
              return datasort
            },  
            CorDate(date) {
                 return moment(String(date), "YYYY-MM-DD").format('DD.MM.YYYY')
            },
        },
        created() {
            moment.locale('ru');
            this.card_current = this.$store.state.card_current

            if (this.MD) {
                this.RequestConfirmSub(this.MD, this.PaRes);
                if (String(this.Card) != 'true') {
                    setTimeout(() => {
                        this.GetSub()
                    }, 500)
                    setTimeout(() => {
                        if (String(status) != 'Error') {
                            this.HistoryPayPost(this.MD, this.PaRes)
                        }
                    }, 1000)
                } else {
                    this.AddCardConfirm(this.MD, this.PaRes)
                    // this.GetConf()
                }
            }
            this.GetSubsInfo()
            this.GetMeInfo()
            this.GetSub()
            this.GetHistoryPay()
            this.GetCards()
        }
   }
</script>