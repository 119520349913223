<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title">
        <div class="container">
            <h1>Рейтинг</h1>
        </div>
    </section>
    <section class="ceo-rating">
        <div class="container">
            <div class="ceo-rating__row">
                <p>Помимо конкретных данных, формируемых на сервисе для проверки компаний, мы показываем рейтинг контрагента, рассчитанный на основании множества открытых показателей:</p>
                <ul>
                    <li>Высокий означает, что контрагент надежный и не имеет очевидных проблем</li>
                    <li>Средний рейтинг имеет большинство проверяемых компаний с удовлетворительной надежностью</li>
                    <li>Низкий рейтинг может быть связан с недостаточной публичной информацией. Он часто присваивается молодым компаниям, работающим менее года. Низким рейтингом обладают контрагенты, имеющие проблемы в тех областях, которые мы проверяем. В любом случае рекомендуем провести детальную проверку или отказаться от сотрудничества с этой фирмой или ИП.</li>
                </ul>
            </div>
            <div class="ceo-rating__row">
                <img src="@/assets/img/ceo-rating.png" alt="">
                <p>Портал работает с динамично меняющимися данными, и по мере их поступления автоматически меняется и рейтинг компаний.</p>
            </div>
        </div>
    </section>
    <section class="affril-list affril-list__rating affril-list__pb">
        <div class="container">
            <div class="affril-list__name">
                <h2>Как рассчитывается рейтинг</h2>
                <p>Для формирования рейтинга сервис использует открытые источники Федеральной налоговой службы, Росстата, арбитража и пр. Данные обрабатываются по скоринговой системе с применением статистических методов обработки данных. Базой для расчета служат:</p>
            </div>
            <ul>
                <li>Дата регистрации, юридический адрес, наличие работающих контактных телефонов, статус, форма собственности, состав учредителей</li>
                <li>Присутствие компании в специальных «негативных» реестрах ФНС и других государственных органов</li>
                <li>Данные и актуальность полномочий генерального директора</li>
                <li>Положительные или отрицательные решения арбитражных судов</li>
                <li>Финансовые показатели</li>
                <li>Лицензии</li>
                <li>Исполнительные производства</li>
                <li>Дополнительные данные, предоставленные компанией на сервис</li>
                <p class="affril-list__rating_more">И прочие показатели</p>
            </ul>
        </div>
    </section>
    <div class="ceo-suc">
        <div class="container">
            <div class="ceo-suc__wrapper">
                <p>Всего для оценки мы используем около 40 показателей и привлекаем к работе профессиональных экономистов.</p>
            </div>
        </div>
    </div>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Если вы находитесь в специальном реестре Федеральной налоговой службы, для поднятия рейтинга необходимо предпринять все усилия, чтобы вас из этого реестра исключили, т. е. выполнить условия ФНС. Иначе рейтинг поднять не получится.</p>
            <p>Добавляйте данные о компании на сервис. Чем их больше — тем лучше. Повышение экономических показателей, отражающихся в бухгалтерской отчетности, лицензии, участие в тендерах, отсутствие незакрытых исполнительных производств также повысит рейтинг вашей компании.</p>
        </div>
    </section>
    <section class="ceo-prev ceo-prev__ver ceo-prev__wht">
        <div class="container">
            <div class="ceo-prev__info">
                <p>При анализе контрагента помните, что хороший рейтинг не является гарантией отсутствия рисков.</p>
                <p>Только комплексная проверка дает полную картину надежности компании. </p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <img src="@/assets/img/ceo-prev_rating.jpg" alt="">
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Rating',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
