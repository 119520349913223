<template>
    <div class="modal" :class="{'modal__active': modal_card}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Банковская карта</h2>
            <div class="modal__card">
                <input v-model="cardNumber" type="tel" v-mask="'#### #### #### ####'" placeholder="Номер карты">
                <div class="modal__card_row">
                  <input v-model="cardDate" type="tel" v-mask="'##/##'" placeholder="Срок действия">
                  <input v-model="cvv" type="tel" v-mask="'###'" placeholder="CVV">   
                </div>
                <input v-model="name_card" type="text" placeholder="Имя держателя">
            </div>
            <button class="btn__green" v-if="cardNumber.length == 19 && cardDate && cvv && name_card" @click="CardAdd()">Добавить</button>
            <button class="btn__green" v-else disabled>Добавить</button>
            <p class="modal__ver">Для верификации карты с вашей карты будет списан 1 рубль</p>
            <div class="modal__close pointer" @click="$emit('modal', false)"></div>
        </div>
    </div>

    <div class="modal" :class="{'modal__active': loader}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <img src="@/assets/icons/wait.svg" class="modal__wait">
        </div>
    </div>   

</template>

<script>
import axios from 'axios'

import moment from 'moment';
import { mask } from 'vue-the-mask'
import { toast } from 'bulma-toast'

export default {
	name: 'AddCard',
    directives: { mask },
    props: {
        modal_card: {
            type: Boolean,
            default: false,
        },
        cards: {
            type: Array,
        },
        current_tarif: {
            type: Number,
        },
    },
    data() {
        return {
            first__tarif: true,

            fio: "",
            phone: "",
            email: "",
            password: "",
            password2: "",

            email_log: "",
            pass_login: "",

            errors: [],
            cardNew: {},
            id_new_user: 0,

            cardNumber: '',
            expDateMonth: '',
            expDateYear: '',
            cvv: '',
            name_card: '',
            cardDate: '',
            promocode: '',
            promo: {},

            error_promo: [],

            date_start: "",
            date_end: "",

            subOrder: {},

            itog_price: null,
            current_price: 1,

            loader: false,
            ip: "123.123.123.123"
        }
    },
    mounted() {
        
    },
    beforeCreate() {
        
    },
    methods: {
      async GetIP() {
          await axios
          .get(`/api/v1/get_client_ip/`)
          .then(response => {
              this.ip = response.data
          })
          .catch(error => {
              console.log(error)
          })
      },
      CardAdd() {
        const checkout = new cp.Checkout({
            publicId: 'pk_3885a360b584f6526a8dfee76b7a2',
        });        

        const data_split = this.cardDate.split('/')
        this.expDateMonth = Number(data_split[0])
        this.expDateYear = Number(data_split[1])

        const fieldValues = {
            cvv: this.cvv,
            cardNumber: this.cardNumber,
            expDateMonth: this.expDateMonth,
            expDateYear: this.expDateYear,
        }

        checkout.createPaymentCryptogram(fieldValues)
            .then((cryptogram) => {
                this.PaymantReady(cryptogram)
            }).catch((errors) => {
                console.log(errors)
            });

      },
      async PaymantReady(cryptogram) {
          const data = {
              "amount": this.current_price,
              "currency":"RUB",
              "ip_address": this.ip,
              "name": this.name_card,
              "cryptogram": cryptogram,
              full_number_card: this.cardNumber,
              expDateMonth: this.expDateMonth,
              expDateYear: this.expDateYear,
              name_card: this.name_card,
          }

          const headersData = {
            'Content-Type': 'application/json',
          }

          await axios
          .post('/api/v1/create/card/', data, {headers: headersData})
          .then(response => {
              this.cardNew = response.data

              if(this.cardNew.confirm_link) {
                  this.$emit('modal', false)
                  this.loader = true

                  setTimeout(() => {
                     this.sendFormConfirm(this.cardNew)
                  }, 3000)
              }
          })
          .catch(error => {
              console.log(error)
          })
      },
      submittedDownloadForm(e) {
        console.log(e);
      },
      sendFormConfirm(acsFormData) {
        const form = document.createElement("form");
        form.action = acsFormData.confirm_link;
        form.method = "post";
        form.name = "downloadForm";
        form.onsubmit = this.submittedDownloadForm;
        const transactionId = document.createElement("input");
        transactionId.name = "MD";
        transactionId.type = "hidden";
        transactionId.value = acsFormData.id_pay;
        const termUrl = document.createElement("input");
        termUrl.name = "TermUrl";
        termUrl.type = "hidden";
        termUrl.value = "https://datafix.tech/api/v1/payment/redirect/card";
        const paReq = document.createElement("input");
        paReq.name = "PaReq";
        paReq.type = "hidden";
        paReq.value = acsFormData.pa_req;
        form
          .appendChild(paReq)
          .appendChild(transactionId)
          .appendChild(termUrl);
        document.body.append(form);
        form.submit();
      },
      CloseModalTarif() {
        this.$emit('modal', false)
      },
    },
    watch: {

    },
    created() {
        this.isAuthenticated = this.$store.state.isAuthenticated
        console.log(this.cards)
        this.GetIP()
    }
}
</script>