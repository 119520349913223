<template>
    <div class="inner-page">
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="founder">
            <h1>Учредители ({{ card_info.СвУчредит.all.length }})</h1>
            <div class="founder__wrapper" v-if="card_info.СвУчредит.all.length != 0">
                <div class="founder__row founder__title">
                    <div class="">
                        <img src="@/assets/icons/founder_icon.svg" alt="">
                        <p>Учредитель</p>
                    </div>
                    <div class="">
                        <p>Дата</p>
                    </div>
                    <div class="">
                        <p>ИНН</p>
                    </div>
                    <div class="">
                        <p>Доля(%)</p>
                    </div>
                    <div class="">
                        <p>Доля (руб.)</p>
                    </div>
                </div>
                <div class="founder__row" v-for="item in card_info.СвУчредит.all">
                    <div v-if="item.name">
                        <p>{{ item.name }}</p>
                    </div>
                    <div v-else>
                        <p>-</p>
                    </div>
                    <div v-if="item.date">
                        <p class="founder__blue">{{ item.date }}</p>
                    </div>
                    <div v-else>
                         <p>-</p>
                    </div>
                    <div v-if="item.inn">
                        <p><span>ИНН:</span> {{ item.inn }}</p>
                    </div>
                    <div v-else>
                        <p><span>ИНН:</span>-</p>
                    </div>
                    <div v-if="item.dol_abs">
                        <p><span>Доля(%):</span> {{ ComputedProcent(item.dol_abs) }}</p>
                    </div>
                    <div v-else>
                        <p><span>Доля(%):</span>-</p>
                    </div>
                    <div v-if="item.dol_abs">
                        <p><span>Доля (руб.):</span> {{ item.dol_abs }}</p>
                    </div>
                    <div v-else>
                        <p><span>Доля (руб.):</span> -</p>
                    </div>
                </div>
            </div>
            <div v-else class="none__databody">
                <h4>Данных нет</h4>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Uchrediteli",
        directives: { mask },
        props: {
            card_info: Object,
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            ComputedProcent(value) {
                let x = 0

                x = parseFloat(value / this.card_info.СвУчредит.sumCap) * 100
                return x
            },
            dateUpdate(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY')
              return datasort
            },  
        },
        created() {

        },
        watch: {

        },        
   }
</script>