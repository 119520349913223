<template>
    <div class="main-llc__wrapper" v-if="!loader">
        <div class="main-llc__col">
            <div class="main-llc__name">
                <h1>ИП {{ card_info.ФИО }}</h1>
                <div class="main-llc__name_row">
                    <div class="info__block">{{ card_info.Статус }}</div>
                    <div class="main-llc__name_text">
                        <p>Дата регистрации</p>
                        <p>{{ card_info.ДатаОГРНИП }}</p>
                    </div>
                    <div class="main-llc__name_link">
                        <a v-if="status == 'error'" class="pointer" @click="AddFavorite(card_info, project_id)">
                            <img src="@/assets/icons/main-lcc_link_3.svg" alt="">
                        </a>
                        <a class="pointer" @click="UnFav()" v-else>
                            <img src="@/assets/icons/fav_active.svg" alt="">
                        </a>
                    </div>
                </div>
                <div class="main-llc__name_wrapper">
                    <div class="main-llc__name_col">
                        <div class="main-llc__name_item">
                            <p class="bold"><img src="@/assets/icons/llc_name_block_1.svg" alt="">Основные реквизиты</p>
                            <div class="main-llc__name_har">
                                <div v-if="card_info.ИННФЛ">
                                    <p>ИНН</p>
                                    <p>{{ card_info.ИННФЛ }}</p>
                                </div>
                                <div v-if="card_info.ОГРНИП">
                                    <p>ОГРНИП</p>
                                    <p>{{ card_info.ОГРНИП }}</p>
                                </div>                            
                            </div>
                        </div>
                        <div class="main-llc__name_item" v-if="card_info.НаимВидИП">
                            <p class="bold">Вид предпринимательства</p>
                            <p>{{ card_info.НаимВидИП }}</p>
                        </div>
<!--                         <div class="main-llc__name_item">
                            <p class="bold">Гражданство</p>
                            <p>Гражданин Российской Федерации</p>
                        </div>
                        <div class="main-llc__name_item">
                            <p class="bold">Пол</p>
                            <p>Мужской</p>
                        </div> -->
                        <div class="main-llc__name_item">
                            <p class="bold">Регион получения ИНН</p>
                            <p>{{ card_info.РегионПолучИНН }}</p>
                        </div>
                        <div class="main-llc__name_item">
                            <p class="bold"><img src="@/assets/icons/llc_name_block_2.svg" alt=""> Местоположение</p>
                            <p>{{ card_info.РегионДействИП }}</p>
                        </div>
                    </div>
                    <div class="main-llc__name_col">
                        <div class="main-llc__name_item">
                            <p class="bold">Основной вид деятельности</p>
                            <p v-if="card_info.НаимОКВЭД || card_info.КодОКВЭД">{{ card_info.НаимОКВЭД }} <span>({{ card_info.КодОКВЭД }})</span></p>
                            <p v-else>Данных нет</p>
<!--                             <a href="#" class="main-llc__name_more main-llc__name_more_link">Все виды деятельности (3)</a> -->
                        </div>
                        <div class="main-llc__name_item" v-if="card_info.КатСубМСП">
                            <p class="bold">Реестр МСП</p>
                            <p>{{ card_info.КатСубМСП['1'] }}</p>
                        </div>
                        <div class="main-llc__name_item">
                            <p class="bold">Коды статистики</p>
                            <div class="main-llc__name_har">
                                <div v-if="card_info.ОКАТО">
                                    <p>ОКАТО</p>
                                    <p>{{ card_info.ОКАТО }}</p>
                                </div>
                                <div v-if="card_info.ОКОГУ">
                                    <p>ОКОГУ</p>
                                    <p>{{ card_info.ОКОГУ }}</p>
                                </div>
                                <div v-if="card_info.ОКОПФ">
                                    <p>ОКОПФ</p>
                                    <p>{{ card_info.ОКОПФ }}</p>
                                </div>
                                <div v-if="card_info.ОКПО">
                                    <p>ОКПО</p>
                                    <p>{{ card_info.ОКПО }}</p>
                                </div>
                                <div v-if="card_info.ОКТМО">
                                    <p>ОКТМО</p>
                                    <p>{{ card_info.ОКТМО }}</p>
                                </div>    
                            </div>
                        </div>
                        <div class="main-llc__name_item">
                            <p class="bold">Реестр недобросовестных поставщиков</p>
                            <p v-if="card_info.НедобросовПостав == 0">Не числится</p>
                            <p v-else>Числится</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="llc-pdf llc-pdf__ip main-llc__mob">
                <a :href="`https://zachestnyibiznesapi.ru/paid/data/fns-card-report-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item pointer">
                    <img src="@/assets/icons/pdf.svg" alt="">
                    <p class="bold">Выписка из ЕГРЮЛ</p>
                    <p>С подписью ФНС / на сегодняшнюю дату</p>
                </a>
                <a :href="`https://zachestnyibiznesapi.ru/paid/data/company-fns-requirements-info?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                    <img src="@/assets/icons/pdf.svg" alt="">
                    <p class="bold">Бухгалтерская отчетность</p>
                    <p>С подписью ФНС (официально)</p>
                </a>
                <a :href="`https://zachestnyibiznesapi.ru/paid/data/pdo-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                    <img src="@/assets/icons/pdf.svg" alt="">
                    <p class="bold">Отчет Должная осмотрительность</p>
                    <p>Отчет о рисках по нормативам ФНС с рекомендациями по сбору документов</p>
                </a>
            </div>
            <div class="llc-arb main-llc__mob">
                <h4>Арбитражные дела</h4>
                <p>Найдено <span>{{ total_arbitaj }}</span></p>
                <ul class="llc-arb__list">
                    <li class="active">Все ({{ total_arbitaj }})</li>
                </ul>
                <div class="llc-arb__wrapper">
                    <div class="llc-arb__item" v-if="total_arbitaj != 0" v-for="(item, index) in items_arbitaj">
                        <div class="llc-arb__name">
                            <div class="">
                                <p class="bold">№ {{ item.НомерДела }}</p>
                                <p class="reg">от {{ item.СтартДата }}</p>
                            </div>
                        </div>
                        <ul>
                            <li v-if="item.СуммаИска">
                                <span>Сумма иска, руб:</span> {{ toLocateSum(item.СуммаИска) }} ₽
                            </li>
                            <li v-if="item.Истец">
                                <span>Истец:</span> {{ item.Истец[0].Наименование }}
                            </li>
                            <li v-if="item.Ответчик">
                                <span>Ответчик:</span> {{ item.Ответчик[0].Наименование }}
                            </li>
                            <li v-if='item["Иной участник"]'>
                                <span>Иной участник:</span> {{ item["Иной участник"][0].Наименование }}
                            </li>
                            <li v-if='item["Третье лицо"]'>
                                <span>Третье лицо:</span> {{ item["Третье лицо"][0].Наименование }}
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <p>Данных нет</p>
                    </div>
                </div>
                <a @click="RedirectToTab(1)" class="llc-arb__more">Смотреть все дела</a>
            </div>
            <div class="llc-procur" v-if="total_partipant != 0 || total_partipant != 0">
                <h4>Госзакупки</h4>
                <p>Найдено <span>{{ total_customer + total_partipant }}</span> контрактов</p>
                <ul class="llc-grah__tab">
                    <li @click="TabChooseGoszakypki(0)" 
                        :class="{'active': tab_zakupki == 0}">
                        Поставщик ({{ total_partipant }})
                    </li>
                    <li 
                        @click="TabChooseGoszakypki(1)"
                        :class="{'active': tab_zakupki == 1}"
                    >
                        Заказчик ({{ total_customer }})
                    </li>
                </ul>
                <div class="llc-procur__wrapper">
                    <div class="llc-procur__item" v-show="tab_zakupki == 0" v-for="list in items_participant.slice(0, 4)">
                        <div class="">
                            <p class="reg">{{ list.participantFullName }}</p>
                            <p class="bold_s">{{ list.count }} <span>контрактов на сумму</span> {{ toLocateSum(list.sum) }} ₽</p>
                        </div>
                    </div>
                    <div class="llc-procur__item" v-show="tab_zakupki == 1" v-for="list in items_customer.slice(4, 8)">
                        <div class="">
                            <p class="reg">{{ list.customerFullName }}</p>
                            <p class="bold_s">{{ list.count }} <span>контрактов на сумму</span> {{ toLocateSum(list.sum) }} ₽</p>
                        </div>
                    </div>
                </div>
                <a @click="RedirectToTab(2)" class="llc-procur__more pointer">Смотреть все контракты</a>
            </div>
            <div class="llc-procur" v-else>
                <h4>Госзакупки</h4>
                <p>Найдено <span>0</span> контрактов</p>                
            </div>
            <div class="main-llc__block_var">
                <div class="main-llc__col">
                    <div class="llc-tax">
                        <h4>Налоговый орган</h4>
                        <div class="llc-tax__name">
                            <p>{{ card_info.НаимНО }}</p>
                            <div class="llc-tax__row">
                                <p>Дата постановки на учет</p>
                                <p>{{ card_info.ДатаПостУч }}</p>
                            </div>
                        </div>
                        <div class="llc-tax__item" v-if="card_info.ДатаРегФСС || card_info.РегНомФСС">
                            <p>Сведения о регистрации в ФСС</p>
                            <div class="llc-tax__row" v-if="card_info.РегНомФСС">
                                <p>Регистрационный номер</p>
                                <p>{{ card_info.РегНомФСС }}</p>
                            </div>
                            <div class="llc-tax__row" v-if="card_info.ДатаРегФСС">
                                <p>Дата регистрации</p>
                                <p>{{ card_info.ДатаРегФСС }}</p>
                            </div>
                        </div>
                        <div class="llc-tax__item" v-if="card_info.ДатаРегПФ || card_info.РегНомПФ">
                            <p>Сведения о регистрации в ПФР</p>
                            <div class="llc-tax__row" v-if="card_info.РегНомПФ">
                                <p>Регистрационный номер</p>
                                <p>{{ card_info.РегНомПФ }}</p>
                            </div>
                            <div class="llc-tax__row" v-if="card_info.ДатаРегПФ">
                                <p>Дата регистрации</p>
                                <p>{{ card_info.ДатаРегПФ }}</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="main-llc__col">
                    <div class="llc-license" v-if="items_licenses.length == 0">
                        <h4>Лицензии</h4>
                        <p>Организация имеет <span>0</span> лицензий</p>
                    </div>
                    <div class="llc-license" v-else>
                        <h4>Лицензии</h4>
                        <p>Организация имеет <span>{{ total_licenses }}</span> лицензии</p>
                        <div class="llc-license__item" v-for="item in items_licenses.slice(0, 2)">
                            <p class="bold_s">{{ item['Номер лицензии'] }}</p>
                            <p>с {{ item['Дата начала лицензии'] }}</p>
                            <div class="llc-license__green">{{ item.Источник }}</div>
                        </div>
                        <a @click="RedirectToTab(5)" class="llc-arb__more">Подробнее</a>
                    </div>
                    <div class="llc-check" v-if="items_proverki.length == 0">
                        <h4>Проверки</h4>
                        <p>Данных нет</p>
                    </div>
                    <div class="llc-check" v-else>
                        <h4>Проверки</h4>
                        <p class="mar_update">Обновлено {{ TodayDate() }}</p>
                        <div>
                            <div class="llc-check__item" v-for="list in items_proverki.slice(0, 1)">
                                <p v-if='list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].START_DATE'> Период проведения: <span>с {{ list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].START_DATE }} по {{ list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].END_DATE }} </span></p>
                                <p>Продолжительность: 
                                    <span v-if='list.I_APPROVE["@attributes"].DURATION_HOURS'>
                                        {{ list.I_APPROVE["@attributes"].DURATION_HOURS }} час.
                                    </span>
                                    <span v-if='list.I_APPROVE["@attributes"].DURATION_DAY'>
                                        {{ list.I_APPROVE["@attributes"].DURATION_DAY }} дн.
                                    </span>
                                </p>
                                <p v-if='list.I_APPROVE["@attributes"].INSP_TARGET'>Цель:<span>{{ list.I_APPROVE["@attributes"].INSP_TARGET }}</span></p>
                                <p v-if='list["@attributes"].ITYPE_NAME'>Тип:<span>{{ list["@attributes"].ITYPE_NAME }}</span></p>
                            </div>
                        </div>

                        <a @click="RedirectToTab(9)" class="llc-arb__more">Подробнее</a>
                    </div>
                </div>
            </div>
            <div class="llc-desc" v-if="card_info.Описание">
                <h4>Описание деятельности компании</h4>
                <p v-html="card_info.Описание"></p>
            </div>
            <div class="llc-desc" v-else>
                <h4>Описание деятельности компании</h4>
                <p>Данных нет</p>                
            </div>
        </div>
        <div class="main-llc__col">
            <div class="llc-pdf llc-pdf__ip main-llc__desktop">
<!--             <a :href="`https://zachestnyibiznesapi.ru/paid/data/fns-card-report-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item pointer">
                <img src="@/assets/icons/pdf.svg" alt="">
                <p class="bold">Выписка из ЕГРЮЛ</p>
                <p>С подписью ФНС / на сегодняшнюю дату</p>
            </a>
            <a :href="`https://zachestnyibiznesapi.ru/paid/data/company-fns-requirements-info?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                <img src="@/assets/icons/pdf.svg" alt="">
                <p class="bold">Бухгалтерская отчетность</p>
                <p>С подписью ФНС (официально)</p>
            </a> -->
            <a :href="`https://zachestnyibiznesapi.ru/paid/data/pdo-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                <img src="@/assets/icons/pdf.svg" alt="">
                <p class="bold">Отчет Должная осмотрительность</p>
                <p>Отчет о рисках по нормативам ФНС с рекомендациями по сбору документов</p>
            </a>
            </div>
            <div class="llc-arb main-llc__desktop">
                <h4>Арбитражные дела</h4>
                <p>Найдено <span>{{ total_arbitaj }}</span></p>
                <ul class="llc-arb__list">
                    <li class="active">Все ({{ total_arbitaj }})</li>
                </ul>
                <div class="llc-arb__wrapper">
                    <div class="llc-arb__item" v-if="total_arbitaj != 0" v-for="(item, index) in items_arbitaj">
                        <div class="llc-arb__name">
                            <div class="">
                                <p class="bold">№ {{ item.НомерДела }}</p>
                                <p class="reg">от {{ item.СтартДата }}</p>
                            </div>
                        </div>
                        <ul>
                            <li v-if="item.СуммаИска">
                                <span>Сумма иска, руб:</span> {{ toLocateSum(item.СуммаИска) }} ₽
                            </li>
                            <li v-if="item.Истец">
                                <span>Истец:</span> {{ item.Истец[0].Наименование }}
                            </li>
                            <li v-if="item.Ответчик">
                                <span>Ответчик:</span> {{ item.Ответчик[0].Наименование }}
                            </li>
                            <li v-if='item["Иной участник"]'>
                                <span>Иной участник:</span> {{ item["Иной участник"][0].Наименование }}
                            </li>
                            <li v-if='item["Третье лицо"]'>
                                <span>Третье лицо:</span> {{ item["Третье лицо"][0].Наименование }}
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <p>Данных нет</p>
                    </div>
                </div>
                <a @click="RedirectToTab(1)" class="llc-arb__more">Смотреть все дела</a>
            </div>
<!--             <div class="llc-product llc-product__ip">
                <h4>Исполнительные производства</h4>
                <p>Найдено <span>10</span></p>
                <div class="llc-product__item">
                    <p class="bold">№ 49270/21/77055-ИП</p>
                    <p>от 18.06.2021</p>
                    <div class="llc-product__gos">Госпошлина</div>
                </div>
                <div class="llc-product__item">
                    <p class="bold">№ 49270/21/77055-ИП</p>
                    <p>от 18.06.2021</p>
                    <div class="llc-product__gos">Взыскание</div>
                </div>
                <a href="#" class="llc-arb__more">Подробнее</a>
            </div> -->
            <div class="llc-req">
                <h4>Реквизиты 
                </h4>
                <p> {{ card_info.НаимОКВЭД }}<br>
                    <br>
                    {{ card_info.РегионДействИП }} <br>
                    ОГРН {{ card_info.ОГРНИП }} <br>
                    ИНН {{ card_info.ИННИП }} <br>
                    ОКВЭД {{ card_info.КодОКВЭД }} <br>
                    <br>
                    {{ card_info.ФИО }}<br>
                </p>
            </div>
        </div>
    </div>
    <div class="loader__block" v-else>
        <div class="loader-wait">
            <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
        </div>         
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';
    

    export default {
        name: "Info",
        directives: { mask },
        props: {
            project_id: [String, Number],

            items_izmeneniya: Object,
            items_licenses: {
                type: Array,
                default: [],
            },

            items_proverki: {
                type: Array,
                default: [],
            },

            card_info: Object,

            total_partipant: Number,
            total_customer: Number,
            items_participant: Array,
            items_customer: Array,
            zvezdi: Number,
            items_arbitaj: [Array, Object],
            total_arbitaj: Number,
        },
        components: {

        },
        data() {
            return {
                tab_zakupki: 0,
                tab__graph: 0,

                address: [],
                ceo: [],
                founder: [],

                big_number: 0,
                small_number: 0,

                loader: true,

                object_favorite: {},
                user: {},
                status: "",

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            if (this.items_izmeneniya.address) {
                this.address = this.items_izmeneniya.address
            }
            
            if (this.items_izmeneniya.ceo) {
                this.ceo = this.items_izmeneniya.ceo
            }

            if (this.items_izmeneniya.founder) {
                this.founder = this.items_izmeneniya.founder
            }


            setTimeout(() => {
                this.loader = false

                if (this.items_izmeneniya.address) {
                    this.address = this.items_izmeneniya.address
                }
                
                if (this.items_izmeneniya.ceo) {
                    this.ceo = this.items_izmeneniya.ceo
                }

                if (this.items_izmeneniya.founder) {
                    this.founder = this.items_izmeneniya.founder
                }
            }, 2400)

        },
        methods: {
            async GetMeInfo() {
                await axios
                    .get('/api/v1/me/')
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            RedirectToTab(value) {
                this.$emit('value', value)
                document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
            },
            TabChooseGoszakypki(value) {
                this.tab_zakupki = value 
            },
            TabChooseGraph(value) {
                this.tab__graph = value
            },
            async AddFavorite(list, project_id) {
                var inn = ""
                var kpp = ""
                var innfl = ""

                if (list.ИННФЛ) {
                    innfl = list.ИННФЛ
                }

                const dataForm = {
                    user: this.user.user,
                    title: list.ФИО,
                    inn: inn,
                    kpp: kpp,
                    innfl: innfl,
                    status: list.Статус,
                    project_id: project_id,
                    type_project: list.ТипДокумента,
                }

                await axios
                .post('/api/v1/favorite/post/', dataForm)
                .then(response => {
                    this.status = 'success'
                })
                .catch(error => {
                  console.log(error)
                })
                 
            },
            async UnFav() {
                await axios
                .delete(`/api/v1/favorite/delete/${this.object_favorite.id}/`)
                .then(response => {
                    this.status = 'error'
                })
                .catch(error => {
                  console.log(error)
                })
            },
            async CheckFavorite() {
                await axios
                .get(`/api/v1/favorite/check/${this.project_id}/`)
                .then(response => {
                    this.status = response.data.status

                    if (this.status == 'success') {
                        this.object_favorite = response.data.body
                    }
                })
                .catch(error => {
                  console.log(error)
                })
                 
            },
            SmallLargNumber(a, b, c) {
                let larg = 0
                let small = 0

                if (a > b) {
                    larg = a
                    small = b
                    if (c > a) {
                        larg = c
                    } else if (b > c) {
                        small = c
                    } 
                } else {
                    larg = b
                    small = a
                    
                    if (c > b) {
                        larg = c
                    } else if (a > c) {
                        small = c
                    }
                }      

                this.big_number = larg
                this.small_number = small 
            },
            FormulaProcent(value){
                this.SmallLargNumber(this.success.length, this.warning.length, this.danger.length)

                let sum = 0 
                let procent = 0 
                let x = 0   

                sum = Number(this.success.length + this.danger.length + this.warning.length)  

                if (this.big_number == value) {
                    procent = 100
                }  else {
                    procent = Math.floor((value / sum) * 100)
                }    

                if (this.small_number == value) {
                    procent = procent + 30
                } 

                x = Math.floor(75.36 - 75.36 * procent / 100)
                return x
            },
            toLocateSum(value) {
                let x = 0
                // var str = value.toString()
                // str = str.substring(0, str.length-3);
                // x = Number(str).toLocaleString('ru-RU')
                // return x
                x = Number(value).toLocaleString('ru-RU')
                return x
            },
            ComputedProcent(value) {
                let x = 0

                x = (Math.floor(this.card_info.СвУчредит.sumCap / value)) * 100
                return x
            },
            TodayDate() {
                return moment().locale('ru').format('L')
            },
            DateConvert(value) {
                var date = new Date(value*1000)
                return moment(date).locale('ru').format('L')
            },
        },
        watch: {
            // tab(value) {
            //     this.$emit('value', this.tab)
            // }
        },
        created() {
            this.GetMeInfo()
            this.CheckFavorite()
        }
   }
</script>