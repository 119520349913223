<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__egrip">
        <div class="container">
            <h1>ОГРНИП</h1>
            <p>Все предприниматели при регистрации государственном реестре получают основной государственный регистрационный номер индивидуального предпринимателя, состоящий из 15 цифр. Он кратко называется ОГРНИП.</p>
        </div>
    </section>
    <section class="affril-list affril-list__activities">
        <div class="container">
            <div class="affril-list__name">
                <h2>О чем расскажет ОГРНИП</h2>
                <p>В последовательности знаков зашифрована важная информация об ИП:</p>
            </div>
            <ul>
                <li>У индивидуальный предпринимателей первая цифра всегда равна 3.</li>
                <li>Второй и третий знаки отражают последние 2 цифры года регистрации. Так для 2021 года это будет 21.</li>
                <li>4 и 5-й знаки — номер региона. Для Санкт-Петербурга они равны 78.</li>
                <li>С 6 по 14-й знак — запись в ЕГРИП (номер).</li>
                <li>На последнем месте стоит контрольная цифра.</li>
                <p class="affril-list__activities_more">При возникновении сомнений относительно достоверности сведений, которые дал вам предприниматель, ОГРНИП можно проверить арифметическими действиями.</p>
            </ul>
        </div>
    </section>
    <section class="affril-list affril-list__ogrn">
        <div class="container">
            <div class="affril-list__name">
                <h2>Как проверить корректность ОГРН предпринимателя</h2>
                <p>Рассмотрим последовательность контрольных действий на примере. <br>
                    ОГРНИП = 314 220 119 000 137. <br>
                    3 — индивидуальный предприниматель. <br>
                    14 — 2014 год.  <br>
                    22 — Алтайский край.</p>
            </div>
            <ul>
                <li>Убираем контрольную цифру и получаем 31 422 011 900 013. Назовем это число А.</li>
                <li>Полученное число А делим на 13 (это константа для ИП). Получилось 2417077838462,538. </li>
                <li>Если получилась дробь — отбрасываем все, что после запятой, а оставшееся целое число умножаем на константу 13. У нас получится 31 422 011 900 006. Назовем этот ответ В.</li>
                <li>Далее надо подсчитать разность С = А - В. 31422011900013- 31422011900006= 7. </li>
                <li>Сравниваем полученный результат (С) с последней (контрольной) цифрой из ОГРНИП. Если совпало — ОГРНИП корректен. В вашем случае С может оказаться больше 9. Из такого числа нас интересует только вторая цифра.</li>
                <p class="affril-list__ogrn_more">Официально узнать ОГРНИП можно по ИНН предпринимателя. Если такового не найдется — значит, ваш контрагент не зарегистрирован, как ИП и вводит вас в заблуждение.</p>
            </ul>
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Ogrnip',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
