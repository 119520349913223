<template>
    <div class="inner-page">
        <div class="inner-page__title" v-if="card_ip">
            <h3>ИП {{ card_info.ФИО }}</h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Статус }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРНИП }}</p>
                </div>
            </div>
        </div>
        <div class="inner-page__title" v-else>
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="crash" v-if="total_banrotstvo != 0">
            <h1>Банкротство ({{ total_banrotstvo }})</h1>
            <div class="crash__table">
                <div class="crash__row crash__title">
                    <div>
                        <p>Категория</p>
                    </div>
                    <div>
                        <p>Дата</p>
                    </div>
                    <div>
                        <p>№ дела</p>
                    </div>
                </div>
                <div class="crash__row" v-for="item in items_bankrotstvo">
                    <div v-if='item.MessageInfo["@attributes"].MessageTypeDescription'>
                        <p>{{ item.MessageInfo["@attributes"].MessageTypeDescription }}</p>
                    </div>
                    <div>
                        <p>{{ dateUpdate(item.PublishDate) }}</p>
                    </div>
                    <div>
                        <p>{{ item.CaseNumber }}</p>
                    </div>
                </div>
            </div>
<!--             <ul class="pagination">
                <li class="active">1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
            </ul> -->
        </div>
        <div v-else>
            <h1>Банкротство</h1>
            <p>Данных нет</p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Bankrotstvo",
        directives: { mask },
        props: {
            card_info: Object,
            card_ip: {
                type: Boolean,
                default: false,
            },
        },
        components: {

        },
        data() {
            return {
                items_bankrotstvo: [],
                total_banrotstvo: 0,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            async GetBankrotstvo(inn) {
                  await axios
                  .get(`/api/v1/ooo/bankrotstvo/${inn}`)
                  .then(response => {
                        console.log(response)
                        this.items_bankrotstvo = response.data.body.data
                        this.total_banrotstvo = response.data.body.total
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            dateUpdate(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD.MM.YYYY')
              return datasort
            },  
        },
        created() {
            if (this.card_ip) {
                this.GetBankrotstvo(this.card_info.ИННФЛ)
            } else {
                this.GetBankrotstvo(this.card_info.ИНН)
            }
            
        },
        watch: {

        },        
   }
</script>