import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Main

import HomeView from '../views/HomeView.vue'

// Search

import Search from '../views/Search/Search.vue'

// Cards
import OOO from '../views/Cards/OOO.vue'
import IP from '../views/Cards/IP.vue'

// Promo

import PromoView from '../views/Promo/PromoView.vue'

// User

import MyProfile from '../views/Acc/MyProfile.vue'
import HistoryProfile from '../views/Acc/HistoryProfile.vue'
import Favorite from '../views/Acc/Favorite.vue'
import Feedback from '../views/Acc/Feedback.vue'

// Seo

import Activites from '../views/Seo/Activites.vue'
import Arbitration from '../views/Seo/Arbitration-Court.vue'
import Affiliation from '../views/Seo/Affiliation.vue'
import Director from '../views/Seo/Director.vue'
import Egrip from '../views/Seo/Egrip.vue'
import Employees from '../views/Seo/Employees.vue'
import Extract from '../views/Seo/Extract.vue'
import Founders from '../views/Seo/Founders.vue'
import Fssp from '../views/Seo/Fssp.vue'
import Ogrn from '../views/Seo/Ogrn.vue'
import Ogrnip from '../views/Seo/Ogrnip.vue'
import Phone from '../views/Seo/Phone.vue'
import HistoryOfChanges from '../views/Seo/HistoryOfChanges.vue'
import LegalAddress from '../views/Seo/LegalAddress.vue'
import Usrle from '../views/Seo/Usrle.vue'
import Reporting from '../views/Seo/Reporting.vue'
import Procurement from '../views/Seo/Procurement.vue'
import Rating from '../views/Seo/Rating.vue'
import Veriftication from '../views/Seo/Veriftication.vue'
import RealibilityCheck from '../views/Seo/ReliabilityCheck.vue'
import AboutService from '../views/Seo/AboutService.vue'
import SubsSeo from '../views/Seo/SubsSeo.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
    props: route => ({ ...route.query, ...route.params }),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/history-profile',
    name: 'HistoryProfile',
    component: HistoryProfile,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/favorite-profile',
    name: 'Favorite',
    component: Favorite,
    meta: {
      requireLogin: true,
    }
  },  
  {
    path: '/feedback-profile',
    name: 'Feedback',
    component: Feedback,
    meta: {
      requireLogin: true,
    }
  },  
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      requireLogin: true,
    }
  },  
  {
    path: '/ooo',
    name: 'OOO',
    component: OOO,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/ip',
    name: 'IP',
    component: IP,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/activites',
    name: 'Activites',
    component: Activites
  },  
  {
    path: '/director',
    name: 'Director',
    component: Director
  },  
  {
    path: '/egrip',
    name: 'Egrip',
    component: Egrip
  }, 
  {
    path: '/employees',
    name: 'Employees',
    component: Employees
  },  
  {
    path: '/extract',
    name: 'Extract',
    component: Extract
  }, 
  {
    path: '/founders',
    name: 'Founders',
    component: Founders
  }, 
  {
    path: '/fssp',
    name: 'Fssp',
    component: Fssp
  }, 
  {
    path: '/ogrn',
    name: 'Ogrn',
    component: Ogrn
  }, 
  {
    path: '/ogrnip',
    name: 'Ogrnip',
    component: Ogrnip
  }, 
  {
    path: '/phone',
    name: 'Phone',
    component: Phone
  }, 
  {
    path: '/arbitration-court',
    name: 'Arbitration',
    component: Arbitration
  },  
  {
    path: '/affiliation',
    name: 'Affiliation',
    component: Affiliation
  },  
  {
    path: '/legal_adress',
    name: 'LegalAddress',
    component: LegalAddress
  }, 
  {
    path: '/egrul',
    name: 'Usrle',
    component: Usrle
  }, 
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting
  }, 
  {
    path: '/procurement',
    name: 'Procurement',
    component: Procurement
  }, 
  {
    path: '/raiting',
    name: 'Rating',
    component: Rating
  }, 
  {
    path: '/history-of-changes',
    name: 'HistoryOfChanges',
    component: HistoryOfChanges
  }, 
  {
    path: '/vertification',
    name: 'Veriftication',
    component: Veriftication
  }, 
  {
    path: '/realibity-check',
    name: 'RealibilityCheck',
    component: RealibilityCheck
  }, 
  {
    path: '/promo',
    name: 'PromoView',
    component: PromoView
  },
  {
    path: '/about-service',
    name: 'AboutService',
    component: AboutService
  },
  {
    path: '/subs-seo',
    name: 'SubsSeo',
    component: SubsSeo
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'HomeView' });
  } else {
    next()
  }
})

export default router
