<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__ver">
        <div class="container">
            <h1>Проверка работодателя</h1>
            <p>С поиском работы, так или иначе, сталкивался практически каждый гражданин работоспособного возраста. Критерии, по которым мы выбираем новое место работы, могут отличаться, но размер заработной платы и комфортные условия труда для всех имеют решающее значение.</p>
        </div>
    </section>
    <div class="ceo-ver">
        <div class="container">
            <img src="@/assets/img/ceo-ver.png" alt="">
            <p>Если вы уже нашли потенциального работодателя, и вас пригласили на собеседование, заранее проверьте надежность компании, чтобы зря не терять времени на общение с организацией, которая не оправдает ваших надежд уже через месяц испытательного срока.</p>
            <h2>Первые шаги вы можете сделать самостоятельно</h2>
            <ul>
                <li>Для начала попросите у представителя, с которым контактируете на предмет трудоустройства, ИНН или ОГРН. Если вам отказали — на собеседование можно не ходить.</li>
                <li>Далее посмотрите в сети Интернет отзывы о компании. </li>
            </ul>
        </div>
    </div>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Здесь надо понимать, что придавать особого значения единичным оценкам не стоит. Фиктивные положительные отзывы могут писать сами представители руководства.</p>
            <p>Отрицательные часто пишут обиженные сотрудники, уволенные за нерадивость. Но наличие десятка плохих оценок, в которых освещаются одни и те же проблемы (как правило, это жалобы на обман относительно заработной платы) должно вдохновить вас на поиск другого работодателя.</p>
        </div>
    </section>
    <section class="ceo-error">
        <div class="container">
            <div class="ceo-error__wrapper">
                <p>Если в компании вам ставят условие, в результате которого придется за что-либо заплатить — уходите немедленно. Это мошенники. И совершенно неважно, по какой причине вас вынуждают достать кошелек — оплата анкеты, обучения или «страховой» взнос.</p>
            </div>
        </div>
    </section>
    <section class="affril-list affril-list__ver">
        <div class="container">
            <div class="affril-list__name">
                <p>Когда самостоятельные шаги дали положительный результат, начните проверку с помощью нашего сервиса.</p>
                <p>Введите в окно поиска ИНН или ОГРН и получите карточку компании. В ней вам надо проанализировать критерии:</p>
            </div>
            <ul>
                <li>Статус «Действующая» говорит о том, что никаких подозрительных изменений с компанией не происходит.</li>
                <li>Виды деятельности должны по смыслу подходить к той работе, о которой вам рассказали при знакомстве.</li>
                <li>Если фирма находится в специальном реестре Федеральной налоговой службы — с ней что-то не так. Отсутствие связи по юридическому адресу тоже не сулит ничего хорошего.</li>
                <li>Срок существования организации меньше года — не стоит тратить на нее время. Специфика бизнеса в РФ такова, что одна из трех новых фирм перестает работать через год.</li>
                <li>В разделе «Арбитраж» показана интересная информация о конфликтах компании, доведенных до суда. Посмотрите, нет ли дел, переданных в исполнительное производство. Если подана заявка на банкротство — это не ваша компания. Ищите другую.</li>
                <li>Количество сотрудников и средняя заработная плата расскажут о масштабах компании и отсутствии «серой» зарплаты. Помните — обещания давать заработок в конверте не гарантируют, что вы будете получать обещанное. Не соглашайтесь работать без официального оформления.</li>
                <li>Обязательно должна быть регистрация в пенсионном фонде.</li>
                <li>Ознакомьтесь с финансовыми показателями. Если они показывают убыток, вы можете остаться без зарплаты и будете годами судиться с работодателем.</li>
            </ul>
        </div>
    </section>
    <section class="ceo-prev ceo-prev__ver">
        <div class="container">
            <div class="ceo-prev__info">
                <p>Более полную информацию о работодателе можно получить через «Премиум доступ».</p>
                <p>Хорошая работа — это та, на которую вы ходите с удовольствием и получаете за свой труд достойную оплату. Будьте осмотрительны, и любимая работа непременно найдет вас с помощью нашего сервиса.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <img src="@/assets/img/ceo-prev_ver.jpg" alt="">
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Veriftication',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
