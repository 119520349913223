<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title">
        <div class="container">
            <h1>Виды деятельности</h1>
            <p>Все виды деятельности имеют многоуровневые коды, систематизированные в ОКВЭД — Общероссийском Классификаторе Видов Экономической Деятельности.</p>
        </div>
    </section>
    <section class="ceo-activities">
        <div class="container">
            <div class="ceo-activities__info">
                <h2>ОКВЭД</h2>
                <p>При регистрации компания указывает, прежде всего, основной вид деятельности и подтверждает его ежегодно. Подтверждение подается в Фонд социального страхования для определения тарифа по взносам на травматизм.</p>
                <p>Кроме основного вида можно указать дополнительные виды деятельности, которые имеют отражение в выписке из ЕГРЮЛ.</p>
                <p>Для подачи заявки в ФНС организация или ИП самостоятельно подбирает коды по классификатору.</p>
            </div>
        </div>
    </section>
    <section class="affril-list affril-list__activities">
        <div class="container">
            <div class="affril-list__name">
                <h2>Зачем проверять виды деятельности организации</h2>
                <p>Проверяя организацию или индивидуального предпринимателя, уделите внимание видам его экономической деятельности, указанной в выписке из ЕГРЮЛ. Работать с контрагентом в той сфере, которая не значится в его выписке, не стоит.</p>
            </div>
            <ul>
                <li>Есть риск, что ваш контрагент не что иное, как организация-однодневка.</li>
                <li>Новая для вас компания, предлагая сотрудничать в области, которая не значится в выписке из ЕГРЮЛ, скорее всего не имеет достаточных компетенций, может провалить полученную работу или выполнит ее некачественно.</li>
                <li>К некоторым видам деятельности государство относится крайне внимательно. Если вы произведете оплату организации за услуги/товары, которые она не вправе оказывать/торговать, ждите неприятностей от контролирующих органов. Это могут быть медицинские услуги, производство и поставка лекарств, монтаж и обслуживание средств противопожарной охраны и т. д.</li>
                <p class="affril-list__activities_more">Законодательством определено более 50 видов деятельности, подлежащих лицензированию.</p>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <h2>Проверка лицензии</h2>
            <p>Надо знать, что деятельность, связанная с потенциальной опасностью для жизни и здоровья или относящаяся к сфере безопасности государства, лицензируется в обязательном порядке.  И лицензии надо проверять!</p>
            <p>Так, например, поручать транспортной компании перевозку детей без уверенности, что она имеет на это право, нельзя ни в коем случае. При возникновении ДТП правоохранительные органы будут выяснять правомочность контрагента на перевозку. При отсутствии лицензии спросят не только с перевозчика, но и с вас.</p>
        </div>
    </section>
    </div>

    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Activites',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
