<template>
    <div class="inner-page">
        <div class="inner-page__title" v-if="card_ip">
            <h3>ИП {{ card_info.ФИО }}</h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Статус }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРНИП }}</p>
                </div>
            </div>
        </div>
        <div class="inner-page__title" v-else>
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="license" v-if="total_licenses != 0">
            <h1>Лицензии</h1>
            <ul class="license__list">
                <li class="active">Все ({{ total_licenses }})</li>
            </ul>
            <div class="license__wrapper">
                <div class="license__item" v-for="list in items_licenses">
                    <div class="license__name">
                        <p v-if="list['Номер лицензии']">№ {{ list['Номер лицензии'] }}</p>
                        <div class="license__green">{{ list.Источник }}</div>
                    </div>
                    <ul>
                        <li v-if="list['Организация выдавшая лицензию']">
                            <p>Организация выдавшая лицензию: <span>{{ list['Организация выдавшая лицензию'] }}</span></p>
                        </li>
                        <li v-if="list['Наименование лицензии']">
                            <p>Наименование лицензии: <span>{{ list['Наименование лицензии'] }}</span></p>
                        </li>
                        <li v-if="list['Дата начала лицензии']">
                            <p>Дата начала лицензии: <span>{{ list['Дата начала лицензии'] }}</span></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else>
            <h1>Лицензии</h1>
            <p>Данных нет</p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Licenses",
        directives: { mask },
        props: {
            card_info: Object,
            items_licenses: {
                type: Array,
                default: [],
            },
            total_licenses: {
                type: Number,
                default: 0,
            },
            card_ip: {
                type: Boolean,
                default: false,
            },
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {

        },
        created() {
            console.log(this.items_licenses)
        },
        watch: {

        },        
   }
</script>