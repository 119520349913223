<template>
    <div class="inner-page" v-if="card_info.НаимЮЛСокр">
<!--         <div class="inner-page__link">
            <a href="#">
                <img src="@/assets/icons/main-lcc_link_1.svg" alt="">
            </a>
            <div>
                <img src="@/assets/icons/main-lcc_link_2.svg" alt="">
                <div class="inner-page__soc">
                    <a href="#">
                        <img src="@/assets/icons/main-llc_soc_1.svg" alt="">
                    </a>
                    <a href="#">
                        <img src="@/assets/icons/main-llc_soc_2.svg" alt="">
                    </a>
                    <a href="#">
                        <img src="@/assets/icons/main-llc_soc_3.svg" alt="">
                    </a>
                </div>
            </div>
            <a href="#">
                <img src="@/assets/icons/main-lcc_link_3.svg" alt="">
            </a>
        </div> -->
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="reliab" v-if="Number(success.length + warning.length + danger.length) != 0">
            <div class="reliab__title">
                <h1>Надежность</h1>
                <div class="">
                    <img src="@/assets/icons/ver.svg" alt="">
                    <p>Высокая</p>
                </div>
            </div>
            <ul class="reliab__tab">
                <li 
                    @click="Tag(0)"
                    :class="{'active': tag == 0}"
                >   
                    Все ({{ success.length + warning.length + danger.length }})
                </li>
                <li 
                    v-if="success.length != 0" 
                    @click="Tag(1)"
                    :class="{'active': tag == 1}"
                >
                    Положительные ({{ success.length }})
                </li>
                <li 
                    v-if="warning.length != 0"
                    @click="Tag(2)"
                    :class="{'active': tag == 2}"                    
                >
                    Нейтральные ({{ warning.length }})
                </li>
                <li 
                    v-if="danger.length != 0"
                    @click="Tag(3)"
                    :class="{'active': tag == 3}" 
                >   
                    Отрицательные ({{ danger.length }})
                </li>
            </ul>
            <div class="reliab__wrapper">
                <div class="reliab__block" v-show="success.length != 0" v-if="tag == 1 || tag == 0">
                    <h3>Положительные факты</h3>
                    <div class="reliab__grid">
                        <div class="reliab__item" v-for="list in success">
                            <div class="reliab__name">
                                <div class="">
                                    <img src="@/assets/icons/reliab_check.svg" alt="">
                                    <p class="bold">{{ list.name }}</p>
                                </div>
                                <p>{{ list.value }}</p>
                            </div>
                            <div class="reliab__info">
                                <p>{{ list.desc }}.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reliab__block" v-show="warning.length != 0" v-if="tag == 2 || tag == 0">
                    <h3>Нейтральные факты</h3>
                    <div class="reliab__grid">
                        <div class="reliab__item" v-for="list in warning">
                            <div class="reliab__name">
                                <div class="">
                                    <img src="@/assets/icons/reliab_warning.svg" alt="">
                                    <p class="bold">{{ list.name }}</p>
                                </div>
                                <p>{{ list.value }}</p>
                            </div>
                            <div class="reliab__info">
                                <p>{{ list.desc }}.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reliab__block" v-show="danger.length != 0" v-if="tag == 3 || tag == 0">
                    <h3>Отрицательные факты</h3>
                    <div class="reliab__grid">
                        <div class="reliab__item" v-for="list in danger">
                            <div class="reliab__name">
                                <div class="">
                                    <img src="@/assets/icons/reliab_error.svg" alt="">
                                    <p class="bold">{{ list.name }}</p>
                                </div>
                                <p>{{ list.value }}</p>
                            </div>
                            <div class="reliab__info">
                                <p>{{ list.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="reliab">
            <h1>Надежность</h1>
            <p>Данных нет</p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';
    
    export default {
        name: "Nadejnost",
        directives: { mask },
        props: {
            card_info: Object,
            success: Array,
            danger: Array,
            warning: Array,
        },
        components: {

        },
        data() {
            return {
                total: 0,
                tag: 0,
            }
        },
        beforeCreate() {
            this.$store.commit('initializeStore')
        },
        mounted() {
        },
        methods: {
            Tag(value) {
                this.tag = value
            }
        },
        computed: {

        },
        watch: {

        },
        created() {

        }
   }
</script>