<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__fssp">
        <div class="container">
            <h1>ФССП</h1>
            <p>Федеральная Служба Судебных Приставов исполняет решения российских судов. Работа службы регламентирована законами ФЗ № 118 «О судебных приставах» и ФЗ № 229 «Об исполнительном производстве». К обязанностям ФССП относится и надзор за деятельностью коллекторских агентств.</p>
        </div>
    </section>
    <div class="ceo-info ceo-info__register">
        <div class="container">
            <div class="ceo-info__text">
                <p>Проверяя контрагента, непременно уделите внимание информации о наличии производств о взыскании задолженности с юридического лица.</p>
                <p>Вам будет предоставлена открытая информация о предмете, сумме задолженности и реквизитах исполнительного документа. Для запуска проверки надо знать ИНН/ОГРН, название или адрес организации.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <div class="ceo-info__img">
                <img src="@/assets/img/ceo-info_fssp.svg" alt="">
            </div>
        </div>
    </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Fssp',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
