<template>
    <ul class="main-llc__tab">
        <li @click="SetValue(0)" :class="{'active': tab_current == 0}">Общие данные</li>
        <li @click="SetValue(1)" :class="{'active': tab_current == 1}">Арбитражные дела</li>
        <li @click="SetValue(2)" :class="{'active': tab_current == 2}">Госзакупки</li>
        <li @click="SetValue(3)" :class="{'active': tab_current == 3}">Лицензии</li>
        <li @click="SetValue(4)" :class="{'active': tab_current == 4}">Проверки</li>
        <li @click="SetValue(5)" :class="{'active': tab_current == 5}">Банкротство</li>
    </ul>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import moment from 'moment';

    export default {
        name: "NavigationMenu",
        props: {
            tab_current: Number,
        },
        components: {

        },
        data() {
            return {
                block: false,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            SetValue(value) {
                this.$emit('value', value)
            },
            OpenAll() {
                this.block = !this.block
            },
        },
        created() {

        },
        watch: {

        },
   }
</script>