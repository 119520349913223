<template>
    <div class="main-llc__wrapper" v-if="!loader && card_info.Адрес">
        <div class="main-llc__col">
            <div class="main-llc__name">
                <h1 v-html='card_info.НаимЮЛСокр'></h1>
                <div class="main-llc__name_row">
                    <div class="info__block">{{ card_info.Активность }}</div>
                    <div class="main-llc__name_text">
                        <p>Дата создания</p>
                        <p>{{ card_info.ДатаОГРН }}</p>
                    </div>
                    <div class="main-llc__name_link">
<!--                         <a href="#">
                            <img src="@/assets/icons/main-lcc_link_1.svg" alt="">
                        </a> -->
<!--                                 <div class="active">
                            <img src="@/assets/icons/main-lcc_link_2.svg" alt="">
                            <div class="main-llc__name_soc">
                                <a href="#">
                                    <img src="@/assets/icons/main-llc_soc_1.svg" alt="">
                                </a>
                                <a href="#">
                                    <img src="@/assets/icons/main-llc_soc_2.svg" alt="">
                                </a>
                                <a href="#">
                                    <img src="@/assets/icons/main-llc_soc_3.svg" alt="">
                                </a>
                            </div>
                        </div> -->
                        <a v-if="status == 'error'" class="pointer" @click="AddFavorite(card_info, project_id)">
                            <img src="@/assets/icons/main-lcc_link_3.svg" alt="">
                        </a>
                        <a class="pointer" @click="UnFav()" v-else>
                            <img src="@/assets/icons/fav_active.svg" alt="">
                        </a>
                    </div>
                </div>
                <div class="main-llc__name_wrapper">
                    <div class="main-llc__name_col">
                        <div class="main-llc__name_item">
                            <p class="bold"><img src="@/assets/icons/llc_name_block_1.svg" alt="">Основные реквизиты</p>
                            <div class="main-llc__name_har">
                                <div class="">
                                    <p>ИНН</p>
                                    <p v-html='card_info.ИНН'></p>
                                </div>
                                <div class="">
                                    <p>КПП</p>
                                    <p v-html='card_info.КПП'></p>
                                </div>
                                <div class="">
                                    <p>ОГРН</p>
                                    <p v-html='card_info.ОГРН'></p>
                                </div>
                            </div>
                            <a @click="RedirectToTab(12)" class="main-llc__name_more main-llc__name_more_link">Все реквизиты</a>
                        </div>
                        <div class="main-llc__name_item">
                            <p class="bold">Коды статистики</p>
                            <div class="main-llc__name_har">
                                <div class="">
                                    <p>ОКАТО</p>
                                    <p v-html='card_info.ОКАТО'></p>
                                </div>
                                <div class="">
                                    <p>ОКОГУ</p>
                                    <p v-html='card_info.ОКОГУ'></p>
                                </div>
                                <div class="">
                                    <p>ОКОПФ</p>
                                    <p v-html='card_info.ОКОПФ'></p>
                                </div>
                            </div>
<!--                                     <a href="#" class="main-llc__name_more">Показать еще</a> -->
                        </div>
                        <div class="main-llc__name_item">
                            <p class="bold"><img src="@/assets/icons/llc_name_block_2.svg" alt="">Юридический адрес</p>
                            <p v-html='card_info.Адрес'></p>
                        </div>
                        <div class="main-llc__name_item" v-if="card_info.ДобавлениеДанных.phone || card_info.ДобавлениеДанных.email">
                            <p class="bold"><img src="@/assets/icons/llc_name_block_3.svg" alt="">Контакты</p>
                            <div class="main-llc__name_har">
                                <div v-if="card_info.ДобавлениеДанных.phone">
                                    <p>Телефон</p>
                                    <p v-html='card_info.ДобавлениеДанных.phone'></p>
                                </div>
                                <div v-if="card_info.ДобавлениеДанных.email">
                                    <p>E-mail</p>
                                    <p v-html='card_info.ДобавлениеДанных.email'></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-llc__name_col">
                        <div class="main-llc__name_item">
                            <p class="bold"><img src="@/assets/icons/llc_name_block_4.svg" alt="">Руководитель</p>
                            <div class="main-llc__name_info" v-if="card_info.Руководители">
                                <p>{{ card_info.Руководители[0].post }}</p>
                                <p><span>{{ card_info.Руководители[0].fl }}</span> <br> с {{ card_info.Руководители[0].date }}</p>
                            </div>
                            <div class="main-llc__name_har" v-if="card_info.Руководители">
                                <div class="">
                                    <p>ИНН</p>
                                    <p>{{ card_info.Руководители[0].inn }}</p>
                                </div>
                            </div>
                            <a @click="RedirectToTab(15)" class="main-llc__name_more main-llc__name_more_link">Все руководители</a>
                        </div>
                        <div class="main-llc__name_item" v-if="card_info.НаимОКВЭД || card_info.КодОКВЭД">
                            <p class="bold">Основной вид деятельности</p>
                            <p>{{ card_info.НаимОКВЭД }} <span>({{ card_info.КодОКВЭД }})</span></p>
                            <a v-if="card_info.СвОКВЭДДоп" @click="RedirectToTab(8)" class="main-llc__name_more main-llc__name_more_link">Все виды деятельности ({{ card_info.СвОКВЭДДоп.length }})</a>
                        </div>
                        <div class="main-llc__name_item" v-else>
                            <p class="bold">Основной вид деятельности</p>
                            <p>Данные отсутствуют</p>
                        </div>
                        <div class="main-llc__name_item" v-if="card_info.ЧислСотруд">
                            <p class="bold"><img src="@/assets/icons/llc_name_block_2.svg" alt="">Среднесписочная численность</p>
                            <div class="main-llc__name_up">
                                <p>{{ card_info.ЧислСотруд }}</p>
<!--                                 <p>15</p> -->
                            </div>
                        </div>
                        <div class="main-llc__name_item" v-if="card_info.КатСубМСП">
                            <p class="bold">Реестр МСП</p>
                            <p>{{ card_info.КатСубМСП['1'] }}</p>
                        </div>
                        <div class="main-llc__name_item" v-if="card_info.НаимНО">
                            <p class="bold">Налоговый орган</p>
                            <p>{{ card_info.НаимНО }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="llc-star main-llc__mob">
                <h4>Репутация компании</h4>
                <div class="llc-star__wrapper" v-if="zvezdi == 5">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star_full.svg" alt="">
                </div>
                <div class="llc-star__wrapper" v-if="zvezdi == 3">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star.svg" alt="">
                    <img src="@/assets/icons/star.svg" alt="">
                </div>
                <div class="llc-star__wrapper" v-if="zvezdi == 1">
                    <img src="@/assets/icons/star_full.svg" alt="">
                    <img src="@/assets/icons/star.svg" alt="">
                    <img src="@/assets/icons/star.svg" alt="">
                    <img src="@/assets/icons/star.svg" alt="">
                    <img src="@/assets/icons/star.svg" alt="">
                </div>
            </div>
            <div class="llc-reliab main-llc__mob">
                <h4>Надежность</h4>
                <div class="info__block" v-if="success.length != 0 || warning.length != 0 || danger.length != 0">Высокая</div>
                <div class="llc-reliab__wrapper" v-if="success.length != 0 || warning.length != 0 || danger.length != 0">
                  <svg viewBox="0 0 30 30">
                    <circle class="unit" r="12" cx="50%" cy="50%" stroke-dasharray="75.36" 
                    :stroke-dashoffset="FormulaProcent(success.length)"></circle>
                    <circle class="unit" r="12" cx="50%" cy="50%" stroke-dasharray="75.36" 
                    :stroke-dashoffset="FormulaProcent(warning.length)"></circle>
                    <circle class="unit" r="12" cx="50%" cy="50%" stroke-dasharray="75.36" 
                    :stroke-dashoffset="FormulaProcent(danger.length)"></circle>
                  </svg>
                  <p>{{ success.length + warning.length + danger.length }}</p>
                </div>
                <p>Выявлено {{ success.length + warning.length + danger.length }} фактов</p>
                <ul>
                    <li v-if="success.length != 0">Положительные: <span>{{ success.length }}</span></li>
                    <li v-if="warning.length != 0">Нейтральные: <span>{{ warning.length }}</span></li>
                    <li v-if="danger.length != 0">Отрицательные: <span>{{ danger.length }}</span></li>
                </ul>
            </div>
            <div class="llc-pdf main-llc__mob">
                <a :href="`https://zachestnyibiznesapi.ru/paid/data/fns-card-report-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item pointer">
                    <img src="@/assets/icons/pdf.svg" alt="">
                    <p class="bold">Выписка из ЕГРЮЛ</p>
                    <p>С подписью ФНС / на сегодняшнюю дату</p>
                </a>
                <a :href="`https://zachestnyibiznesapi.ru/paid/data/company-fns-requirements-info?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                    <img src="@/assets/icons/pdf.svg" alt="">
                    <p class="bold">Бухгалтерская отчетность</p>
                    <p>С подписью ФНС (официально)</p>
                </a>
                <a :href="`https://zachestnyibiznesapi.ru/paid/data/pdo-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                    <img src="@/assets/icons/pdf.svg" alt="">
                    <p class="bold">Отчет Должная осмотрительность</p>
                    <p>Отчет о рисках по нормативам ФНС с рекомендациями по сбору документов</p>
                </a>
            </div>
            <div class="llc-stat main-llc__mob">
                <h4>Основная статистика, ₽</h4>
                <div class="llc-stat__wrapper">
                    <div class="llc-stat__item" v-if="card_info.СумКап">
                        <p>уставный капитал</p>
                        <p>{{ toLocateSum(card_info.СумКап) }}</p>
                    </div>
                    <div class="llc-stat__item" v-if="card_info.ФондОплТруда">
                        <p>фонд оплаты труда</p>
                        <p>{{ toLocateSum(card_info.ФондОплТруда) }}</p>
                    </div>
                </div>
            </div>
            <div class="llc-arb main-llc__mob">
                <h4>Арбитражные дела</h4>
                <p>Найдено <span>{{ total_arbitaj }}</span></p>
                <ul class="llc-arb__list">
                    <li class="active">Все ({{ total_arbitaj }})</li>
                </ul>
                <div class="llc-arb__wrapper">
                    <div class="llc-arb__item" v-if="total_arbitaj != 0" v-for="(item, index) in items_arbitaj">
                        <div class="llc-arb__name">
                            <div class="">
                                <p class="bold">№ {{ item.НомерДела }}</p>
                                <p class="reg">от {{ item.СтартДата }}</p>
                            </div>
                        </div>
                        <ul>
                            <li v-if="item.СуммаИска">
                                <span>Сумма иска, руб:</span> {{ toLocateSum(item.СуммаИска) }} ₽
                            </li>
                            <li v-if="item.Истец">
                                <span>Истец:</span> {{ item.Истец[0].Наименование }}
                            </li>
                            <li v-if="item.Ответчик">
                                <span>Ответчик:</span> {{ item.Ответчик[0].Наименование }}
                            </li>
                            <li v-if='item["Иной участник"]'>
                                <span>Иной участник:</span> {{ item["Иной участник"][0].Наименование }}
                            </li>
                            <li v-if='item["Третье лицо"]'>
                                <span>Третье лицо:</span> {{ item["Третье лицо"][0].Наименование }}
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <p>Данных нет</p>
                    </div>
                </div>
                <a @click="RedirectToTab(3)" class="llc-arb__more">Смотреть все дела</a>
            </div>
            <div class="llc-grah">
                <ul class="llc-grah__tab">
                    <li @click="TabChooseGraph(0)" :class="{'active': tab__graph == 0}">Выручка</li>
                    <li @click="TabChooseGraph(1)" :class="{'active': tab__graph == 1}">Прибыль</li>
                    <li @click="TabChooseGraph(2)" :class="{'active': tab__graph == 2}">Стоимость</li>
                </ul>
                <div class="llc-grah__wrapper" v-if="tab__graph == 0">
                    <div class="row-grah__info">
                        <p>2020</p>
                        <h4 v-if="card_info.ФО2020.ВЫРУЧКА">{{ toLocateSum(card_info.ФО2020.ВЫРУЧКА) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info">
                        <p>2019</p>
                        <h4 v-if="card_info.ФО2019.ВЫРУЧКА">{{ toLocateSum(card_info.ФО2019.ВЫРУЧКА) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info">
                        <p>2018</p>
                        <h4 v-if="card_info.ФО2018.ВЫРУЧКА">{{ toLocateSum(card_info.ФО2018.ВЫРУЧКА) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info" v-if="card_info.ФО2017.ВЫРУЧКА">
                        <p>2017</p>
                        <h4>{{ toLocateSum(card_info.ФО2017.ВЫРУЧКА) }}  ₽</h4>
                    </div>
                    <div class="row-grah__info" v-if="card_info.ФО2016.ВЫРУЧКА">
                        <p>2016</p>
                        <h4>{{ toLocateSum(card_info.ФО2016.ВЫРУЧКА) }} ₽</h4>
                    </div>
                </div>
                <div class="llc-grah__wrapper" v-if="tab__graph == 1">
                    <div class="row-grah__info">
                        <p>2020</p>
                        <h4 v-if="card_info.ФО2020.ПРИБЫЛЬ">{{ toLocateSum(card_info.ФО2020.ПРИБЫЛЬ) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info">
                        <p>2019</p>
                        <h4 v-if="card_info.ФО2019.ПРИБЫЛЬ">{{ toLocateSum(card_info.ФО2019.ПРИБЫЛЬ) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info">
                        <p>2018</p>
                        <h4 v-if="card_info.ФО2018.ПРИБЫЛЬ">{{ toLocateSum(card_info.ФО2018.ПРИБЫЛЬ) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info" v-if="card_info.ФО2017.ПРИБЫЛЬ">
                        <p>2017</p>
                        <h4>{{ toLocateSum(card_info.ФО2017.ПРИБЫЛЬ) }}  ₽</h4>
                    </div>
                    <div class="row-grah__info" v-if="card_info.ФО2016.ПРИБЫЛЬ">
                        <p>2016</p>
                        <h4>{{ toLocateSum(card_info.ФО2016.ПРИБЫЛЬ) }} ₽</h4>
                    </div>
                </div>
                <div class="llc-grah__wrapper" v-if="tab__graph == 2">
                    <div class="row-grah__info">
                        <p>2020</p>
                        <h4 v-if="card_info.ФО2020.ОСНСРЕДСТВА">{{ toLocateSum(card_info.ФО2020.ОСНСРЕДСТВА) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info">
                        <p>2019</p>
                        <h4 v-if="card_info.ФО2019.ОСНСРЕДСТВА">{{ toLocateSum(card_info.ФО2019.ОСНСРЕДСТВА) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info">
                        <p>2018</p>
                        <h4 v-if="card_info.ФО2018.ОСНСРЕДСТВА">{{ toLocateSum(card_info.ФО2018.ОСНСРЕДСТВА) }} ₽</h4>
                        <h4 v-else> - </h4>
                    </div>
                    <div class="row-grah__info" v-if="card_info.ФО2017.ОСНСРЕДСТВА">
                        <p>2017</p>
                        <h4>{{ toLocateSum(card_info.ФО2017.ОСНСРЕДСТВА) }}  ₽</h4>
                    </div>
                    <div class="row-grah__info" v-if="card_info.ФО2016.ОСНСРЕДСТВА">
                        <p>2016</p>
                        <h4>{{ toLocateSum(card_info.ФО2016.ОСНСРЕДСТВА) }} ₽</h4>
                    </div>
                </div>
            </div>
            <div class="llc-procur" v-if="total_partipant != 0 || total_partipant != 0">
                <h4>Госзакупки</h4>
                <p>Найдено <span>{{ total_customer + total_partipant }}</span> контрактов</p>
                <ul class="llc-grah__tab">
                    <li @click="TabChooseGoszakypki(0)" 
                        :class="{'active': tab_zakupki == 0}">
                        Поставщик ({{ total_partipant }})
                    </li>
                    <li 
                        @click="TabChooseGoszakypki(1)"
                        :class="{'active': tab_zakupki == 1}"
                    >
                        Заказчик ({{ total_customer }})
                    </li>
                </ul>
                <div class="llc-procur__wrapper">
                    <div class="llc-procur__item" v-show="tab_zakupki == 0" v-for="list in items_participant.slice(0, 4)">
                        <div class="">
                            <p class="reg">{{ list.participantFullName }}</p>
                            <p class="bold_s">{{ list.count }} <span>контрактов на сумму</span> {{ toLocateSum(list.sum) }} ₽</p>
                        </div>
                    </div>
                    <div class="llc-procur__item" v-show="tab_zakupki == 1" v-for="list in items_customer.slice(4, 8)">
                        <div class="">
                            <p class="reg">{{ list.customerFullName }}</p>
                            <p class="bold_s">{{ list.count }} <span>контрактов на сумму</span> {{ toLocateSum(list.sum) }} ₽</p>
                        </div>
                    </div>
                </div>
                <a @click="RedirectToTab(4)" class="llc-procur__more pointer">Смотреть все контракты</a>
            </div>
            <div class="llc-procur" v-else>
                <h4>Госзакупки</h4>
                <p>Найдено <span>0</span> контрактов</p>                
            </div>
        </div>
        <div class="main-llc__col">
            <div class="main-llc__block">
                <div class="main-llc__col">
                    <div class="llc-star main-llc__desktop">
                        <h4>Репутация компании</h4>
                        <div class="llc-star__wrapper" v-if="zvezdi == 5">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star_full.svg" alt="">
                        </div>
                        <div class="llc-star__wrapper" v-if="zvezdi == 3">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star.svg" alt="">
                            <img src="@/assets/icons/star.svg" alt="">
                        </div>
                        <div class="llc-star__wrapper" v-if="zvezdi == 1">
                            <img src="@/assets/icons/star_full.svg" alt="">
                            <img src="@/assets/icons/star.svg" alt="">
                            <img src="@/assets/icons/star.svg" alt="">
                            <img src="@/assets/icons/star.svg" alt="">
                            <img src="@/assets/icons/star.svg" alt="">
                        </div>
                    </div>
                    <div class="llc-reliab main-llc__desktop">
                        <h4>Надежность</h4>
                        <div class="info__block" v-if="success.length != 0 || warning.length != 0 || danger.length != 0">Высокая</div>
                        <div class="llc-reliab__wrapper" v-if="success.length != 0 || warning.length != 0 || danger.length != 0">
                          <svg viewBox="0 0 30 30">
                            <circle class="unit" r="12" cx="50%" cy="50%" stroke-dasharray="75.36" 
                            :stroke-dashoffset="FormulaProcent(success.length)"></circle>
                            <circle class="unit" r="12" cx="50%" cy="50%" stroke-dasharray="75.36" 
                            :stroke-dashoffset="FormulaProcent(warning.length)"></circle>
                            <circle class="unit" r="12" cx="50%" cy="50%" stroke-dasharray="75.36" 
                            :stroke-dashoffset="FormulaProcent(danger.length)"></circle>
                          </svg>
                          <p>{{ success.length + warning.length + danger.length }}</p>
                        </div>
                        <p>Выявлено {{ success.length + warning.length + danger.length }} фактов</p>
                        <ul>
                            <li v-if="success.length != 0">Положительные: <span>{{ success.length }}</span></li>
                            <li v-if="warning.length != 0">Нейтральные: <span>{{ warning.length }}</span></li>
                            <li v-if="danger.length != 0">Отрицательные: <span>{{ danger.length }}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="main-llc__col">
                    <div class="llc-pdf main-llc__desktop">
                        <a :href="`https://zachestnyibiznesapi.ru/paid/data/fns-card-report-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item pointer">
                            <img src="@/assets/icons/pdf.svg" alt="">
                            <p class="bold">Выписка из ЕГРЮЛ</p>
                            <p>С подписью ФНС / на сегодняшнюю дату</p>
                        </a>
                        <a :href="`https://zachestnyibiznesapi.ru/paid/data/company-fns-requirements-info?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                            <img src="@/assets/icons/pdf.svg" alt="">
                            <p class="bold">Бухгалтерская отчетность</p>
                            <p>С подписью ФНС (официально)</p>
                        </a>
                        <a :href="`https://zachestnyibiznesapi.ru/paid/data/pdo-pdf?api_key=NtuN5v2UdWqMuiBTcD5hK4NoImiOb1wo&id=${project_id}`" target="_blank" class="llc-pdf__item">
                            <img src="@/assets/icons/pdf.svg" alt="">
                            <p class="bold">Отчет Должная осмотрительность</p>
                            <p>Отчет о рисках по нормативам ФНС с рекомендациями по сбору документов</p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="llc-stat main-llc__desktop">
                <h4>Основная статистика, ₽</h4>
                <div class="llc-stat__wrapper">
                    <div class="llc-stat__item" v-if="card_info.СумКап">
                        <p>уставный капитал</p>
                        <p>{{ toLocateSum(card_info.СумКап) }}</p>
                    </div>
                    <div class="llc-stat__item" v-if="card_info.ФондОплТруда">
                        <p>фонд оплаты труда</p>
                        <p>{{ toLocateSum(card_info.ФондОплТруда) }}</p>
                    </div>
                </div>
            </div>
            <div class="llc-arb main-llc__desktop">
                <h4>Арбитражные дела</h4>
                <p>Найдено <span>{{ total_arbitaj }}</span></p>
                <ul class="llc-arb__list">
                    <li class="active">Все ({{ total_arbitaj }})</li>
                </ul>
                <div class="llc-arb__wrapper">
                    <div class="llc-arb__item" v-if="total_arbitaj != 0" v-for="(item, index) in items_arbitaj">
                        <div class="llc-arb__name">
                            <div class="">
                                <p class="bold">№ {{ item.НомерДела }}</p>
                                <p class="reg">от {{ item.СтартДата }}</p>
                            </div>
                        </div>
                        <ul>
                            <li v-if="item.СуммаИска">
                                <span>Сумма иска, руб:</span> {{ toLocateSum(item.СуммаИска) }} ₽
                            </li>
                            <li v-if="item.Истец">
                                <span>Истец:</span> {{ item.Истец[0].Наименование }}
                            </li>
                            <li v-if="item.Ответчик">
                                <span>Ответчик:</span> {{ item.Ответчик[0].Наименование }}
                            </li>
                            <li v-if='item["Иной участник"]'>
                                <span>Иной участник:</span> {{ item["Иной участник"][0].Наименование }}
                            </li>
                            <li v-if='item["Третье лицо"]'>
                                <span>Третье лицо:</span> {{ item["Третье лицо"][0].Наименование }}
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <p>Данных нет</p>
                    </div>
                </div>
                <a v-if="total_arbitaj != 0" @click="RedirectToTab(3)" class="llc-arb__more">Смотреть все дела</a>
            </div>
        </div>
        <div class="main-llc__col">
            <div class="main-llc__block_var">
                <div class="main-llc__col">
                    <div class="llc-founder">
                        <h4>Учредители ({{ card_info.СвУчредит.all.length }})</h4>
                        <div class="llc-founder__title">
                            <p>Уставный капитал <span>{{ card_info.СвУчредит.sumCap }} ₽</span></p>
                            <p>Согласно данным ЕГРЮЛ учредителями являются <span>{{ card_info.СвУчредит.all.length }}</span> юридических лица</p>
                        </div>
                        <div class="llc-founder__item" v-if="card_info.СвУчредит.all.length != 0" v-for="item in card_info.СвУчредит.all">
                            <div class="llc-founder__line">
                                <span :style="{'width': ComputedProcent(item.dol_abs) + '%'}"></span>
                            </div>
                            <p class="bold">{{ item.fl_aff }}</p>
                            <p>с {{ item.date }}</p>
                            <ul>
                                <li>
                                    <span>Доля</span>
                                    <p>{{ item.dol_abs }}</p>
                                    <div class="llc-founder__proc">{{ ComputedProcent(item.dol_abs) }}%</div>
                                </li>
                                <li>
                                    <span>ИНН</span>
                                    <p>{{ item.inn }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
<!--                     <div class="llc-com" v-if="items_svyaz.length == 0">
                        <h4>Связи</h4>
                        <p>Данных нет</p>
                    </div>
                    <div class="llc-com" v-else>
                        <h4>Связи</h4>
                        <div class="llc-connect_new" v-for="list in items_svyaz.slice(0, 1)">
                            <div v-if="list.ТипДокумента == 'ul'">
                                <p>
                                    <span>Организации</span>
                                    <span v-html="list.НаимЮЛСокр"></span>
                                </p>
                                <p>
                                    <span>руководители</span>
                                    <span>{{ list.Руководитель[0] }}</span>
                                </p>
                            </div>
                            <div v-if="list.ТипДокумента == 'ip'">
                                <p>
                                    <span>ИП</span>
                                    <span>{{ list.Фамилия }} {{ list.Имя }} {{ list.Отчество }}</span>
                                </p>                                
                            </div>
                        </div>
                        <button @click="RedirectToTab(6)" class="btn__gray">Дерево связей</button>
                    </div> -->
                    <div class="llc-complaint" v-if="total_filials != 0">
                        <h4>Филиалы ({{ total_filials }})</h4>

                        <div class="llc-filial__item" v-for="item in items_filials.slice(0, 2)">
                            <p class="llc-fillial__name"><img src="@/assets/icons/map.svg" alt="">{{ item.НаимПредПолн }}</p>
                        </div>

                        <a @click="RedirectToTab(11)" class="llc-arb__more pointer">Все филиалы</a>
                    </div>
                    <div v-else class="llc-complaint">
                        <h4>Филиалы (0)</h4>
                        <p>Данных нет</p>
                    </div>
                </div>
                <div class="main-llc__col">
                    <div class="llc-license" v-if="items_licenses.length == 0">
                        <h4>Лицензии</h4>
                        <p>Организация имеет <span>0</span> лицензий</p>
                    </div>
                    <div class="llc-license" v-else>
                        <h4>Лицензии</h4>
                        <p>Организация имеет <span>{{ total_licenses }}</span> лицензии</p>
                        <div class="llc-license__item" v-for="item in items_licenses.slice(0, 2)">
                            <p class="bold_s">{{ item['Номер лицензии'] }}</p>
                            <p>с {{ item['Дата начала лицензии'] }}</p>
                            <div class="llc-license__green">{{ item.Источник }}</div>
                        </div>
                        <a @click="RedirectToTab(5)" class="llc-arb__more">Подробнее</a>
                    </div>
                    <div class="llc-check" v-if="items_proverki.length == 0">
                        <h4>Проверки</h4>
                        <p>Данных нет</p>
                    </div>
                    <div class="llc-check" v-else>
                        <h4>Проверки</h4>
                        <p class="mar_update">Обновлено {{ TodayDate() }}</p>
                        <div>
                            <div class="llc-check__item" v-for="list in items_proverki.slice(0, 1)">
                                <p v-if='list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].START_DATE'> Период проведения: <span>с {{ list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].START_DATE }} по {{ list.I_APPROVE.I_CARRYOUT_EVENTS["@attributes"].END_DATE }} </span></p>
                                <p>Продолжительность: 
                                    <span v-if='list.I_APPROVE["@attributes"].DURATION_HOURS'>
                                        {{ list.I_APPROVE["@attributes"].DURATION_HOURS }} час.
                                    </span>
                                    <span v-if='list.I_APPROVE["@attributes"].DURATION_DAY'>
                                        {{ list.I_APPROVE["@attributes"].DURATION_DAY }} дн.
                                    </span>
                                </p>
                                <p v-if='list.I_APPROVE["@attributes"].INSP_TARGET'>Цель:<span>{{ list.I_APPROVE["@attributes"].INSP_TARGET }}</span></p>
                                <p v-if='list["@attributes"].ITYPE_NAME'>Тип:<span>{{ list["@attributes"].ITYPE_NAME }}</span></p>
                            </div>
                        </div>

                        <a @click="RedirectToTab(9)" class="llc-arb__more">Подробнее</a>
                    </div>
                    <div class="llc-product" v-if="items_dolgi.length == 0">
                        <h4>Исполнительные производства</h4>
                        <p>Найдено <span>0</span></p>                        
                    </div>
                    <div class="llc-product" v-else>
                        <h4>Исполнительные производства</h4>
                        <p>Найдено <span>{{ total_dolgi }}</span></p>
                        <div class="llc-product__item" v-for="list in items_dolgi.slice(0, 2)">
                            <p class="bold">№ {{ list.НомИспПроизв }}</p>
                            <p>от {{ DateConvert(list.ДатаВозбуждения) }}</p>
                            <div class="llc-product__gos">{{ list.ПредметИсп }}</div>
                        </div>
                        <a @click="RedirectToTab(7)" class="llc-arb__more">Подробнее</a>
                    </div>
                </div>
                <div class="llc-tax">
                    <h4>Налоговый орган</h4>
                    <div class="llc-tax__name">
                        <p>{{ card_info.НаимНО }}</p>
                        <div class="llc-tax__row">
                            <p>Дата постановки на учет</p>
                            <p>{{ card_info.ДатаПостУч }}</p>
                        </div>
                    </div>
                    <div class="llc-tax__item" v-if="card_info.ДатаРегФСС || card_info.РегНомФСС">
                        <p>Сведения о регистрации в ФСС</p>
                        <div class="llc-tax__row" v-if="card_info.РегНомФСС">
                            <p>Регистрационный номер</p>
                            <p>{{ card_info.РегНомФСС }}</p>
                        </div>
                        <div class="llc-tax__row" v-if="card_info.ДатаРегФСС">
                            <p>Дата регистрации</p>
                            <p>{{ card_info.ДатаРегФСС }}</p>
                        </div>
                    </div>
                    <div class="llc-tax__item" v-if="card_info.ДатаРегПФ || card_info.РегНомПФ">
                        <p>Сведения о регистрации в ПФР</p>
                        <div class="llc-tax__row" v-if="card_info.РегНомПФ">
                            <p>Регистрационный номер</p>
                            <p>{{ card_info.РегНомПФ }}</p>
                        </div>
                        <div class="llc-tax__row" v-if="card_info.ДатаРегПФ">
                            <p>Дата регистрации</p>
                            <p>{{ card_info.ДатаРегПФ }}</p>
                        </div>
                    </div>
                </div>
                <div class="llc-sign"  v-if="card_info.ТоварЗнак.length != 0">
                    <h4>Товарные знаки ({{ card_info.ТоварЗнак.length }})</h4>
                    <div class="llc-sign__item">
                        <p>Рег. номер</p>
                        <p class="llc-sign__green">{{ card_info.ТоварЗнак[0].РегНомер }}</p>
                    </div>
                    <div class="llc-sign__item">
                        <p>Номер заявки</p>
                        <p>{{ card_info.ТоварЗнак[0].НомЗаявки }}</p>
                    </div>
                    <div class="llc-sign__item">
                        <p>Дата государственной  регистрации</p>
                        <p>{{ card_info.ТоварЗнак[0].ДатаРег }}</p>
                    </div>
                    <div class="llc-sign__item">
                        <p>Дата истечения срока действия</p>
                        <p>{{ card_info.ТоварЗнак[0].ДатаОконч }}</p>
                    </div>
                    <a @click="RedirectToTab(13)" class="llc-arb__more">Все товарные знаки</a>
                </div>
                <div class="llc-sign" v-else>
                    <h4>Товарные знаки ({{ card_info.ТоварЗнак.length }})</h4>
                    <h4>Данных нет</h4>
                </div>
            </div>
        </div>
        <div class="main-llc__col">
            <div class="llc-report">
                <h4>Показатели отчетности</h4>
                <p class="llc-report__name">Уплаченные страховые взносы за 2020 год (по данным ФНС), ₽</p>
                <div class="llc-report__wrapper">
                    <div class="llc-report__item">
                        <p>Взносы на соц. страхование</p>
                        <p>2,6 млн</p>
                        <div class="llc-report__up">
                            <img src="@/assets/icons/llc_up.svg" alt="">
                            <p>1,3 млн</p>
                        </div>
                    </div>
                    <div class="llc-report__item">
                        <p>Страховые взносы в ПФР</p>
                        <p>6,91 млн</p>
                        <div class="llc-report__up">
                            <img src="@/assets/icons/llc_up.svg" alt="">
                            <p>9,9 млн</p>
                        </div>
                    </div>
                    <div class="llc-report__item">
                        <p>Страховые взносы на ОМС</p>
                        <p>4,7 млн</p>
                        <div class="llc-report__down">
                            <img src="@/assets/icons/llc_down.svg" alt="">
                            <p>2,3 млн</p>
                        </div>
                    </div>
                </div>
                <p class="llc-report__name">Уплаченные налоги за 2020 год, ₽</p>
                <div class="llc-report__grid">
                    <div class="llc-report__item">
                        <p>Страховые взносы на ОМС</p>
                        <p>4,7 млн</p>
                    </div>
                    <div class="llc-report__item">
                        <p>Страховые взносы на ОМС</p>
                        <p>4,7 млн</p>
                    </div>
                    <div class="llc-report__item">
                        <p>Страховые взносы на ОМС</p>
                        <p>4,7 млн</p>
                    </div>
                    <div class="llc-report__item">
                        <p>Страховые взносы на ОМС</p>
                        <p>4,7 млн</p>
                    </div>
                    <div class="llc-report__item">
                        <p>Страховые взносы на ОМС</p>
                        <p>4,7 млн</p>
                    </div>
                </div>
                <div class="llc-report__info">
                    <p class="llc-report__title">Сведения о налоговых правонарушениях и мерах ответственности за их совершение за 2017 год</p>
                    <div class="">
                        <p>3 тыс ₽</p>
                        <p>Сумма штрафа</p>
                    </div>
                </div>
                <div class="llc-report__info">
                    <p class="llc-report__title">Сведения о суммах недоимки и задолженности по пеням и штрафам за 2019 год</p>
                    <div class="">
                        <p>--</p>
                        <p>Сумма штрафа</p>
                    </div>
                </div>
            </div>
            <div class="llc-change" v-if="Object.keys(items_izmeneniya).length == 0">
                <h4>Последние изменения</h4>
                <p>Данных нет</p>                
            </div>
            <div class="llc-change" v-else>
                <h4>Последние изменения</h4>
                <p>Обновлено {{ TodayDate() }}</p>
                <div class="llc-change__item" v-if="address"  v-for="list in address.slice(0, 2)">
                    <p class="bold">{{ list.attribute[0][`${Object.keys(list.attribute[0])}`] }}</p>
                    <p>{{ list.date }}</p>
                </div>
                <div class="llc-change__item" v-if="ceo" v-for="list in ceo.slice(0, 2)">
                    <p class="bold">{{ list.attribute[0].ФИО }}</p>
                    <p>{{ list.date }}</p>
                    <p>Должность: {{ list.attribute[0].НаимДолжн }}</p>
                    <p>ИНН: {{ list.attribute[0].ИНН }}</p>
                </div>
                <div class="llc-change__item" v-if="founder" v-for="list in founder.slice(0, 1)">
                    <p class="bold" v-if="list.attribute[0].Наименование">{{ list.attribute[0].Наименование }}</p>
                    <p class="bold" v-if="list.attribute[0].ФИО">{{ list.attribute[0].ФИО }}</p>
                    <p>{{ list.date }}</p>
                    <p>Доля: {{ list.attribute[0].ДоляРуб }}₽</p>
                    <p>ИНН: {{ list.attribute[0].ИНН }}</p>
                </div>
                <a @click="RedirectToTab(14)" class="llc-arb__more">Все изменения</a>
            </div>
        </div>
        <div class="llc-desc">
            <h4>Описание деятельности компании</h4>
            <p v-html="card_info.Описание"></p>
        </div>
        <div class="llc-req">
            <h4>Реквизиты 
<!--                 <a href="#"><img src="@/assets/icons/downloud.svg" alt=""></a> -->
            </h4>
            <p>{{ card_info.НаимЮЛСокр }}<br>
                {{ card_info.Адрес }} <br>
                ОГРН {{ card_info.ОГРН }} <br>
                ИНН {{ card_info.ИНН }} <br>
                КПП {{ card_info.КПП }} <br>
                ОКВЭД {{ card_info.КодОКВЭД }} <br>
                <br>
                {{ card_info.Руководители[0].post }}<br>
                <br>
                {{ card_info.Руководители[0].fl }}</p>
            <button @click="RedirectToTab(12)" class="btn__gray">Все реквизиты</button>
        </div>
    </div>
    <div class="loader__block" v-else>
        <div class="loader-wait">
            <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
        </div>         
    </div>
</template>

<style scoped>
.llc-grah__tab li {
    cursor: pointer;
}

.llc-fillial__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;

    color: #272833;
    max-width: 100% !important;
}

.llc-check__item p {
    font-weight: 400;
    font-size: 16px;
    line-height: 157.5%;
    color: #737373;   
    margin-bottom: 5px; 
}

.mar_update {
    margin-bottom: 15px;
}

.llc-check__item p span {
    font-weight: 500;
    font-size: 16px;
    line-height: 157.5%;
    color: #000000;
}

.llc-filial__item {
    margin: 10px 0 0 0;
}

.row-grah__info {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #E6E6E6;
}

.row-grah__info p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #737373;
}

.row-grah__info h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 158%;
    color: #272833;
}

.llc-connect_new {
    display: flex;
    flex-direction: column;
}

.llc-connect_new p {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.llc-com h4 {
    margin-bottom: 15px;
}

.llc-connect_new p span {    
    font-weight: 400;
    font-size: 14px;
    line-height: 157.5%;
    margin-bottom: 8px;

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #737373;
}

.llc-connect_new p span + span {    
    font-weight: 700;
    font-size: 16px;
    line-height: 157.5%;
    /* or 25px */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* black */

    color: #272833;
}


.unit {
  fill: none;
  stroke-width: 5px;
}

.unit:nth-child(1) {
  stroke: #00D45F;
}

.unit:nth-child(2) {
  stroke: #F8D557;
}

.unit:nth-child(3) {
  stroke: #ED6A50;
}

.unit:nth-child(1) {
  stroke: #00D45F;
}

.unit:nth-child(2) {
  stroke: #F8D557;
}

.unit:nth-child(3) {
  stroke: #ED6A50;
}

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';
    

    export default {
        name: "Info",
        directives: { mask },
        props: {
            project_id: [String, Number],

            items_izmeneniya: Object,

            card_info: Object,
            success: Array,
            danger: Array,
            warning: Array,

            items_svyaz: {
                type: Array,
                default: [],
            },

            total_svyaz: Number,

            items_licenses: {
                type: Array,
                default: [],
            },

            items_proverki: {
                type: Array,
                default: [],
            },

            items_dolgi: {
                type: Array,
                default: [],
            },

            total_dolgi: Number,

            items_filials: Array,
            total_filials: Number,

            total_partipant: Number,
            total_customer: Number,
            items_participant: Array,
            items_customer: Array,
            zvezdi: Number,
            items_arbitaj: [Array, Object],
            total_arbitaj: Number,
        },
        components: {

        },
        data() {
            return {
                tab_zakupki: 0,
                tab__graph: 0,

                address: [],
                ceo: [],
                founder: [],

                big_number: 0,
                small_number: 0,

                loader: true,

                object_favorite: {},
                user: {},
                status: "",

                total_licenses: 30,

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            if (this.items_izmeneniya.address) {
                this.address = this.items_izmeneniya.address
            }
            
            if (this.items_izmeneniya.ceo) {
                this.ceo = this.items_izmeneniya.ceo
            }

            if (this.items_izmeneniya.founder) {
                this.founder = this.items_izmeneniya.founder
            }


            setTimeout(() => {
                this.loader = false

                if (this.items_izmeneniya.address) {
                    this.address = this.items_izmeneniya.address
                }
                
                if (this.items_izmeneniya.ceo) {
                    this.ceo = this.items_izmeneniya.ceo
                }

                if (this.items_izmeneniya.founder) {
                    this.founder = this.items_izmeneniya.founder
                }
            }, 2400)

        },
        methods: {
            async GetMeInfo() {
                await axios
                    .get('/api/v1/me/')
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            RedirectToTab(value) {
                this.$emit('value', value)
                document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
            },
            TabChooseGoszakypki(value) {
                this.tab_zakupki = value 
            },
            TabChooseGraph(value) {
                this.tab__graph = value
            },
            async AddFavorite(list, project_id) {
                var inn = ""
                var kpp = ""
                var innfl = ""

                if (list.ИНН) {
                    inn = list.ИНН
                }

                if (list.КПП) {
                    kpp = list.КПП
                }

                if (list.ИННФЛ) {
                    innfl = list.ИННФЛ
                }

                const dataForm = {
                    user: this.user.user,
                    title: list.НаимЮЛСокр,
                    inn: inn,
                    kpp: kpp,
                    innfl: innfl,
                    status: list.Активность,
                    project_id: project_id,
                    type_project: list.ТипДокумента,
                }

                await axios
                .post('/api/v1/favorite/post/', dataForm)
                .then(response => {
                    this.status = 'success'
                })
                .catch(error => {
                  console.log(error)
                })
                 
            },
            async UnFav() {
                await axios
                .delete(`/api/v1/favorite/delete/${this.object_favorite.id}/`)
                .then(response => {
                    this.status = 'error'
                })
                .catch(error => {
                  console.log(error)
                })
            },
            async CheckFavorite() {
                await axios
                .get(`/api/v1/favorite/check/${this.project_id}/`)
                .then(response => {
                    this.status = response.data.status

                    if (this.status == 'success') {
                        this.object_favorite = response.data.body
                    }
                })
                .catch(error => {
                  console.log(error)
                })
                 
            },
            SmallLargNumber(a, b, c) {
                let larg = 0
                let small = 0

                if (a > b) {
                    larg = a
                    small = b
                    if (c > a) {
                        larg = c
                    } else if (b > c) {
                        small = c
                    } 
                } else {
                    larg = b
                    small = a
                    
                    if (c > b) {
                        larg = c
                    } else if (a > c) {
                        small = c
                    }
                }      

                this.big_number = larg
                this.small_number = small 
            },
            FormulaProcent(value){
                this.SmallLargNumber(this.success.length, this.warning.length, this.danger.length)

                let sum = 0 
                let procent = 0 
                let x = 0   

                sum = Number(this.success.length + this.danger.length + this.warning.length)  

                if (this.big_number == value) {
                    procent = 100
                }  else {
                    procent = Math.floor((value / sum) * 100)
                }    

                if (this.small_number == value) {
                    procent = procent + 30
                } 

                x = Math.floor(75.36 - 75.36 * procent / 100)
                return x
            },
            toLocateSum(value) {
                let x = 0
                // var str = value.toString()
                // str = str.substring(0, str.length-3);
                // x = Number(str).toLocaleString('ru-RU')
                // return x
                x = Number(value).toLocaleString('ru-RU')
                return x
            },
            ComputedProcent(value) {
                let x = 0

                x = parseFloat(value / this.card_info.СвУчредит.sumCap) * 100
                return x
            },
            TodayDate() {
                return moment().locale('ru').format('L')
            },
            DateConvert(value) {
                var date = new Date(value*1000)
                return moment(date).locale('ru').format('L')
            },
        },
        watch: {
            // tab(value) {
            //     this.$emit('value', this.tab)
            // }
        },
        created() {
            this.GetMeInfo()
            this.CheckFavorite()
        }
   }
</script>