<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title">
        <div class="container">
            <h1>Выписка из ЕГРЮЛ/ЕГРИП</h1>
            <p>Выписка из Государственного реестра содержит актуальную открытую информацию о вашем контрагенте.</p>
        </div>
    </section>
    <section class="ceo-list ceo-list__extract">
        <div class="container">
            <h2>Выписка из ЕГРЮЛ</h2>
            <p>Каждое юридическое лицо на территории РФ через Федеральную налоговую службу регистрируется в Едином Государственном Реестре Юридических лиц. Выписка из ЕГРЮЛ отражает подробную открытую информацию, действующую на данный момент времени, о конкретной организации:</p>
            <ul>
                <li>
                    <p>Название организации, ИНН/КПП, организационно-правовая форма.</p>
                </li>
                <li>
                    <p>Состояние ЮЛ</p>
                    <p>(действующее, ликвидированное, в стадии реорганизации и пр.)</p>
                </li>
                <li>
                    <p>Адрес регистрации, контактные данные</p>
                </li>
                <li>
                    <p>Информация о регистрации</p>
                </li>
                <li>
                    <p>Данные о регистрирующем органе</p>
                </li>
                <li>
                    <p>Сведения о руководителе юридического лица</p>
                </li>
                <li>
                    <p>Информация об участниках (учредителях) ЮЛ</p>
                </li>
                <li>
                    <p>Уставный капитал</p>
                </li>
                <li>
                    <p>Регистрация юридического лица в ПФ и ФСС</p>
                </li>
                <li>
                    <p> Какие виды экономической деятельности ведет организация</p>
                </li>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>При изменении информации, которая содержится в реестре и отражается в выписке, руководитель организации обязан через отделение ФНС подать заявку для внесения их в Государственный Реестр. Согласно законодательству, изменения вносятся в течение 5 дней.</p>
            <p>Получив выписку, сверьте данные с теми, которые вам предоставил контрагент или которые указаны в первичных документах на авансовый платеж. Обнаружатся расхождения — спросите, когда он подавал заявку на внесение изменений в ЕГРЮЛ. Если оппонент скажет, что заявка не подавалась, стоит насторожиться.</p>
        </div>
    </section>
    <div class="ceo-suc">
        <div class="container">
            <div class="ceo-suc__wrapper">
                <p>ВНИМАНИЕ: в выписке не предоставляются персональные данные директора и учредителей.</p>
            </div>
        </div>
    </div>
    <section class="ceo-list ceo-list__extract ceo-list__pt">
        <div class="container">
            <h2>Выписка из ЕГРИП</h2>
            <p>Экономическую деятельность в Российской Федерации можно вести без образования юридического лица. Для этого достаточно зарегистрироваться индивидуальным предпринимателем в ЕГРИП. Заявка подается в налоговую службу. Фактически любой ИП обладает многими правами юридических лиц. Информацию о ИП в разрезе проводимой им экономической деятельности можно узнать, заказав выписку из ЕГРИП:</p>
            <ul>
                <li>
                    <p>Регистрационные сведения и данные об органе, который произвел регистрацию</p>
                </li>
                <li>
                    <p>Действующий статус ИП</p>
                </li>
                <li>
                    <p>Информация о физическом лице</p>
                    <p>гражданство, адрес регистрации (места жительства), телефон</p>
                </li>
                <li>
                    <p>Перечень видов деятельности, осуществляемой ИП</p>
                </li>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Если у предпринимателя изменяются данные, внесенные в государственный реестр, он обязан через отделение ФНС подать заявку для записи их в ЕГРИП. Изменения отразятся в выписке.</p>
            <p>Получив выписку, сверьте данные с теми, которые вам предоставил ИП или которые указаны в первичных документах на авансовый платеж. Обнаружатся расхождения — спросите, когда он подавал заявку на внесение изменений в ЕГРИП. Если предприниматель скажет, что заявка не подавалась, стоит насторожиться.</p>
        </div>
    </section>
    <section class="ceo-prev ceo-prev__extract">
        <div class="container">
            <div class="ceo-prev__info">
                <p>Информация на портале синхронизирована с ресурсом ФНС РФ и ежедневно обновляется</p>
                <p>Воспользовавшись нашим сервисом, вы получите выписку из ЕГРЮЛ <br> ЕГРИП с электронной подписью ФНС, равнозначную бумажному документу.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <img src="@/assets/img/ceo-prev_extract.jpg" alt="">
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Extract',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
