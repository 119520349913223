<template>
    <div class="inner-page">
        <div class="inner-page__title" v-if="card_ip">
            <h3>ИП {{ card_info.ФИО }}</h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Статус }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРНИП }}</p>
                </div>
            </div>
        </div>
        <div class="inner-page__title" v-else>
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="custom" v-if="total_partipant != 0 || total_customer != 0">
            <h1>Закупки</h1>
            <ul class="custom__list">
                <li @click="TabChoose(0)" 
                    :class="{'active': tab == 0}">
                    Поставщик ({{ total_partipant }})
                </li>
                <li 
                    @click="TabChoose(1)"
                    :class="{'active': tab == 1}"
                >
                    Заказчик ({{ total_customer }})
                </li>
            </ul>
            <div class="custom__wrapper">
                <div class="custom__row custom__title">
                    <div class="">
                        <p>{{ title }}</p>
                    </div>
                    <div class="">
                        <p>Количество</p>
                    </div>
                    <div class="">
                        <p>Сумма, ₽</p>
                    </div>
                </div>
                <div class="custom__row" v-show="tab == 0" v-for="list in items_participant">
                    <div class="">
                        <p>{{ list.participantFullName }}</p>
                        <p>{{ list.participant }}</p>
                    </div>
                    <div class="">
                        <p>{{ list.count }}</p>
                    </div>
                    <div class="">
                        <p>{{ list.sum }}</p>
                    </div>
                </div>
                <div class="custom__row" v-show="tab == 1" v-for="list in items_customer">
                    <div class="">
                        <p>{{ list.customerFullName }}</p>
                        <p>{{ list.customer }}</p>
                    </div>
                    <div class="">
                        <p>{{ list.count }}</p>
                    </div>
                    <div class="">
                        <p>{{ list.sum }}</p>
                    </div>
                </div>
            </div>
<!--             <ul class="pagination">
                <li class="active">1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
            </ul> -->
        </div>
        <div class="custom" v-else>
            <h1>Закупки</h1>
            <p>Данных нет</p>
        </div>
    </div>
</template>

<style scoped>
.custom__list li {
    cursor: pointer;
}
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Goszakypki",
        directives: { mask },
        props: {
            card_info: Object,
            total_partipant: Number,
            total_customer: Number,
            items_participant: Array,
            items_customer: Array,

            card_ip: {
                type: Boolean,
                default: false,
            },
        },
        components: {

        },
        data() {
            return {
                title: "Поставщик",
                tab: 0,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            async GetParticipant() {
                  await axios
                  .get('/api/v1/ooo/zakupki/participant')
                  .then(response => {
                        this.items_participant = response.data.body.docs
                        this.total_partipant = response.data.body.total
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            },  
            async GetCustomer() {
                  await axios
                  .get('/api/v1/ooo/zakupki/customer')
                  .then(response => {
                        this.items_customer = response.data.body.docs
                        this.total_customer = response.data.body.total
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            }, 
            TabChoose(value) {
                this.tab = value 

                if (value == 1) {
                    this.title = "Заказчик"
                } else {
                    this.title = "Поставщик"
                }
            },
        },
        created() {

        },
        watch: {

        },        
   }
</script>