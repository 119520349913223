import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import VueYandexMetrika from 'vue-yandex-metrika' 

axios.defaults.baseURL = 'https://datafix.tech'

createApp(App).use(VueYandexMetrika, {
    id: 91147848,
    router: router,
    env: process.env.NODE_ENV
}).use(store).use(router).mount('#app')
