<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__row">
                <div class="footer__list">
                    <router-link to="/">
                        <img src="@/assets/icons/logo_footer.svg" alt="" class="footer__logo">
                    </router-link>
                    <div class="">
                        <p>Компания</p>
                        <ul>
                            <li><router-link to="/about-service">О сервисе</router-link></li>
                            <li><router-link to="/my-profile">Личный кабинет</router-link></li>
                            <li><router-link to="/subs-seo">Тарифы</router-link></li>
                        </ul>
                    </div>
                    <div class="">
                        <p>Разделы</p>
                        <ul :class="{'see-all': pagination}">
                            <li><router-link to="/director">Руководитель</router-link></li>
                            <li><router-link to="/founders">Учредители</router-link></li>
                            <li><router-link to="/affiliation">Аффилированность</router-link></li>
                            <li><router-link to="/legal_adress">Юридический адрес</router-link></li>
                            <li><router-link to="/egrul">ЕГРЮЛ</router-link></li>
                            <li><router-link to="/ogrnip">ОГРНИП</router-link></li>
                            <li><router-link to="/ogrn">ОГРН</router-link></li>
                            <li><router-link to="/extract">ЕГРИП/ЕГРЮЛ</router-link></li>
                            <li><router-link to="/egrip">ЕГРИП</router-link></li>
                            <li><router-link to="/reporting">Отчетность компаний</router-link></li>
                            <li><router-link to="/arbitration-court">Арбитраж</router-link></li>
                            <li><router-link to="/procurement">Закупки</router-link></li>
                            <li><router-link to="/activites">Виды деятельности</router-link></li>
                            <li><router-link to="/fssp">ФССП</router-link></li>
                            <li><router-link to="/phone">Телефоны организаций РФ</router-link></li>
                            <li><router-link to="/employees">Реестр товарных знаков</router-link></li>
                            <li><router-link to="/history-of-changes">История изменений</router-link></li>
                            <li><router-link to="/employees">Количество сотрудников</router-link></li>
                            <li><router-link to="/vertification">Проверка работодателя</router-link></li>
                            <li><router-link to="/realibity-check">Проверка контрагентов и партнеров</router-link></li>
                            <li><router-link to="/raiting">Рейтинг</router-link></li>
                        </ul>
                        <a @click="pagination = true" :class="{'d-none': pagination}" class="footer__more">Показать все</a>
                    </div>
                </div>
                <div class="footer__info">
                    <p><!-- ЭЛ № ФС77-70272 выдано Федеральной службой по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор) 10.07.2017 г --></p>
                    <button class="footer__btn" @click="modal_feedback = true">Обратная связь</button>
                </div>
            </div>
            <div class="footer__desc">
                <ul>
                    <li><a href="https://datafix.tech/privacy_policy.pdf" target="_blank">Политика конфиденциальности</a></li>
                    <li><a href="https://datafix.tech/user_agreement.pdf" target="_blank">Пользовательское соглашение</a></li>
<!--                     <li><a href="https://datafix.tech/datafix_oferta.pdf" target="_blank">Договор оферты</a></li> -->
                    <!-- <li class="studio__by"><a>Разработано <span>Студией Дмитрия Маркина</span></a></li> -->
                    <!-- <li class="studio__by"><a target="_blank" href="https://oktta.ru/">Дизайн <span>Oktta</span></a></li> -->
                </ul>
                <a href="mailto: info@datafix.tech" class="footer__link">info@datafix.tech</a>
            </div>
        </div>
    </footer>     

    <div class="modal" :class="{'modal__active': modal_feedback}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Обратная связь</h2>
            <div class="modal__reg">
                <input v-model="fio" type="text" placeholder="ФИО">
                <input v-model="email" type="text" placeholder="Email">
                <textarea v-model="message" placeholder="Текст обращения" maxlength="200"></textarea>
            </div>
            <div v-if="fio && email && message">
                <button @click="SendFeedback()" class="btn__green">Отправить</button>
            </div>
            <div v-else>
                <button class="btn__green" disabled>Отправить</button>
            </div>
            
            <p class="modal__ver">Нажимая кнопку «Отправить», я соглашаюсь с условиями <a href="#">политики конфиденциальности</a> и <a href="#">пользовательского соглашения</a></p>

            <div class="modal__close pointer" @click="modal_feedback = false"></div>
        </div>
    </div>

    <div class="modal" :class="{'modal__active': modal_send}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <img src="@/assets/icons/succes.svg" alt="" class="modal__success">
            <h2>Ваше обращение успешно отправлено!</h2>
            <div class="modal__close pointer" @click="modal_send = false"></div>
        </div>
    </div>   
</template>

<style scoped>
    .see-all {
        max-height: 1000px !important;
    }

    .d-none {
        display: none !important;
    }

    .modal__reg textarea {
        background: #F9F9F9;
        border-radius: 8px;
        padding: 16px 20px;
        box-sizing: border-box;
        border: none;
        height: 120px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 113.5%;
        color: #272833;
        resize: none;
    }

    .studio__by {
        margin-left: 20px;
    }
</style>

<script>
    import axios from 'axios'
    export default {
        name: 'Footer',
        data() {
            return {
                fio: "",
                email: "",
                message: "",

                pagination: false,
                modal_feedback: false,
                modal_send: false,
            }
        },
        methods: {
            async SendEmail() {
                const FormData = {
                    fio: this.fio,
                    email: this.email,
                    description: this.message,
                }

                await axios
                    .post('/api/v1/send_help_user/', FormData)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    }) 
            },    
            async SendFeedback() {
                const FormData = {
                    name: this.fio,
                    email: this.email,
                    description: this.message,
                }

                await axios
                    .post('/api/v1/contact_post/', FormData)
                    .then(response => {
                        this.message = ""
                        this.modal_feedback = false
                        this.modal_send = true
                        this.SendEmail()

                        setTimeout(() => {
                            this.modal_send = false
                        }, 3000)
                    })
                    .catch(error => {
                        console.log(error)
                    }) 
            },     
        },
    }
</script>