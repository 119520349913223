<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__egrip">
        <div class="container">
            <h1>История изменений</h1>
            <p>В настоящее время понятие закрытости данных стало более узким, и серьезная информация о компаниях стала более открытой. Данные распределены по разным ресурсам: СМИ, ФНС, ФССП, Федеральная служба государственной статистики, внебюджетные фонды и пр. </p>
        </div>
    </section>
    <section class="affril-list affril-list__activities affril-list__padding">
        <div class="container">
            <div class="affril-list__name">
                <h2>Зачем нужна история изменений</h2>
                <p>Большая доля информации для проверки компаний формируется на основании открытых данных ФНС России. Помимо ЕГРЮЛ и ЕГРИП налоговая служба имеет специальные реестры, т. н. «черный список». В него попадают компании, проверка по которых выявила:</p>
            </div>
            <ul>
                <li>Предоставление недостоверных данных в ЕГРЮЛ</li>
                <li>Изменения данных, не внесенные в реестр</li>
                <li> Недействительный юридический адрес</li>
                <li>Отсутствие связи с предприятием</li>
                <p class="affril-list__activities_more">Прочие нарушения</p>
            </ul>
        </div>
    </section>
    <div class="ceo-suc">
        <div class="container">
            <div class="ceo-suc__wrapper">
                <p>Работая с контрагентом необходимо находиться в курсе всех изменений официальных данных компании и контролировать, не попала ли организация в черные списки ФНС.</p>
            </div>
        </div>
    </div>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>Согласно законодательству, если организация предоставила недостоверные сведения для регистрации в ЕГРЮЛ и этот факт выявлен налоговой службой, юридическое лицо по решению ФНС может быть исключено из реестра. </p>
            <p>Работать с такой организацией категорически нельзя — на момент сделки она может оказаться недействующей.</p>
        </div>
    </section>
    <div class="ceo-info ceo-info__activities">
        <div class="container">
            <div class="ceo-info__text">
                <p>Взаимодействуя с компанией, вы должны быть в курсе новых рисков постоянно.</p>
                <p>Для отслеживания изменений, происходящих с контрагентами, на портале имеется опция «История изменений». </p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <div class="ceo-info__img">
                <img src="@/assets/img/ceo-change.svg" alt="">
            </div>
        </div>
    </div>
    <section class="ceo-list ceo-list__change">
        <div class="container">
            <h2>Какая информация отражается в истории изменений</h2>
            <p>Опция работает с использованием открытых данных из государственных реестров и баз данных:</p>
            <ul>
                <li>
                    <p>Единый государственный реестр юридических лиц</p>
                </li>
                <li>
                    <p>Единый государственный реестр индивидуальных предпринимателей</p>
                </li>
                <li>
                    <p>Единый государственный реестр налогоплательщиков</p>
                </li>
                <li>
                    <p>Реестры ФНС неблагонадежных руководителей</p>
                </li>
                <li>
                    <p>Федеральный реестр дисквалифицированных лиц</p>
                </li>
                <li>
                    <p>Реестр Федеральной службы государственной статистики</p>
                </li>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p>История изменений будет полезна для проверки собственной компании, не попала ли она в специальные реестры ФНС.</p>
            <p>Актуальность информации обеспечена синхронизацией информационных ресурсов портала с ресурсами официальных органов. </p>
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'HistoryOfChanges',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
