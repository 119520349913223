<template>
    <div class="inner-page">
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="connect" v-if="total_svyaz != 0">
            <h1>Связанные организации ({{ total_svyaz }})</h1>
            <div class="connect__wrapper">
                <div class="connect__text">
                    <h3>{{ card_info.Руководители[0].fl }} <span>— Руководитель {{ card_info.НаимЮЛСокр }} с {{ card_info.Руководители[0].date }}</span></h3>
                    <div class="connect__item connect__item_active" v-for="list in items_svyaz">
                        <div v-if="list.ТипДокумента == 'ul'">
                            <p class="connect__name" v-html="list.НаимЮЛСокр"></p>
                            <p class="connect__info">
                                <span>Руководитель</span>
                                <span v-if="list.Руководитель">{{ list.Руководитель[0] }}</span>
                                <span v-else> - </span>
                            </p>
                            <p class="connect__parag"><span>Адрес:</span>{{ list.Адрес }}</p>
                            <div class="connect__row">
                                <p class="connect__parag"><span>ИНН: </span> {{ list.ИНН }}</p>
                                <p class="connect__parag"><span>КПП: </span> {{ list.КПП }}</p>
                                <p class="connect__parag"><span>Дата регистрации: </span> {{ list.ОбрДата }}.</p>
                            </div>
                            <p class="connect__parag"><span>Основной вид деятельности:</span> {{ list.КодОКВЭД }} {{ list.НаимОКВЭД }}</p>
                        </div>
                        <div v-if="list.ТипДокумента == 'ip'">
                            <p class="connect__name">{{ list.Фамилия }} {{ list.Имя }} {{ list.Отчество }}</p>
                            <div class="connect__row">
                                <p class="connect__parag"><span>ИНН: </span> {{ list.ИННФЛ }}</p>
                                <p class="connect__parag"><span>Дата регистрации: </span> {{ list.ДатаОГРНИП }}.</p>
                            </div>
                            <p class="connect__parag"><span>Основной вид деятельности:</span> {{ list.КодОКВЭД }} {{ list.НаимОКВЭД }}</p>
                        </div>
                    </div>
<!--                     <ul class="pagination">
                        <li class="active">1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                    </ul> -->
                </div>
<!--                 <div class="connect__func">
                    <div class="connect__mob">
                        <button class="btn__green">Смотреть схему</button>
                    </div>
                    <img src="@/assets/img/connect.svg" alt="">
                </div> -->
            </div>
        </div>
        <div class="connect" v-else>
            <h1>Связанные организации</h1>
            <p>Данных нет</p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Connect",
        directives: { mask },
        props: {
            card_info: Object,
            items_svyaz: Array,
            total_svyaz: Number,
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {

        },
        created() {

        },
        watch: {

        },        
   }
</script>