<template>
    <Header />
    <section class="header-lend WowCss">
        <img src="@/assets/img/lend-header.svg" alt="" class="header-lend__bg">
        <div class="container">
            <h1>Специальное предложение</h1>
            <p class="new__p">Оформите подписку до конца сентября и получите обслуживание <br> в течении целого года бесплатно!</p>
            <div class="header-lend__form">
                <h2>Заявка на доступ</h2>
                <form>
                    <input v-model="name" type="text" placeholder="Имя">
                    <input v-model="surname" type="text" placeholder="Фамилия">
                    <input v-model="inn" type="tel" v-mask="'############'" placeholder="ИНН">
                    <input v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'" placeholder="Номер телефона">
                    <input v-model="email" type="email" placeholder="Email" required>
                    <div v-if="inn.length >= 10 && email">
                      <button class="btn__green" type="button" @click="sendEmail()">Отправить</button>
                    </div>
                    <div v-else>
                      <button class="btn__green" type="button" disabled>Отправить</button>
                    </div>
                    
                </form>
                <p>Нажимая кнопку «Отправить», я соглашаюсь с условиями политики конфиденциальности</p>
            </div>
            <div class="header-lend__wrapper">
                <div class="header-lend__item">
                    <img src="@/assets/icons/header-lend_1.svg" alt="">
                    <h3>Экспресс оценка рисков</h3>
                    <p class="reg">Уникальные аналитические показатели, позволяющие провести экспресс-анализ компании и оценить ее благонадежность</p>
                </div>
                <div class="header-lend__item">
                    <img src="@/assets/icons/header-lend_2.svg" alt="">
                    <h3>Умный поиск</h3>
                    <p class="reg">Максимально эффективный поиск с возможностью точной идентификации искомого объекта</p>
                </div>
                <div class="header-lend__item">
                    <img src="@/assets/icons/header-lend_3.svg" alt="">
                    <h3>Финансовый анализ</h3>
                    <p class="reg">Графический и табличный анализ финансово-экономических показателей и расчетных коэффициентов компании</p>
                </div>
            </div>
        </div>
    </section>
    <section class="rate rate-lend WowCss">
        <div class="container">
            <h2>Тарифы </h2>
            <div class="rate__wrapper">
                <div class="rate__item">
                    <h2>1 месяц</h2>
                    <div class="rate__price">
                        <p>350 ₽</p>
                    </div>
                    <ul>
                        <li><p>900 <span>запросов в месяц</span></p></li>
                        <li><p>1 <span>устройство</span></p></li>
                    </ul>
                </div>
                <div class="rate__item">
                    <h2>3 месяца</h2>
                    <div class="rate__price">
                        <p>9900 ₽</p>
                    </div>
                    <ul>
                        <li><p>3000 <span>запросов в месяц</span></p></li>
                        <li><p>неограниченное <span>количество устройств</span></p></li>
                    </ul>
                </div>
                <div class="rate__item">
                    <h2>12 месяцев</h2>
                    <div class="rate__price">
                        <p>49 900 ₽</p>
                    </div>
                    <ul>
                        <li><p>неограниченное <span>количество запросов</span></p></li>
                        <li><p>неограниченное <span>количество устройств</span></p></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="extra-feat WowCss">
        <div class="container">
            <h2>Дополнительные возможности</h2>
            <p>28 309 667 компаний и ИП по состоянию на сегодня в Базе данных</p>
            <div class="extra-feat__wrapper">
                <div class="extra-feat__item">
                    <img src="@/assets/icons/extra_1.svg" alt="">
                    <h3>Информация</h3>
                    <p>Получить информацию об организации, рейтинг, реквизиты руководителя, учредителей, лицензии.</p>
                </div>
                <div class="extra-feat__item">
                    <img src="@/assets/icons/extra_2.svg" alt="">
                    <h3>Арбитраж</h3>
                    <p>Проверить судебные дела, исполнительные производства, штрафы и недоимки, недостоверность сведений.</p>
                </div>
                <div class="extra-feat__item">
                    <img src="@/assets/icons/extra_3.svg" alt="">
                    <h3>Активность</h3>
                    <p>Получить краткий финансовый анализ, отчетность компаний, госконтракты, товарные знаки и другую информацию.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="potential WowCss">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <h2>Потенциал сервиса</h2>
            <p>С помощью сервиса DATAFIX каждый Пользователь может проверить контрагента и получить о нем полную информацию, собранную из официальных открытых источников (ЕГРЮЛ, ЕГРИП, БОО, Арбитражный Суд, ФССП).</p>
            <p>Данная информация необходима для принятия важных решений при выборе поставщика товаров или услуг, а также при других ситуациях, когда необходимо получить достоверные данные о юридических лицах или индивидуальных предпринимателях, зарегистрированных в Российской Федерации.</p>
        </div>
    </section>
    <div class="mac WowCss">
        <div class="container">
            <div class="mac__wrapper">
                <p>Через поисковую строку, можно найти контрагента по ИНН (юридического или физического лица), по адресу компании, по ФИО руководителя или учредителя, по ОГРН (для юридических лиц), по ОГРНИП (для индивидуальных предпринимателей).</p>
            </div>
            <img src="@/assets/img/mac_1.svg" alt="">
            <p>Получив карточку организации, можно ознакомиться с контактными данными, бухгалтерской отчетностью компаний — данная отчетность является одним из важных показателей при проверке компании на благонадежность, также можно проверить компанию на участие в Арбитражных делах, что в свою очередь так же является специальным пунктом проверки Контрагента.</p>
        </div>
    </div>

    <Footer />

    <div class="modal" :class="{'modal__active': tarif__modal}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Оплата подписки</h2>
            <p class="reg">После оплаты на указанную почту придут данные для входа в ваш автоматически созданный аккаунт</p>
            <div class="modal__pay">
                <p>Стандарт <span>1 месяц</span></p>
                <p>350 ₽</p>
            </div>
            <div class="modal__reg">
                <input type="text" placeholder="ФИО*">
                <input type="text" placeholder="E-mail*">
                <input type="text" placeholder="E-mail еще раз*">
                <div class="modal__reg_phone">
                    <input type="text" placeholder="+7 (999) 999-99-99*">
                    <a href="#">
                        <img src="@/assets/icons/rus.svg" alt="">
                    </a>
                </div>
                <input type="text" placeholder="Промокод">
            </div>
            <div class="modal__pay">
                <p>Стандарт</p>
                <p class="bold">350 ₽</p>
            </div>
            <button class="btn__green pointer" @click="tarif__modal = false">Перейти к оплате</button>
            <p class="modal__ver">Нажимая кнопку «Зарегистрироваться», я соглашаюсь с условиями <a href="#">политики конфиденциальности</a> и <a href="#">пользовательского соглашения</a></p>
            <div class="modal__close pointer" @click="tarif__modal = false"></div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'
import axios from 'axios'

import {mask} from 'vue-the-mask'

export default {
  name: 'PromoView',
  directives: { mask },
  components: {
    Header,
    Footer
  },
  data() {
    return {
      tarif__modal: false,
      name: "",
      surname: "",
      email: "",
      phone: "",
      inn: "",
    }
  },
  methods: {
      async sendEmail() {
          const data = {
              name: this.name,
              surname: this.surname,
              email: this.email,
              phone: this.phone,
              inn: this.inn,
          }

          await axios
          .post('/api/v1/send_email/', data)
          .then(response => {
              console.log(response)
              alert('Успешно!')
              this.name = ""
              this.surname = ""
              this.email = ""
              this.phone = ""
              this.inn = ""
          })
          .catch(error => {
              console.log(error)
          })
         
      },
  },
}
</script>

<style>
  .new__p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    /* or 38px */

    text-align: center;

    /* black */

    color: #272833;

    transform: rotate(0deg);    
  }
</style>
