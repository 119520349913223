<template>
    <HeaderProfile />

    <div class="main-llc">
        <div class="container">
            <NavigationMenu 
                v-on:value="tab = $event" 
                :tab_current="tab"
            />

            <section v-if="tab == 0">
                <Info
                    :card_info="card_info"
                    :items_participant="items_participant"
                    :items_customer="items_customer"
                    :total_customer="total_customer"
                    :total_partipant="total_partipant"
                    :items_arbitaj="items_arbitaj_info"
                    :total_arbitaj="total_arbitaj"
                    :project_id="project_id"
                    :items_izmeneniya="items_izmeneniya"
                    :items_proverki="items_proverki"
                    :items_licenses="items_licenses"
                    :total_licenses="total_licenses"

                    v-on:value="tab = $event" 
                />
            </section>

            <section v-if="tab == 1">
                <Arbitraj
                    :card_info="card_info"
                    :items_arbitaj="items_arbitaj"
                    :total_arbitaj="total_arbitaj"
                    :card_ip="true"
                />
            </section> 
            <section v-if="tab == 2">
                <Goszakypki
                    :card_info="card_info"
                    :items_participant="items_participant"
                    :items_customer="items_customer"
                    :total_customer="total_customer"
                    :total_partipant="total_partipant"
                    :card_ip="true"
                />
            </section>
            <section v-if="tab == 3">
                <Licenses
                    :card_info="card_info"
                    :card_ip="true"
                    :items_licenses="items_licenses"
                    :total_licenses="total_licenses"
                />
            </section>
            <section v-if="tab == 4">
                <Proverki 
                    :total_proverki="total_proverki"
                    :items_proverki="items_proverki"
                    :card_info="card_info"
                    :card_ip="true"
                />
            </section>  
            <section v-if="tab == 5">
                <Bankrotstvo 
                    :card_info="card_info"
                    :card_ip="true"
                />
            </section>  

        </div>
    </div>

    <Footer />   
</template>

<style scoped>
.container-relative {
    position: relative;
}
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    import Info from '@/components/IP/Info.vue'
    import Licenses from '@/components/OOO/Licenses.vue'
    import Arbitraj from '@/components/OOO/Arbitraj.vue'

    import Goszakypki from '@/components/OOO/Goszakypki.vue'
    import Proverki from '@/components/OOO/Proverki.vue'
    import Bankrotstvo from '@/components/OOO/Bankrotstvo.vue'

    import LentaIzmeneniy from '@/components/OOO/LentaIzmeneniy.vue'


    import Footer from '@/components/Footer/Footer.vue'
    import HeaderProfile from '@/components/Headers/HeaderProfile.vue'
    import NavigationMenu from '@/components/IP/NavigationMenu.vue'

    export default {
        name: "OOO",
        directives: { mask },
        props: {

        },
        components: {
            LentaIzmeneniy,
            Bankrotstvo,
            Goszakypki,
            Arbitraj,
            Licenses,
            Proverki,
            Info,
            HeaderProfile,
            NavigationMenu,
            Footer,
        },
        data() {
            return {
                project_id: "",

                tab: 0,
                card_info: {},

                items_licenses: [],
                total_licenses: 0,

                items_izmeneniya: {},

                total_dolgi: 0,
                items_dolgi: [],

                items_participant: [],
                items_customer: [],
                total_partipant: 0,
                total_customer: 0,

                items_arbitaj: {},
                items_arbitaj_info: [],
                total_arbitaj: 0,

                total_proverki: 0,
                items_proverki: [],

                zvezdi: 1,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            // async GetInfoCard() {
            //       await axios
            //       .get('/api/v1/ooo/info/')
            //       .then(response => {
            //             this.card_info = response.data.body
            //             console.log(this.card_info)
            //       })
            //       .catch(error => {
            //           console.log(error)
            //       })            
            // },
            async GetInfoCard(uid) {
                  await axios
                  .get(`/api/v1/ooo/info/${uid}`)
                  .then(response => {
                        this.card_info = response.data.body
                  })
                  .catch(error => {
                      console.log(error)
                  })
            },
            async GetParticipant(inn) {
                  await axios
                  .get(`/api/v1/ooo/zakupki/participant/${inn}`)
                  .then(response => {
                        this.items_participant = response.data.body.docs
                        this.total_partipant = response.data.body.total
                        console.log(response.data)
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            },  
            async GetCustomer(inn) {
                  await axios
                  .get(`/api/v1/ooo/zakupki/participant/${inn}`)
                  .then(response => {
                        this.items_customer = response.data.body.docs
                        this.total_customer = response.data.body.total
                        console.log(response.data)
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetProverki(uid) {
                  await axios
                  .get(`/api/v1/ooo/proverki/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_proverki = response.data.body.docs
                        this.total_proverki = response.data.body.total
                        
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetLicenses(uid) {
                  await axios
                  .get(`/api/v1/ooo/licenses/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_licenses = response.data.items
                        this.total_licenses = response.data.total
                        
                  })
                  .catch(error => {
                      console.log(error)
                  })
            }, 
            async GetListingArbitraj(uid) {
                  await axios
                  .get(`/api/v1/ooo/arbitraj/${uid}`)
                  .then(response => {
                        console.log(response.data)
                        this.items_arbitaj = response.data.body.точно.дела

                        const item_ar = Object.keys(response.data.body.точно.дела)
                        for (let q = 0; q < Object.keys(response.data.body.точно.дела).length; q++) {
                            if (q < 2) {
                                const item1 = item_ar[q]
                                const item2 = response.data.body.точно.дела[item1]
                                this.items_arbitaj_info.push(item2)
                            }
                        } 

                        this.total_arbitaj = response.data.body.точно.всего
                  })
                  .catch(error => {
                      console.log(error)
                  })
            },             
        },
        watch: {

        },
        created() {
            this.project_id = this.$route.query.project_id

            if (this.project_id) {
                this.GetInfoCard(this.project_id)
                this.GetLicenses(this.project_id)
                this.GetListingArbitraj(this.project_id)
                this.GetProverki(this.project_id)
                // this.GetIzmenenyia(this.project_id)

                setTimeout(() => {
                    if (this.card_info) {

                        if (this.card_info.ИННФЛ) {
                            this.GetParticipant(this.card_info.ИННФЛ)
                            this.GetCustomer(this.card_info.ИННФЛ)
                        }
                    }
                }, 550)
            }
        }
   }
</script>