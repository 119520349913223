<template>
    <div class="inner-page">
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="product" v-if="total_dolgi != 0">
            <h1>Исполнительные производства ({{ total_dolgi }})</h1>
            <div class="product__wrapper">
                <div class="product__row product__title">
                    <div class="">
                        <p>Номер дела, дата возбуждения </p>
                    </div>
                    <div class="">
                        <p>Предмет исполнения</p>
                    </div>
                    <div class="">
                        <p>Сумма</p>
                    </div>
                    <div class="">
                        <p>Тип исполнительного документа</p>
                    </div>
                    <div class="">
                        <p>Отдел судебных приставов</p>
                    </div>
                </div>
                <div class="product__row" v-for="list in items_dolgi">
                    <div class="">
                        <p>{{ list.НомИспПроизв }}</p>
                        <p>{{ DateConvert(list.ДатаВозбуждения) }}</p>
                    </div>
                    <div class="">
                        <p class="product__blue">{{ list.ПредметИсп }}</p>
                    </div>
                    <div class="">
                        <p><span>Сумма, руб:</span> {{ list.СуммаДолга }} руб.</p>
                    </div>
                    <div class="">
                        <p><span>Реквизиты исполнительного документа: </span>{{ list.ТипИспДок }}</p>
                    </div>
                    <div class="">
                        <p><span>Отдел судебных приставов:</span>{{ list.ОтделСудебПрист }}</p>
                    </div>
                </div>
            </div>
<!--             <ul class="pagination">
                <li class="active">1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
            </ul> -->
        </div>
        <div v-else class="none__databody">
            <p>Исполнительных производств найдено (0)</p>
        </div>        
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "More",
        directives: { mask },
        props: {
            card_info: Object,
            items_dolgi: [Object, Array],
            total_dolgi: Number,
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            DateConvert(value) {
                var date = new Date(value*1000)
                return moment(date).locale('ru').format('L')
            },
        },
        created() {

        },
        watch: {

        },        
   }
</script>