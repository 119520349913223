<template>
    <HeaderProfile />
    <section class="acc">
        <div class="container">
            <SideProfile />
            <div class="acc__container" v-if="!loader">
                <section class="favourite" v-if="favorite.length != 0">
                    <h1>Избранное</h1>
                    <p>В избранном <span>{{ total }}</span></p>
                    <div class="favourite__wrapper">
                        <div class="favourite__item" v-for="(list, index) in favorite" >
                            <div class="favourite__name">
                                <h3 class="pointer" @click="GoQuery(list.project_id, list.type_project)">{{ list.title }}</h3>
                                <a class="pointer" @click="UnFav(list.id, index)">
                                    <img src="@/assets/icons/fav_active.svg" alt="">
                                </a>
                            </div>
                            <div class="favourite__row">
                                <div v-if="list.inn">
                                    <p class="reg">ИНН</p>
                                    <p class="bold_s">{{ list.inn }}</p>
                                </div>
                                <div v-if="list.kpp">
                                    <p class="reg">КПП</p>
                                    <p class="bold_s">{{ list.kpp }}</p>
                                </div>
                                <div v-if="list.innfl">
                                    <p class="reg">ИННФЛ</p>
                                    <p class="bold_s">{{ list.innfl }}</p>                                 
                                </div>
                            </div>
                            <p class="info__block">{{ list.status }}</p>
                        </div>
                    </div>
                    <button v-show="next != null" @click="GetFavoritePanigation()" class="btn__gray">Показать ещё</button>
                </section>
                <section v-else class="favourite favorite-clear">
                    <h1 style="margin-bottom: 10px;">В избранном пока пусто</h1>
                    <h6>Добавляйте контрагентов в закладки, сохраняйте данные <br> и быстро возвращайтесь к нужным запросам</h6>
                    <div>
                        <img src="@/assets/img/favorite__image.svg">
                    </div>
                </section>
            </div>
            <section class="req" v-else>
                <div class="loader__block">
                    <div class="loader-wait">
                        <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
                    </div>         
                </div>                   
            </section>             
        </div>
    </section>
    <Footer />   
</template>

<style scoped>
    .favorite-clear {
        width: 100%; 
        height: 100%; 
        display: block;
    }

    .favorite-clear div {
        margin: 20px 0px;
    }
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    import Footer from '@/components/Footer/Footer.vue'
    import HeaderProfile from '@/components/Headers/HeaderProfile.vue'
    import SideProfile from '@/components/SideMenu/SideProfile.vue'

    export default {
        name: "Favorite",
        components: {
            HeaderProfile,
            SideProfile,
            Footer
        },
        data() {
            return {
                loader: true,

                favorite: [],
                next: "",
                total: 0,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            GoQuery(object_id, object_type) {
                if (object_type == 'ul') {
                    this.$router.push({path: '/ooo', query: { project_id: object_id }})
                } else {
                    this.$router.push({path: '/ip', query: { project_id: object_id }})
                }
            },
            async GetFavoriteInfo() {
                var next_page = ""

                await axios
                    .get('/api/v1/favorite/')
                    .then(response => {
                        setTimeout(() => {
                            this.loader = false
                        }, 500)
                        this.favorite = response.data.results
                        this.total = response.data.count
                        this.next = response.data.next

                        if (this.next != null) {
                            next_page = this.next.split("/")
                            this.next_page_pagination = next_page[6]
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async GetFavoritePanigation() {
                var next_page = ""

                await axios
                    .get(`/api/v1/favorite/${this.next_page_pagination}`)
                    .then(response => {
                        this.next = response.data.next

                        if (this.next != null) {
                            next_page = this.next.split("/")
                            this.next_page_pagination = next_page[6]
                        }

                        for (var i = 0; i < response.data.results.length; i++) {
                            const item = response.data.results[i]
                            this.favorite.push(item)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },     
            async UnFav(list_id, index) {
                await axios
                .delete(`/api/v1/favorite/delete/${list_id}/`)
                .then(response => {
                    this.favorite.splice(index, 1)
                    this.total = this.total - 1
                })
                .catch(error => {
                  console.log(error)
                })
            },
        },
        created() {
            this.GetFavoriteInfo()
        },
        watch: {

        },   
    }
</script>