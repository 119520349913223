<template>
    <Header />
    <div class="full__white">
            <section class="ceo-title ">
                <div class="container">
                    <h1>Арбитражный суд РФ</h1>
                    <p>Арбитражный суд является регулирующим органом специальной юрисдикции и наделен особыми полномочиями на основании законодательства РФ.</p>    
                </div>
            </section>
            <section class="arb-court">
                <div class="container">
                    <img src="@/assets/img/arbitration-court.png" alt="">
                    <div class="arb-court__info">
                        <h2>В его компетенцию входит:</h2>
                        <ul>
                            <li>Урегулирование экономических споров между коммерческими предприятиями, ИП, государством и его субъектами</li>
                            <li>Установление фактов, имеющих решающее значение для изменения или прекращения деятельности предприятий в области коммерции</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="potential potential__ceo">
                <img src="@/assets/img/potential_1.svg" alt="">
                <img src="@/assets/img/potential_2.svg" alt="">
                <div class="container">
                    <p>С помощью сервиса DATAFIX каждый Пользователь может проверить контрагента и получить о нем полную информацию, собранную из официальных открытых источников (ЕГРЮЛ, ЕГРИП, БОО, Арбитражный Суд, ФССП).</p>
                    <p>Данная информация необходима для принятия важных решений при выборе поставщика товаров или услуг, а также при других ситуациях, когда необходимо получить достоверные данные о юридических лицах или индивидуальных предпринимателях, зарегистрированных в Российской Федерации.</p>
                </div>
            </section>
            <section class="ceo-list">
                <div class="container">
                    <h2>Сервис предоставляет данные по арбитражу за все время существования компании:</h2>
                    <ul>
                        <li>
                            <p>Участники разбирательства</p>
                        </li>
                        <li>
                            <p>К какой категории относится дело</p>
                        </li>
                        <li>
                            <p>Предмет дела</p>
                        </li>
                        <li>
                            <p>Исковая сумма</p>
                        </li>
                        <li>
                            <p>Состояние дела или его результат</p>
                        </li>
                    </ul>
                </div>
            </section>
            <div class="ceo-info">
                <div class="container">
                    <div class="ceo-info__text">
                        <p>Анализ арбитражных дел поможет понять, какими суммами распоряжается организация, насколько порядочна она при взаимодействии с контрагентами и налоговыми органами, а также выявить наличие у нее текущих споров или процесса банкротства.</p>
                        <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
                    </div>
                    <div class="ceo-info__img">
                        <img src="@/assets/img/analysis.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Arbitration',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>

<style scoped></style>
