<template>
    <div class="modal" :class="{'modal__active': tarif__modal}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper" v-if="first__tarif">
            <h2>Оплата подписки</h2>
<!--             <p class="reg" v-if="!isAuthenticated">После оплаты на указанную почту придут данные для входа в ваш автоматически созданный аккаунт</p> -->
            <div class="modal__pay" v-if="current_tarif == 1">
                <p>Стандарт <span>1 месяц</span></p>
                <p>{{ current_price }} ₽</p>
            </div>
            <div class="modal__pay" v-if="current_tarif == 2">
                <p>Премиум <span>3 месяца</span></p>
                <p>{{ current_price }} ₽</p>
            </div>
            <div class="modal__pay" v-if="current_tarif == 3">
                <p>Безлимит <span>1 год</span></p>
                <p>{{ current_price }} ₽</p>
            </div>
            <div class="modal__reg" v-if="!isAuthenticated">
                <input type="text" v-model="fio" placeholder="ФИО*" />
                <div class="modal__reg_error">
                    <input type="email" v-model="email" @blur="validateEmail" placeholder="E-mail*">
                    <p v-show="validateEmailCh">Почта некорректна</p>
                </div>
                <div class="modal__reg_error">
                    <input type="password" v-model="password" placeholder="Пароль*">
                </div>
                <div class="modal__reg_error">
                    <input type="password" v-model="password2" placeholder="Повторите пароль*">
                    <p v-if="password2" v-show="password != password2">Данные не совпадают</p>
                </div>
                <div class="modal__reg_phone">
                    <input v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'" placeholder="+7 (999) 999-99-99*">
                    <a class="pointer">
                        <img src="@/assets/icons/rus.svg" alt="">
                    </a>
                </div>
                <div class="modal__reg_error" v-if="errors.length != 0">
                    <p v-show="errors">{{ errors[0] }}</p>
                </div>
            </div>
            <div class="modal__reg modal__reg_error">
                <input v-model="promocode" type="text" placeholder="Промокод">
                <p v-if="error_promo.length != 0" v-show="error_promo">{{ error_promo[0] }}</p>
            </div>
            <div v-if="!isAuthenticated">
                <div v-if="fio && !validateEmailCh && password && (password2 == password) && phone">
                    <button class="btn__green pointer" @click="RedirectToPay()">Перейти к оплате</button>
                </div>
                <div v-else>
                    <button class="btn__green pointer" disabled>Перейти к оплате</button>
                </div>
            </div>
            <div v-else>
                <button class="btn__green pointer" @click="RedirectToPay()">Перейти к оплате</button>
            </div>
            
            <p class="modal__ver">Нажимая кнопку «Перейти к оплате», я соглашаюсь с условиями <a href="https://datafix.tech/datafix_politika.pdf" target="_blank">политики конфиденциальности</a> и <a href="https://datafix.tech/datafix_pesonal.pdf" target="_blank">пользовательского соглашения</a></p>
            <div class="modal__close pointer" @click="CloseModalTarif()"></div>
        </div>
        <div class="modal__wrapper" v-else>
            <h2>Заполните данные*</h2>
            <div class="modal__pay" v-if="current_tarif == 1">
                <p>Стандарт <span>1 месяц</span></p>
                <p>{{ current_price }} ₽</p>
            </div>
            <div class="modal__pay" v-if="current_tarif == 2">
                <p>Премиум <span>3 месяца</span></p>
                <p>{{ current_price }} ₽</p>
            </div>
            <div class="modal__pay" v-if="current_tarif == 3">
                <p>Безлимит <span>1 год</span></p>
                <p>{{ current_price }} ₽</p>
            </div>
            <div class="modal__reg">
                <input v-model="cardNumber" type="tel" v-mask="'#### #### #### ####'" placeholder="Номер карты">
                <div class="modal__flex_reg">
                  <input v-model="cardDate" type="tel" v-mask="'##/##'" placeholder="Срок действия">
                  <input v-model="cvv" type="tel" v-mask="'###'" placeholder="CVV">                  
                </div>
                <input v-model="name_card" type="text" placeholder="Имя держателя">
            </div>
            <button class="btn__green pointer" @click="PaySub()">Оплатить</button>
            <p class="modal__ver">Нажимая кнопку «Оплатить», я соглашаюсь с условиями <a href="https://datafix.tech/datafix_politika.pdf" target="_blank">политики конфиденциальности</a> и <a href="https://datafix.tech/datafix_pesonal.pdf" target="_blank">пользовательского соглашения</a></p>
            <div class="modal__close pointer" @click="CloseModalTarif()"></div>
        </div>  
    </div>


    <div class="modal" :class="{'modal__active': loader}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
<!--             <div class="modal__close pointer"></div> -->
        </div>
    </div>   

</template>

<script>
import axios from 'axios'

import moment from 'moment';
import { mask } from 'vue-the-mask'
import { toast } from 'bulma-toast'

export default {
	name: 'ModalSub',
    directives: { mask },
    props: {
        tarif__modal: {
            type: Boolean,
            default: false,
        },
        current_price: {
            type: Number,
            default: 0
        },
        current_tarif: {
            type: Number,
        },
        prom: {
            type: String,
            default: "none",
        },
    },
    data() {
        return {
            first__tarif: true,

            fio: "",
            phone: "",
            email: "",
            password: "",
            password2: "",

            email_log: "",
            pass_login: "",

            errors: [],
            id_new_user: 0,

            user_account: {},

            validateEmailCh: false,
            isAuthenticated: false,

            cardNumber: '',
            expDateMonth: '',
            expDateYear: '',
            cvv: '',
            name_card: '',
            cardDate: '',
            promocode: '',
            promo: {},

            error_promo: [],

            date_start: "",
            date_end: "",

            subOrder: {},
            card_current: {},

            itog_price: null,

            loader: false,
            ip: "123.123.123.123",

            request_num: 0,

            user: {},
        }
    },
    mounted() {
        
    },
    beforeCreate() {
        
    },
    methods: {
      async GetMeInfo() {
        await axios
            .get('/api/v1/me/')
            .then(response => {
                this.user = response.data
            })
            .catch(error => {
                console.log(error)
            })
      },
      RedirectToPay() {
        if (!this.isAuthenticated) {
            this.submitForm()
        } else {
            this.first__tarif = false
            this.RequestsChecker()
        }
        
        if (this.promo.id == undefined) {
            if (this.prom == "none") {
                this.CheckPromo()
            }
        }

      },
      RequestsChecker() {
        if (this.current_tarif == 1) {
            this.request_num = 900
        }

        if (this.current_tarif == 2) {
            this.request_num = 3000
        }

        if (this.current_tarif == 3) {
            this.request_num = 9999
        }
      },
      Price() {
        if (this.current_tarif == 1) {
            this.itog_price = 350
        }

        if (this.current_tarif == 2) {
            this.itog_price = 9900
        }

        if (this.current_tarif == 3) {
            this.itog_price = 49900
        }
      },
      PriceUpdate() {
        if (this.current_tarif == 1) {
            this.$emit('price', 350)
        }

        if (this.current_tarif == 2) {
            this.$emit('price', 9900)
        }

        if (this.current_tarif == 3) {
            this.$emit('price', 49900)
        }
      },
      errorAlert() {
          for (let i = 0; i < this.errors.length; i++) {
              const message_error = this.errors[i]
              toast({
                  message: message_error,
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 2000,
                  position: 'bottom-right',
              })
          }
      },
      validateEmail() {
          if (/@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
              this.validateEmailCh = false
          } else {
              this.validateEmailCh = true
          }
      },
      async CheckPromo() {
          this.promo = {}

          await axios
          .get(`/api/v1/promocode/${this.promocode}/`)
          .then(response => {
              this.error_promo = []
              this.promo = response.data

              if (this.promo) {
                if (response.data.request_num == 0) {
                    this.error_promo.push('Срок действия промокода закончился.')
                }

                if (this.promo.sub != this.current_tarif) {
                    this.error_promo.push('Промокод не действителен для данного тарифа.')
                }

                if (this.error_promo.length == 0) {
                    this.Price()
                    const price = (parseInt(this.current_price) * (100 - this.promo.percent)) / 100;
                    if (this.current_price == this.itog_price) {
                        this.$emit('price', price)
                    }

                    if (Number(price) == 0) {
                        this.$emit('price', 1)
                    }
                }
              }
              
          })
          .catch(error => {
            this.error_promo.push('Промокода не существует.')
            this.PriceUpdate()
          })
      },
      async PromoUpdate(promo) {
            if (Number(promo.request_num) != 0) {
                const NewData = {
                    name: promo.name,
                    duration: promo.duration,
                    percent: promo.percent,
                    request_num: Number(promo.request_num) - 1
                }

                await axios
                .put(`/api/v1/promocode/${this.promocode}/`, NewData)
                .then(response => {
                  console.log(response)
                })
                .catch(error => {
                  console.log(error)
                })
            }
      },
      async SendEmailReg(value) {
            await axios
            .post(`/api/v1/send_reg_email/`, {email: value})
            .then(response=> {
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
      },
      async SendSubActiveEmail(value) {
            await axios
            .post(`/api/v1/send_sub_active_email/`, {email: value})
            .then(response=> {
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
      },
      async GetIP() {
          await axios
          .get(`/api/v1/get_client_ip/`)
          .then(response => {
              this.ip = response.data
          })
          .catch(error => {
              console.log(error)
          })
      },
      PaySub() {
        const checkout = new cp.Checkout({
            publicId: 'pk_3885a360b584f6526a8dfee76b7a2',
        });        

        const data_split = this.cardDate.split('/')
        this.expDateMonth = Number(data_split[0])
        this.expDateYear = Number(data_split[1])

        const fieldValues = {
            cvv: this.cvv,
            cardNumber: this.cardNumber,
            expDateMonth: this.expDateMonth,
            expDateYear: this.expDateYear,
        }

        checkout.createPaymentCryptogram(fieldValues)
            .then((cryptogram) => {
                this.PaymantReady(cryptogram)
            }).catch((errors) => {
                console.log(errors)
            });

      },
      async PaymantReady(cryptogram) {
          this.RequestsChecker()
          
          const today = new Date();
          const year = moment(String(today)).format('YYYY')
          const moth = moment(String(today)).format('MM')
          const day = moment(String(today)).format('DD')
    
          const currentData = year + '-' + moth + '-' + day

          if (this.current_tarif == 1) {
            const newmoth = moment().add(1, 'months').format('MM');
            this.date_end = year + '-' + newmoth + '-' + day
          }

          if (this.current_tarif == 2) {
            const newmoth = moment().add(3, 'months').format('MM');
            this.date_end = year + '-' + newmoth + '-' + day
          }

          if (this.current_tarif == 3) {
            const newyear = moment().add(1, 'years').format('YYYY');
            this.date_end = newyear + '-' + moth + '-' + day
          }
          
          this.date_start = currentData;

          const data = {
              "amount": this.current_price,
              "currency":"RUB",
              "ip_address": this.ip,
              "name": this.name_card,
              "cryptogram": cryptogram,
              "sub": this.current_tarif,
              "price": this.current_price,
              "date_start": this.date_start,
              "date_end": this.date_end,
              "request_num": this.request_num,
              "request_num_all": this.request_num,
          }

          const headersData = {
            'Content-Type': 'application/json',
          }

          await axios
          .post('/api/v1/create/order/', data, {headers: headersData})
          .then(response => {
              this.subOrder = response.data

              if(this.subOrder.confirm_link) {
                  this.$emit('modal', false)
                  this.loader = true
                  this.CheckPromo()

                  if (!this.isAuthenticated) {
                    this.SendSubActiveEmail(this.email)
                  } else {
                    this.SendSubActiveEmail(this.user.email)
                  }

                  setTimeout(() => {
                     this.PromoUpdate(this.promo)
                     this.sendFormConfirm(this.subOrder)
                  }, 5000)
              }
          })
          .catch(error => {
              console.log(error)
          })
      },
      submittedDownloadForm(e) {
        console.log(e);
      },
      sendFormConfirm(acsFormData) {
        const form = document.createElement("form");
        form.action = acsFormData.confirm_link;
        form.method = "post";
        form.name = "downloadForm";
        form.onsubmit = this.submittedDownloadForm;
        const transactionId = document.createElement("input");
        transactionId.name = "MD";
        transactionId.type = "hidden";
        transactionId.value = acsFormData.id_pay;
        const termUrl = document.createElement("input");
        termUrl.name = "TermUrl";
        termUrl.type = "hidden";
        termUrl.value = "https://datafix.tech/api/v1/payment/redirect";
        const paReq = document.createElement("input");
        paReq.name = "PaReq";
        paReq.type = "hidden";
        paReq.value = acsFormData.pa_req;
        form
          .appendChild(paReq)
          .appendChild(transactionId)
          .appendChild(termUrl);
        document.body.append(form);
        form.submit();
      },
      async submitForm() {
          this.errors = []

          if (!this.errors.length) {
              const formData = {
                  username: this.email,
                  password: this.password,
                  email: this.email,
              }

              await axios
                  .post("/api/v1/users/", formData)
                  .then(response => {
                      this.id_new_user = response.data.id
                  })
                  .catch(error => {
                      if (error.response) {
                          for (const property in error.response.data) {
                              this.errors.push(`${error.response.data[property]}`)

                              this.errorAlert();
                          }
                      } else if (error.message) {
                          this.errors.push('Попробуйте еще раз, произошла ошибка')

                          this.errorAlert();
                      }
                  })
              
              if (this.id_new_user != 0) {
                  const UserData = {
                      user: this.id_new_user,
                      fio: this.fio,
                      email: this.email,
                      phone: this.phone,
                  }

                  await axios
                      .post("/api/v1/myProfiles/", UserData)
                      .then(response => {
                          this.user_account = response.data
                      })
                      .catch(error => {
                          console.log(error)
                      })

                  axios.defaults.headers.common["Authorization"] = ""

                  localStorage.removeItem("token")

                  await axios
                  .post("/api/v1/token/login/", formData)
                  .then(response => {
                      const token = response.data.auth_token

                      this.SendEmailReg(this.user_account.email)

                      this.$store.commit('setToken', token)
                      
                      axios.defaults.headers.common["Authorization"] = "Token " + token

                      localStorage.setItem("token", token)

                      this.$store.commit('setUser', this.user_account.id)

                      localStorage.setItem("user_id", this.user_account.id)

                      localStorage.setItem("user", this.user_account)

                      this.$store.commit('setUserInfo', this.user_account)

                      this.first__tarif = false
                  })
                  .catch(error => {
                      if (error.response) {
                          for (const property in error.response.data) {
                              this.errors.push(`${property}: ${error.response.data[property]}`)
                          }
                          this.errorAlert();
                      } else {
                          this.errors.push('Что-то пошло не так...')

                          this.errorAlert();
                          
                          console.log(JSON.stringify(error))
                      }
                  })
              }

          }
      },  
      CloseModalTarif() {
        this.$emit('modal', false)
        this.$emit('promo', 'none')
        this.first__tarif = true
        this.promocode = ""
        this.error_promo = []
      },
      AutoFull() {
        console.log(this.card_current)

            // cvv: this.cvv,
            // cardNumber: this.cardNumber,
            // expDateMonth: this.expDateMonth,
            // expDateYear: this.expDateYear,
      }
    },
    watch: {
        promocode(value) {
            this.promocode = value

            if (this.promocode != this.prom) {
                this.CheckPromo()
            } else {
                this.error_promo = []
            }
        },
        prom(value) {
            if (this.prom) {
                this.promocode = this.prom
            }
        },
    },
    created() {
        this.card_current = this.$store.state.card_current
        this.isAuthenticated = this.$store.state.isAuthenticated

        if (this.isAuthenticated) {
            this.GetMeInfo()
        }
        

        if (Object.keys(this.card_current).length != 0) {
            this.AutoFull()
        }
        this.GetIP()
    }
}
</script>