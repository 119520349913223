<template>
    <div class="inner-page">
        <div class="inner-page__title">
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="finance" v-if="Object.keys(finance)[0]">
            <h1>Финансы</h1>
            <h3>{{ Object.keys(finance)[0] }}</h3>
            <div class="finance__block">
                <h4>{{ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] }}</h4>
                <div class="finance__wrapper">
                    <div class="finance__table">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[0] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[0]}`])[0]}`]">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[0]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="finance__table" v-if="Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[1]">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[1] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[0]}`])[1]}`]">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[0] ])[1]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="finance__block">
                <h4>{{ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] }}</h4>
                <div class="finance__wrapper">
                    <div class="finance__table">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`])[0] }`] ">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="finance__table">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[1] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`])[1] }`] ">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[1]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="finance__table">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[2] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`])[2] }`] ">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[2]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="finance__table" v-if="Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[3]">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[3] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[0]}`])[3] }`] ">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ][`${Object.keys(finance[`${Object.keys(finance)[0]}`][ Object.keys(finance[`${Object.keys(finance)[0]}`])[1] ])[3]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3>{{ Object.keys(finance)[1] }}</h3>
            <div class="finance__block">
                <div class="finance__wrapper">
                    <div class="finance__table">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[1]}`])[0] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[0]}`])[0]}`]">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[0]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="finance__table">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[1]}`])[1] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[0]}`])[1]}`]">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[1]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="finance__table" v-if="Object.keys(finance[`${Object.keys(finance)[1]}`])[2]">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[1]}`])[2] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[0]}`])[2]}`]">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[1]}`][`${Object.keys(finance[`${Object.keys(finance)[1]}`])[2]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 v-if="Object.keys(finance)[2]">{{ Object.keys(finance)[2] }}</h3>
            <div class="finance__block" v-if="Object.keys(finance)[2]">
                <div class="finance__wrapper">
                    <div class="finance__table">
                        <div class="finance__title finance__row">
                            <div class="">
                                <p>{{ Object.keys(finance[`${Object.keys(finance)[2]}`])[0] }}</p>
                            </div>
                            <div v-for="(list, index) in finance[`${Object.keys(finance)[2]}`][`${Object.keys(finance[`${Object.keys(finance)[2]}`])[0]}`][`${Object.keys(finance[`${Object.keys(finance)[2]}`][`${Object.keys(finance[`${Object.keys(finance)[2]}`])[0]}`])[0]}`]">
                                <p>{{ index }}</p>
                            </div>
                        </div>
                        <div class="finance__row" v-for="(list, index) in finance[`${Object.keys(finance)[2]}`][`${Object.keys(finance[`${Object.keys(finance)[2]}`])[0]}`]">
                            <div class="">
                                <p>{{ index }}</p>
                            </div>
                            <div class="" v-for="item in list">
                                <p>{{ Number(item).toLocaleString('ru-RU') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="finance" v-else>
            <h1>Финансы</h1>
            <p>Данных нет</p>            
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Connect",
        directives: { mask },
        props: {
            card_info: Object,
        },
        components: {

        },
        data() {
            return {
                items: [],
                finance: {},
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            
        },
        methods: {
            async GetFinance(uid) {
                  await axios
                  .get(`/api/v1/ooo/finance/${uid}`)
                  .then(response => {
                        this.finance = response.data.body
                        
                  })
                  .catch(error => {
                      console.log(error)
                  })
                 
            },            
        },
        created() {
            this.project_id = this.$route.query.project_id
            this.GetFinance(this.project_id)
        },
        watch: {

        },        
   }
</script>