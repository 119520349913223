<template>
    <Header />
    <div class="full__white">
        <section class="ceo-title ceo-title__affil">
            <div class="container">
                <h1>Аффилированность</h1>
                <p>В законодательстве РФ уделяется отдельное внимание понятию аффилированности и взаимозависимости. Впервые это понятие было определено в РСФСР еще в 1991 году в целях борьбы с монополизмом и создания условий для добросовестной конкуренции.</p>
                <img src="@/assets/img/affil-title.png" alt="" class="ceo-title__affil_bg">
            </div>
        </section>
        <section class="potential potential__ceo">
            <img src="@/assets/img/potential_1.svg" alt="">
            <img src="@/assets/img/potential_2.svg" alt="">
            <div class="container">
                <p>В Налоговом Кодексе РФ определению взаимозависимости отведено 11 пунктов статьи 105.1. Аффилированность и взаимозависимость в современных условиях надо обязательно проверять, особенно, если речь идет о крупных проектах.</p>
                <p>Это поможет избежать рисков взаимодействия с компаниями, которые уходят от налогов через аффилированные фирмы, т. к. рано или поздно налоговая служба обратит на них внимание, и вы тоже попадете в круг проверок, как косвенный участник. Аффилированность нередко становится способом банального хищения средств из госбюджета и коммерческих организаций.</p>
            </div>
        </section>
        <section class="affril-list">
            <div class="container">
                <h2>4 причины для проверки аффилированности:</h2>
                <ul>
                    <li>Снижение риска увода финансов и увеличения расходов</li>
                    <li>Выявление мошеннических схем при сделках</li>
                    <li>Исключение ненужного взаимодействия с госорганами в части обоснованности ценообразования</li>
                    <li>Исключение участия в судебных спорах и оплаты штрафов, если налоговая инспекция придет к выводу, что стоимость товаров/услуг необоснованно завышена и за точку отсчета примет рыночные цены</li>
                </ul>
            </div>
        </section>
        <div class="ceo-info ceo-info__affil">
            <div class="container">
                <div class="ceo-info__text">
                    <p>Для выявления аффилированности сервис работает по алгоритму, который позволяет выявить связи между компаниями на основании совпадения руководителей, учредителей, акционеров, учрежденных организаций, адресов и телефонов.</p>
                    <p>Вы сможете разобраться в схеме бизнеса контрагента и понять, кто является фактическим владельцем компании. Это особенно важно, когда проверяемая фирма принадлежит другой компании или сама имеет несколько «дочек». Для удобства пользователей можно анализировать полученные данные с помощью фильтра.</p>
                    <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
                </div>
                <div class="ceo-info__img">
                    <img src="@/assets/img/affil.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Affiliation',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
    GoTo(redirect) {
      this.$router.push(redirect)
    },
  },
}
</script>
