<template>
    <Header />
    <div class="full__white">
      <section class="main-header WowCss">
          <div class="container">
              <div class="main-header__info">
                  <h1>Сервис проверки контрагентов на благонадежность</h1>
                  <p>Помогаем быстро и эффективно оценить надежность контрагента</p>
                  <button class="btn__green" @click="scrollToSubs()">Изучить тарифы</button>
              </div>
              <div class="main-header__img">
                  <img src="@/assets/img/main-header_1.svg" alt="">
                  <img src="@/assets/img/main-header_2.svg" alt="">
                  <img src="@/assets/img/main-header_3.svg" alt="">
                  <img src="@/assets/img/main-header_4.svg" alt="">
                  <img src="@/assets/img/main-header_5.svg" alt="">
              </div>
          </div>
      </section>
      <section class="main-har WowCss">
          <div class="container">
              <div class="main-har__item">
                  <p>28 361 523 компаний и <br> ИП</p>
                  <p>По состоянию базы на сегодня</p>
              </div>
              <div class="main-har__item">
                  <p>Более 20 <br> параметров оценки</p>
                  <p>41 официальный источник данных</p>
              </div>
              <div class="main-har__item">
                  <p>Самые выгодные <br> тарифы</p>
                  <p>Ежемесячный тариф от 350 ₽ за 1 месяц</p>
              </div>
          </div>
      </section>
      <section class="main-est WowCss">
          <div class="container">
              <img src="@/assets/img/main-est_bg.svg" alt="" class="main-est__bg">
              <h2>Удобная оценка контрагентов по всем ключевым сведениям</h2>
              <div class="main-est__wrapper">
                  <img src="@/assets/img/est-img.svg" alt="" class="main-est__img">
                  <div class="main-est__grid">
                      <div class="main-est__item">
                          <img src="@/assets/icons/est_1.svg" alt="">
                          <h3>Рейтинг надежности</h3>
                          <p>41 источник данных, надежные сведения по каждой компании</p>
                      </div>
                      <div class="main-est__item">
                          <img src="@/assets/icons/est_2.svg" alt="">
                          <h3>Судебные дела</h3>
                          <p>Анализируйте судебные процессы и задолженности</p>
                      </div>
                      <div class="main-est__item">
                          <img src="@/assets/icons/est_3.svg" alt="">
                          <h3>Аффилированность</h3>
                          <p>Поиск связей, визуализация структуры владения</p>
                      </div>
                      <div class="main-est__item">
                          <img src="@/assets/icons/est_4.svg" alt="">
                          <h3>Финансы</h3>
                          <p>Графический анализ финансово-экономических показателей компании </p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="interface WowCss">
          <div class="container">
              <h2>Удобный интерфейс</h2>
              <div class="interface__wrapper">
                  <ul>
                      <li v-for="(list, index) in interfaces" @click="TabChoose(index)" 
                        class="pointer" 
                        :class="{'active': tab_id == index}"
                      >{{ list.name }}</li>
                  </ul>
                  <div class="interface__block">
                      <img :src="interfaces[tab_id].img" alt="" class="interface__img">
                      <div class="interface__info">
                          <h3>{{ interfaces[tab_id].name }}</h3>
                          <p>{{ interfaces[tab_id].disk }}</p>
                          <button class="btn__gray" @click="Link(interfaces[tab_id].link)">Подробнее</button>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="visual WowCss">
          <div class="container">
              <div class="visual__history">
                  <div class="visual__item">
                      <img src="@/assets/icons/visual_1.svg" alt="">
                      <h3>Избранные компании</h3>
                      <p>Добавляйте в закладки, чтобы сохранить данные</p>
                  </div>
                  <div class="visual__item">
                      <img src="@/assets/icons/visual_2.svg" alt="">
                      <h3>История запросов</h3>
                      <p>Все проверенные вами компании сохраняются в личном кабинете</p>
                  </div>
              </div>
              <h2>Визуализация связей</h2>
              <div class="visual__img">
                  <img src="@/assets/img/visual_1.svg" alt="">
                  <img src="@/assets/img/visual_2.svg" alt="">
                  <img src="@/assets/img/visual_3.svg" alt="">
              </div>
          </div>
      </section>
      <section class="main-banner WowCss">
          <div class="container">
              <div class="main-banner__wrapper">
                  <h2>Проверка контрагентов по базе из более 25 000 000 компаний</h2>
                  <p>Помогаем быстро и эффективно оценить надежность ваших бизнес-партнеров</p>
                  <img src="@/assets/img/main-banner.png" alt="" class="main-banner__desktop">
                  <img src="@/assets/img/main-banner_mob.png" alt="" class="main-banner__mob">
              </div>
          </div>
      </section>
      <section class="rate rate-main WowCss" ref="subs">
          <div class="container">
              <h2>Тарифы</h2>
              <div class="rate__wrapper">
                  <div class="rate__item">
                      <h2>Стандарт</h2>
                      <div class="rate__price">
                          <p>350 ₽</p>
                          <p>/ 1 месяц</p>
                      </div>
                      <ul>
                          <li><p>900 <span>запросов в месяц</span></p></li>
                          <li><p>1 <span>устройство</span></p></li>
                      </ul>
                      <button class="btn__green pointer" @click="TarifModal(1)">Выбрать тариф</button>
                  </div>
                  <div class="rate__item rate__item_rec">
                      <div class="rate__rec">
                          <p class="reg">рекомендуем</p>
                      </div>
                      <h2>Премиум</h2>
                      <div class="rate__price">
                          <p>9900 ₽</p>
                          <p>/ 3 месяца</p>
                      </div>
                      <ul>
                          <li><p>3000 <span>запросов в месяц</span></p></li>
                          <li><p>неограниченное <span>количество устройств</span></p></li>
                      </ul>
                      <button class="btn__green pointer" @click="TarifModal(2)">Выбрать тариф</button>
                  </div>
                  <div class="rate__item">
                      <h2>Безлимит</h2>
                      <div class="rate__price">
                          <p>49 900 ₽</p>
                          <p>/ 1 год (без ндс)</p>
                      </div>
                      <div class="rate__price">
                          <span style="color: rgba(0, 0, 0, .5);">57 500₽ / с ндс 20%</span>
                      </div>
                      <ul>
                          <li><p>неограниченное <span>количество запросов</span></p></li>
                          <li><p>неограниченное <span>количество устройств</span></p></li>
                      </ul>
                      <button class="btn__green pointer" @click="TarifModal(3)">Выбрать тариф</button>
                  </div>
              </div>
          </div>
      </section>
      <section class="quest WowCss" ref="faq">
          <div class="container">
              <h2>Часто задаваемые вопросы</h2>
              <div class="quest__wrapper">
                  <div 
                    class="pointer"
                    v-for="(list, index) in faq"
                    @click="FaqChoose(index)"
                  >
                      <div class="quest__btn">
                        <p>{{ list.name }}</p>
                        <img src="@/assets/icons/quest_arrow.svg" alt="">
                      </div>

                      <div v-show="faq_id == index" class="response__faq">
                        <p>{{ list.disk }}</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="source WowCss" ref="info">
          <div class="container">
              <h2>Информационные источники</h2>
              <div class="source__wrapper" :class="{'source__all_show': show_all}">
                  <div class="source__item">
                      <img src="@/assets/img/source_1.jpg" alt="">
                      <p>Федеральная налоговая служба</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_2.jpg" alt="">
                      <p>Единый федеральный реестр сведений  о банкротстве</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_3.jpg" alt="">
                      <p>Росстат</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_4.jpg" alt="">
                      <p>Верховный суд РФ</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_5.jpg" alt="">
                      <p>Федеральная служба судебных приставов</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_6.jpg" alt="">
                      <p>Реестр представительств иностранных ЮЛ</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_7.jpg" alt="">
                      <p>Федресурс</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_8.jpg" alt="">
                      <p>Центральный банк РФ</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_9.jpg" alt="">
                      <p>Федеральная Нотариальная палата</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_10.jpg" alt="">
                      <p>Роскомнадзор</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_11.jpg" alt="">
                      <p>Роспатент</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_12.jpg" alt="">
                      <p>Федеральное Казначейство РФ</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_13.jpg" alt="">
                      <p>Росфинмониторинг</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_14.jpg" alt="">
                      <p>Роспотребнадзор</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_15.jpg" alt="">
                      <p>Росздравнадзор</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_16.jpg" alt="">
                      <p>Ростехнадзор</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_17.jpg" alt="">
                      <p>Росалкоголь</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_18.jpg" alt="">
                      <p>Росаккредитация</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_19.jpg" alt="">
                      <p>Федеральная Миграционная Служба</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_20.jpg" alt="">
                      <p>Министерство Внутренних Дел</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_21.jpg" alt="">
                      <p>Федеральная Антимонопольная Служба</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_22.jpg" alt="">
                      <p>Торгово-Промышленная палата РФ</p>
                  </div>
                  <div class="source__item">
                      <img src="@/assets/img/source_23.jpg" alt="">
                      <p>Реестр МСП</p>
                  </div>
                  <div class="source__logo">
                      <img src="@/assets/img/i_av.png" alt="">
                      <p>Данные компаний</p>
                  </div>
              </div>
              <button class="btn__gray" v-show="!show_all" @click="ShowAll()">Показать все</button>
          </div>
      </section>
      <section class="main-info">
<!--           <div class="container">
              <div class="main-info__logo">
                  <img src="@/assets/img/main-info__logo.jpg" alt="">
              </div>
              <div class="main-info__info">
                  <p>В Datafix каждый день приходит много обращений и писем. Одно из них запомнилось особенно. Научный сотрудник Нижегородского государственного педагогического института им. К.Минина написал, что работает над статьей о российских эргонимах, производных от имён древнерусских языческих божеств.</p>
                  <p>Эргонимы — это названия различных деловых объединений — организаций, учреждений. На сайте Rusprofile он нашел множество коммерческих фирм и иных организаций, чьи названия являются производными от названий божеств. Например, по запросу «Сварог» он нашел 544 юрлица, а по запросу «Велес» – целых 4276. Так как такой большой объем данных сложно обработать вручную, он попросил о содействии нашего IT-специалиста, который помог бы собрать статистику.</p>
                  <p>Его интересовало : распределение результатов по хронологии возникновения таких организаций, географическое распределение, связь названия организации и вида деятельности. Мы помогли с этим запросом и предоставили необходимые данные. Автор опубликовал статью и поблагодарил нас за содействие.</p>
              </div>
          </div> -->
      </section>
    </div>

    <Footer />

    <ConfirmPassword 
      :modal="confirm_password" 
      :uid="uid"
      :token="token"
      @conf="confirm_password = $event"
    />

    <ModalSub 
      :tarif__modal="tarif__modal" 
      :current_price="current_price"
      :current_tarif="current_tarif"
      v-on:modal="tarif__modal = $event" 
      v-on:price="current_price = $event" 
    />

</template>

<script>
import axios from 'axios'
import moment from 'moment';
import { mask } from 'vue-the-mask'
import { toast } from 'bulma-toast'

import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'
import ModalSub from '@/components/Subs/ModalSub.vue'

import ConfirmPassword from '@/components/Modal/ConfirmPassword.vue'

export default {
  name: 'HomeView',
  directives: { mask },
  components: {
    Header,
    Footer,
    ModalSub,
    ConfirmPassword
  },
  data() {
    return {
      tarif__modal: false,
      current_tarif: null,
      current_price: null,

      show_all: false,
      confirm_password: false,

      tab_id: 0,
      faq_id: 99,
      interfaces: [
        {
            name: 'База данных',
            disk: 'Анализируйте информацию на основании данных, получаемых в режиме реального времени',
            link: '/subs-seo',
            img: require('@/assets/img/data_base.png')
        },
        {
            name: 'Арбитраж',
            disk: 'Анализируйте информацию на основании данных, получаемых в режиме реального времени',
            link: '/arbitration-court',
            img: require('@/assets/img/arbitraj_tab.png')
        },
        {
            name: 'Закупки',
            disk: 'Анализируйте информацию на основании данных, получаемых в режиме реального времени',
            link: '/procurement',
            img: require('@/assets/img/zakupki.png')
        },
        {
            name: 'Аффилированность',
            disk: 'Анализируйте информацию на основании данных, получаемых в режиме реального времени',
            link: '/affiliation',
            img: require('@/assets/img/affilir.png')
        },
        {
            name: 'Проверки',
            disk: 'Анализируйте информацию на основании данных, получаемых в режиме реального времени',
            link: '/realibity-check',
            img: require('@/assets/img/proverki.png')
        },        
        {
            name: 'Отчётность компаний',
            disk: 'Анализируйте информацию на основании данных, получаемых в режиме реального времени',
            link: '/reporting',
            img: require('@/assets/img/otchetnost.png')
        },     
      ],
      faq: [
        {
            name: 'Как выбрать тариф?',
            disk: 'Для выбора тарифа Вам необходимо ознакомиться с условиями предоставления доступа к сервису в разделе «Тарифы» на главной странице сервиса. При выборе тарифа необходимо обратить внимание на возможное количество подключаемых устройств и предельного лимита количества запросов. Например, тариф «Стандарт» позволяет проверить информацию о компаниях 900 раз в месяц с 1 устройства.',
        },
        {
            name: 'Как активировать промокод?',
            disk: 'Для активации промокода вам необходимо нажать на кнопку «Ввести промокод», далее ввести имеющийся у вас промокод и перейти к верификации вашей банковской карты. В случае бесплатного доступа, деньги с карты списываться не будут. В случае отсутствия отключения функции автопродления, после завершения промодоступа дальнейшее списание денег будет произведено в рамках используемого тарифа.',
        },
        {
            name: 'Как проверить компанию?',
            disk: 'Для проверки компании необходимо выбрать подходящий для вас тариф, оплатить тариф и в личном кабинете сделать запрос на проверку информации. В строке поиска возможно сделать запрос по ИНН компании, названию юридического лица и т.д. ',
        },
        {
            name: 'Можно ли подарить доступ к сервису?',
            disk: 'Возможность подарить доступ к сервису есть. Для этого напишите нам на электронную почту info@datafix.tech и укажите какой тариф вы хотите предоставить в качестве подарка. По выбранному тарифу мы выставим вам счёт для оплаты и предоставим промокод.',
        },
      ], 

      uid: "",
      token: "",
    }
  },
  beforeCreate() {

  },
  mounted() {
    this.isAuthenticated = this.$store.state.isAuthenticated

    if (this.$route.query.redirect == 'subs') {
        setTimeout(() => {
            this.scrollToSubs()
        }, 100)
    }

    if (this.$route.query.redirect == 'faq') {
        setTimeout(() => {
            this.scrollToFaq()
        }, 100)
    }    
    
    if (this.$route.query.redirect == 'info') {
        setTimeout(() => {
            this.scrollToInfo()
        }, 100)
    }  

  },
  methods: {
      scrollToSubs() {
          this.$refs.subs.scrollIntoView({ behavior: "smooth" });
      },
      scrollToFaq() {
          this.$refs.faq.scrollIntoView({ behavior: "smooth" });
      },
      scrollToInfo() {
          this.$refs.info.scrollIntoView({ behavior: "smooth" });
      },
      ShowAll() {
        this.show_all = true;
      },
      TarifModal(id) {
        this.tarif__modal = true;
        this.current_tarif = id

        this.Price()
      },
      Price() {
        if (this.current_tarif == 1) {
            this.current_price = 350
        }

        if (this.current_tarif == 2) {
            this.current_price = 9900
        }

        if (this.current_tarif == 3) {
            this.current_price = 49900
        }
      },
      TabChoose(value, name) {
        this.tab_id = value
      },
      FaqChoose(value) {
        if (value == this.faq_id) {
          this.faq_id = 99
        } else {
          this.faq_id = value
        }
      },
      Link(value) {
        this.$router.push(value)
      },
      async sendEmail() {
          const data = {
              name: this.name,
              surname: this.surname,
              email: this.email,
              phone: this.phone,
              inn: this.inn,
          }

          await axios
          .post('/api/v1/send_email/', data)
          .then(response => {
              console.log(response)
              alert('Успешно!')
              this.name = ""
              this.surname = ""
              this.email = ""
              this.phone = ""
              this.inn = ""
          })
          .catch(error => {
              console.log(error)
          })
         
      },
  },
  watch: {
    $route() {
      if (this.$route.query.redirect == 'subs') {
          setTimeout(() => {
              this.scrollToSubs()
          }, 100)
      }

      if (this.$route.query.redirect == 'faq') {
          setTimeout(() => {
              this.scrollToFaq()
          }, 100)
      }    
      
      if (this.$route.query.redirect == 'info') {
          setTimeout(() => {
              this.scrollToInfo()
          }, 100)
      }  
    },
  },
  created() {
      if (this.$route.query.restore_user) {
          setTimeout(() => {
              this.confirm_password = true
              this.uid = this.$route.query.restore_user
              this.token = this.$route.query.md
          }, 100)
      }    
  },
}
</script>

<style>
  .new__p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    /* or 38px */

    text-align: center;

    /* black */

    color: #272833;

    transform: rotate(0deg);    
  }

  .response__faq p {
    margin-top: 20px;
    font-weight: 500;
  }

  .source__all_show {
    max-height: inherit !important;
  }

  .modal__flex_reg {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
</style>
