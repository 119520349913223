import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuthenticated: false,
    token: '',
    sub_id: '',
    user: {},
    card_current: {},
    user_id: null,
    status_pay: false,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      }

      if (localStorage.getItem('user_id')) {
        state.user_id = localStorage.getItem('user_id')
      } else {
        state.user_id = ''      
      }

      if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user'))
      } else {
        localStorage.setItem('user', JSON.stringify(state.user))
      }

      if (localStorage.getItem('card_current')) {
        state.card_current = JSON.parse(localStorage.getItem('card_current'))
      } else {
        localStorage.setItem('card_current', JSON.stringify(state.card_current))
      }

      if (localStorage.getItem('sub_id')) {
        state.sub_id = localStorage.getItem('sub_id')
      } else {
        state.sub_id = ''
      }
    }, 
    setUser(state, user_id) {
        state.user_id = user_id
    },  
    setSub(state, sub_id) {
        state.sub_id = sub_id
    },  
    setUserInfo(state, user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    setCardInfo(state, card_current) {
        state.card_current = card_current
        localStorage.setItem('card_current', JSON.stringify(state.card_current))
    },
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
    },  
    removeSub(state) {
        state.sub_id = ""
    },  
    removeUserInfo(state) {
        state.user = {}
    },
    removeToken(state) {
        state.token = ''
        state.card_current = {}
        state.isAuthenticated = false
    },
    removeUser(state) {
        state.user_id = ""
    }, 
  },
  actions: {
  },
  modules: {
  }
})
