<template>
     <div class="modal" :class="{'modal__active': modal}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Восстановить пароль</h2>
            <div class="modal__reg">
                <input v-model="email_user" type="text" placeholder="Введите ваш E-mail*">
            </div>
            <button class="btn__green" @click="RestorePassword(email_user)" v-if="email_user">Далее</button>
            <button class="btn__green" disabled v-else>Далее</button>
            <a @click="Back()" class="modal__reg_link pointer">Назад</a>
            <div class="modal__close pointer" @click="$emit('modal', false)"></div>
        </div>
    </div>   

     <div class="modal" :class="{'modal__active': modal_send}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>На ваш  e-mail отправлена ссылка</h2>
            <p>Перейдите по ссылке в письме</p>
            <button class="btn__green" @click="modal_send = false">Закрыть</button>
            <div class="modal__close pointer" @click="modal_send = false"></div>
        </div>
    </div>   
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import moment from 'moment';

    export default {
        name: "ForgotPassword",
        props: {
            modal: Boolean,
        },
        components: {

        },
        data() {
            return {
                modal_send: false,
                email_user: "",
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            async RestorePassword(value) {
                await axios
                .post(`/api/v1/users/reset_password/`, {email: value})
                .then(response => {
                    this.$emit('modal', false)
                    this.modal_send = true
                })
                .catch(error => {
                    console.log(error)
                })
            },
            Back() {
                this.$emit('modal', false)
                this.$emit('value', true)
            },
        },
        created() {

        },
        watch: {

        },
   }
</script>