<template>
    <div class="inner-page">
<!--         <div class="inner-page__link">
            <a href="#">
                <img src="@/assets/icons/main-lcc_link_1.svg" alt="">
            </a>
            <div>
                <img src="@/assets/icons/main-lcc_link_2.svg" alt="">
                <div class="inner-page__soc">
                    <a href="#">
                        <img src="@/assets/icons/main-llc_soc_1.svg" alt="">
                    </a>
                    <a href="#">
                        <img src="@/assets/icons/main-llc_soc_2.svg" alt="">
                    </a>
                    <a href="#">
                        <img src="@/assets/icons/main-llc_soc_3.svg" alt="">
                    </a>
                </div>
            </div>
            <a href="#">
                <img src="@/assets/icons/main-lcc_link_3.svg" alt="">
            </a>
        </div> -->
        <div class="inner-page__title" v-if="card_ip">
            <h3>ИП {{ card_info.ФИО }}</h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Статус }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРНИП }}</p>
                </div>
            </div>
        </div>
        <div class="inner-page__title" v-else>
            <h3 v-html='card_info.НаимЮЛСокр'></h3>
            <div class="inner-page__row">
                <div class="info__block">{{ card_info.Активность }}</div>
                <div class="inner-page__text">
                    <p>Дата создания:</p>
                    <p>{{ card_info.ДатаОГРН }}</p>
                </div>
            </div>
        </div>
        <div class="arbitration" v-if="total_arbitaj != 0">
            <h1>Арбитражные дела</h1>
            <ul class="arbitration__list">
                <li class="active">Все ({{ total_arbitaj }})</li>
            </ul>
            <div class="arbitration__wrapper">
                <div class="arbitration__item" v-for="(list, index) in items_arbitaj">
                    <div class="arbitration__prev">
                        <p class="bold">№ {{ list.НомерДела }}</p>
                        <p>от {{ list.СтартДата }}</p>
                    </div>
                    <ul>
                        <li v-if="list.СуммаИска">
                            <p>Сумма иска, руб: <span>{{ list.СуммаИска }}</span></p>
                        </li>
                        <li v-if="list.Истец">
                            <p>Истец: <span>{{ list.Истец[0].Наименование }}</span></p>
                        </li>
                        <li v-if="list.Ответчик">
                            <p>Ответчик: <span>{{ list.Ответчик[0].Наименование }}</span></p>
                        </li>
                        <li v-if='list["Иной участник"]'>
                            <p>Иной участник: <span>{{ list["Иной участник"][0].Наименование }}</span></p>
                        </li>
                        <li v-if='list["Третье лицо"]'>
                            <p>Третье лицо: <span>{{ list["Третье лицо"][0].Наименование }}</span></p>
                        </li>
                    </ul>
<!--                     <a href="#">Подробнее</a> -->
                </div>
            </div>
<!--             <ul class="pagination">
                <li class="active">1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
            </ul> -->
        </div>
        <div class="arbitration" v-else>
            <h1>Арбитражные дела</h1>
            <p>Данных нет</p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';

    export default {
        name: "Arbitraj",
        directives: { mask },
        props: {
            card_info: Object,
            items_arbitaj: [Array, Object],
            total_arbitaj: Number,

            card_ip: {
                type: Boolean,
                default: false,
            }
        },
        components: {

        },
        data() {
            return {

            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            
        },
        methods: {
        
        },
        created() {

        }
   }
</script>