<template>
     <div class="modal" :class="{'modal__active': modal}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper">
            <h2>Придумайте новый пароль</h2>
            <div class="modal__reg">
                <input v-model="pass1" type="password" placeholder="Введите новый пароль">
                <input v-model="pass2" type="password" placeholder="Повторите*">
            </div>
            <button class="btn__green" @click="ConfirmPassword()" v-if="pass1 == pass2 && pass2">Сохранить</button>
            <button class="btn__green" disabled v-else>Сохранить</button>
        </div>
    </div>   

    <div class="modal" :class="{'modal__active': loader}">
        <div class="modal__overflow"></div>
        <div class="modal__wrapper loader__pass">
            <img src="@/assets/icons/wait.svg" alt="" class="modal__wait">
            <h2>Новый пароль сохранен</h2>
            <p>Теперь вы можете войти в личный кабинет</p>
        </div>
    </div>   
</template>

<style scoped>
.loader__pass h2 {
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    margin: 35px 0 15px;
    color: #1B1B1E;
}
</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import moment from 'moment';

    export default {
        name: "ConfirmPassword",
        props: {
            modal: Boolean,
            uid: String,
            token: String,
        },
        components: {

        },
        data() {
            return {
                pass1: "",
                pass2: "",

                loader: false,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            async ConfirmPassword() {
                const NewData = {
                    "uid": this.uid,
                    "token": this.token,
                    "new_password": this.pass2                   
                }

                await axios
                .post(`/api/v1/users/reset_password_confirm/`, NewData)
                .then(response => {
                    this.$emit('conf', false)
                    this.loader = true

                    setTimeout(() => {
                        this.loader = false
                        window.location.href = '/'
                    }, 2500)
                })
                .catch(error => {
                    console.log(error)
                    alert('Данная ссылка не актуальна')
                    this.$emit('conf', false)
                    window.location.href = '/'
                })
            },
            Back() {

            },
            OpenAll() {
                this.block = !this.block
            },
        },
        created() {

        },
        watch: {

        },
   }
</script>