<template>
    <Header />
    <div class="full__white">
    <section class="ceo-title ceo-title__phone">
        <div class="container">
            <h1>Юридический адрес</h1>
            <p>Юридический адрес в Едином Государственном Реестре Юридических лиц, указывается как место пребывания организации. Он же заносится в учредительные документы.</p>
        </div>
    </section>
    <section class="affril-list affril-list__activities">
        <div class="container">
            <div class="affril-list__name">
                <h2>Юридический адрес не может быть фиктивным</h2>
                <p>Фактический, или почтовый адрес иногда не совпадает с юридическим. Если раньше эта ситуация встречалась повсеместно, сейчас такое положение не приветствуется со стороны ФНС и банковских организаций. </p>
            </div>
            <ul>
                <li>Опасность несовпадения адресов:</li>
                <li>Отсутствие представителей организации по юрадресу создает трудности для надзирающих органов, пенсионного фонда, ФССП и ФСС</li>
                <li>При несовпадении адресов банк вправе отказать в кредитах из опасений, что кредитора будет трудно найти в случае непогашения</li>
                <li>Налоговики сообщат в банковскую организацию, в которой открыт счет компании. Суд по обращению банка может расторгнуть договор обслуживания банковского счета</li>
                <li>Налоговая служба, проверив достоверность данных, внесет в ЕГРЮЛ пометку о недостоверных сведениях</li>
                <p class="affril-list__activities_more">При обнаружении ФНС несовпадения адресов сначала компания получает уведомление о необходимости внести изменения в ЕГРЮЛ. Требование налоговой службы необходимо выполнить в короткий срок.</p>
            </ul>
        </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <h2>Как ФНС проверяет юридический адрес</h2>
            <p>Юридический адрес компании вносится в ЕГРЮЛ. Для регистрации, в том числе предоставляются документы, подтверждающие нахождение организации по этому адресу.</p>
            <p>Это может быть свидетельство о собственности, договор аренды с арендодателем или гарантийное письмо от собственника помещения.</p>
        </div>
    </section>
    <section class="affril-list affril-list__activities">
        <div class="container">
            <div class="affril-list__name">
                <h2>Для проверки налоговая проводит собственные мероприятия:</h2>
            </div>
            <ul>
                <li>Получает списки арендаторов от собственников офисных центров</li>
                <li>Система автоматического контроля позволяет налоговикам видеть книги продаж собственников помещений, где в качестве контрагентов указаны арендаторы</li>
                <li>Рассылка писем по юридическим адресам</li>
            </ul>
        </div>
    </section>
    <section class="ceo-prev ceo-prev__director">
        <div class="container">
            <div class="ceo-prev__info">
                <p>Согласно законодательству, если организация предоставила недостоверные сведения для регистрации в ЕГРЮЛ, в том числе фиктивный юридический адрес, и этот факт выявлен налоговой службой, юридическое лицо по решению ФНС может быть исключено из реестра.</p>
                <button class="btn__green" @click="GoTo('/?redirect=subs')">Выбрать тариф</button>
            </div>
            <img src="@/assets/img/ceo-info_director.jpg" alt="">
        </div>
    </section>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'LegalAddress',
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {
        GoTo(redirect) {
          this.$router.push(redirect)
        },
  },
}
</script>
