 <template>
    <Header />
    <div class="full__white">
    <section class="ceo-title">
        <div class="container">
            <h1>Тарифы</h1>
            <p>Предлагаемый тарифный план разработан на основе изучения запросов компаний и предпринимателей, работающих в российском бизнесе.</p>
        </div>
    </section>
    <section class="rate rate-main" ref="subs">
          <div class="container">
              <h2>Тарифы</h2>
              <div class="rate__wrapper">
                  <div class="rate__item">
                      <h2>Стандарт</h2>
                      <div class="rate__price">
                          <p>350 ₽</p>
                          <p>/ 1 месяц</p>
                      </div>
                      <ul>
                          <li><p>900 <span>запросов в месяц</span></p></li>
                          <li><p>1 <span>устройство</span></p></li>
                      </ul>
                      <button class="btn__green pointer" @click="TarifModal(1)">Выбрать тариф</button>
                  </div>
                  <div class="rate__item rate__item_rec">
                      <div class="rate__rec">
                          <p class="reg">рекомендуем</p>
                      </div>
                      <h2>Премиум</h2>
                      <div class="rate__price">
                          <p>9900 ₽</p>
                          <p>/ 3 месяца</p>
                      </div>
                      <ul>
                          <li><p>3000 <span>запросов в месяц</span></p></li>
                          <li><p>неограниченное <span>количество устройств</span></p></li>
                      </ul>
                      <button class="btn__green pointer" @click="TarifModal(2)">Выбрать тариф</button>
                  </div>
                  <div class="rate__item">
                      <h2>Безлимит</h2>
                      <div class="rate__price">
                          <p>49 900 ₽</p>
                          <p>/ 1 год</p>
                      </div>
                      <div class="rate__price">
                          <span style="color: rgba(0, 0, 0, .5);">57 500₽ / с ндс 20%</span>
                      </div>
                      <ul>
                          <li><p>неограниченное <span>количество запросов</span></p></li>
                          <li><p>неограниченное <span>количество устройств</span></p></li>
                      </ul>
                      <button class="btn__green pointer" @click="TarifModal(3)">Выбрать тариф</button>
                  </div>
              </div>
          </div>
    </section>
    <section class="potential potential__ceo">
        <img src="@/assets/img/potential_1.svg" alt="">
        <img src="@/assets/img/potential_2.svg" alt="">
        <div class="container">
            <p class="ceo-info-sub">Выбирайте подходящий тариф в зависимости от потребностей вашей организации.</p>
            <p style="opacity: 1 !important;">Если у вас молодая, только стартовавшая компания,  вполне подойдут тарифы на 1 или 3 месяца.</p>
            <p>Сформируйте запрос и скачайте базу данных потенциальных клиентов с телефонами, адресами и руководством.</p>
        </div>
    </section>
    </div>
    <Footer />

    <ModalSub 
      :tarif__modal="tarif__modal" 
      :current_price="current_price"
      :current_tarif="current_tarif"
      v-on:modal="tarif__modal = $event" 
      v-on:price="current_price = $event" 
    />
</template>

<style scoped>
.ceo-info-sub {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 44px !important;
    line-height: 140%;
    /* or 67px */

    text-align: center;
    letter-spacing: -0.03em;

    /* black */

    color: #272833;
}

.ceo-info img {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    box-shadow: 0px 0px 0px !important;
    border-radius: 0px !important;
}
.ceo__about_service {
    position: absolute;
    top: 20px;
    right: 0px;
}    
</style>

<script>
import Header from '@/components/Headers/Header.vue'
import Footer from '@/components/Footer/Footer.vue'
import ModalSub from '@/components/Subs/ModalSub.vue'

export default {
  name: 'SubsSeo',
  components: {
    Header,
    Footer,
    ModalSub
  },
  data() {
    return {
      tarif__modal: false,
      current_tarif: null,
      current_price: null,
    }
  },
  methods: {
    GoQuery(value) {
      this.$router.push({path: '/', query: { redirect: value }})
    },
      TarifModal(id) {
        this.tarif__modal = true;
        this.current_tarif = id

        this.Price()
      },
      Price() {
        if (this.current_tarif == 1) {
            this.current_price = 350
        }

        if (this.current_tarif == 2) {
            this.current_price = 9900
        }

        if (this.current_tarif == 3) {
            this.current_price = 49900
        }
      },  },
}
</script>
